<template>
  <ModalForm :modal-width="1100" :hide-footer="true" :title="title" ref="viewBoxModal">
    <template v-slot:content>
      <div class="container-fluid" style="padding: 10px; overflow-y: auto; max-height: 500px">
        <div class="row" v-if="loading">
          <div class="col-12">
            <Preloader></Preloader>
          </div>
        </div>
        <div class="row" v-else-if="!loading && !data">
          <div class="col-12" style="padding: 20px; text-align: center">
            <h4>{{$t('box_data_not_found')}}</h4>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
            <table class="table table-hover table-sm">
              <tbody>
              <tr>
                <td>{{ $t('name') }}</td>
                <td>
                  <div style="font-size: 120%">
                    <b>{{ data.name }}</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('size') }}</td>
                <td>
                  <div style="font-size: 120%">
                    <ColoredBlockText
                        :background-color="data.is_full ? 'darkred' : 'darkgreen'"
                        :border-color="data.is_full ? 'red' : 'green'"
                        :text-color="'#FFFFFF'"
                        v-html="` <b>${data.count_registered}</b>/${data.count_ports} -
                      ${data.loading_prc}%`"
                    ></ColoredBlockText>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('has_issues') }}</td>
                <td>
                  <div>
                    <ColoredBlockText style="width: 40px"
                                      :background-color="data.has_issues ? 'rgba(192, 154, 0, 1);' : '#a3a3a3'"
                                      :border-color="data.has_issues ? 'rgba(192, 154, 0, 1);' : '#a3a3a3'"
                                      :text-color="'#FFFFFF'"
                                      v-html="data.has_issues ? $t('yes') : $t('no')"
                    ></ColoredBlockText>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('device') }}</td>
                <td>
                  <div>
                    <router-link :to="{name: 'device_dashboard', params: {id: data.device.id}}">
                      {{ data.device.ip }} <br><small>{{ data.device.name }}</small>
                    </router-link>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('olt_name') }}</td>
                <td>
                  <div>
                    {{ data.olt_name }}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="form-group row"  style="margin-bottom: 4px">
              <div class="col-md-12 col-sm-12 col-12">
              <label class=""  style="padding-left: 5px; padding-right: 10px">{{
                  $t('exclude_from_stat')
                }}</label>
                  <label class="switch-small" title="Enabled"
                       style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                  <input type="checkbox" :disabled="!$auth.isPermitted('utels_allow_exclude_from_stat')"
                        v-model="data.exclude_from_stat">
                  <span class="slider"></span>
                </label>
               </div>
            </div>
            <div class="form-group row" >
              <div class="col-md-4 col-sm-4 col-4">
              <label style="padding-left: 5px; padding-right: 10px" >{{
                  $t('comment')
                }}</label></div>
              <div class="col-md-8 col-sm-8 col-8">
                 <textarea class="form-control" :disabled="!$auth.isPermitted('utels_allow_set_comment')" v-model="data.comment">

                 </textarea>
              </div>
              <div class="col-4"></div>
              <div class="col-8">
                <button class="btn btn-sm btn-default" @click="updateBox(data)" style="margin-top: 5px;" :disabled="!$auth.isPermitted('utels_allow_set_comment') && !$auth.isPermitted('utels_allow_exclude_from_stat')">
                  <i class="mdi mdi-content-save"></i> {{$t('save')}}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
            <div style="max-height: 480px; width: 100%; overflow-y: auto">
              <table class=" VueTables__table VueTables__table table table-striped table-bordered table-hover styled-table table-sm" v-if="data.onts.length > 0">
                <thead>
                <tr>
                  <th>{{$t('port')}}</th>
                  <th>{{$t('contract')}}</th>
                  <th>{{$t('interface')}}</th>
                  <th>{{$t('rx')}} (dBm)</th>
                  <th>{{$t('olt_rx')}} (dBm)</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(ont, id) in data.onts" :key="id">
                  <td><b>{{ont.box.port}}</b></td>
                  <td>
                    <div v-if="ont.interface">
                      <a href="javascript:void(0)" v-if="ont.interface.billing_link" @click="openInBilling(ont.interface)" :title="ont.interface?.params?.billing?.nodeny.fio">
                        {{ont.contract}}
                      </a>
                    </div>
                    <div v-else>
                      {{ont.contract}}
                    </div>
                  </td>
                  <td>
                    <div v-if="ont.interface">
                      <router-link
                          :to="{name: 'device_iface_dashboard', params: {id: ont.interface.device.id, 'interface': ont.interface.bind_key}}">
                        {{ ont.interface.name }} <span v-if="ont.interface.description"> - {{ ont.interface.description }} </span>
                      </router-link>
                      <router-link target='_blank'
                                   :to="{name: 'device_iface_dashboard', params: {id: ont.interface.device.id, 'interface': ont.interface.bind_key}}">

                        <i class="mdi mdi-open-in-new"></i>
                      </router-link>
                       <span :class="`ont-status ` + (ont.interface.status === 'Online' ? 'ont-status-online' : 'ont-status-offline')">{{ont.interface.status}}</span>
                    </div>
                  </td>
                  <td>
                    <span>{{ont?.optical?.rx ? ont.optical.rx : $t('n_a')}}</span>
                  </td>
                  <td>
                    <span>{{ont?.optical?.olt_rx ? ont.optical.olt_rx : $t('n_a')}}</span>
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>
                <h4 align="center" style="margin: 10px"><i class="mdi mdi-table-off"></i> {{ $t('clients_not_found') }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalForm>
</template>
<script>
import ModalForm from "@/components/ModalForm.vue";
import Preloader from "@/components/Preloader.vue";
import ColoredBlockText from "@/components/ColoredBlockText.vue";

export default {
  components: {
    ModalForm,
    Preloader,
    ColoredBlockText,
  },
   props: {

   },
   data() {
     return {
        loading: true,
        title: '',
        data: null,
     }
   },
  methods: {
    async showModalBoxByDeviceAndName(deviceId, boxName) {

    },
    async openInBilling(iface) {
      await this.$api.get('/component/utels_integration/session-id', {}, true).then(r => {
        let link = iface.billing_link + "&pp=" + r.data
        window.open(link, '_blank').focus();
      }).catch(e => {
        this.$noty.warning(this.$t('not_found_session_in_billing', {error: e.response.data.error.description}))
      })
    },
    async showBoxModal(box) {
      this.loading = true
      this.title = `${this.$t('view')} ${box.name}`
      this.$refs.viewBoxModal.show()
      await this.$api.get(`/component/utels_integration/boxes/${box.id}`).then(r => {
        this.data = r.data
      }).finally(() => {
        this.loading = false
      })
    },
    updateBox(box) {
      this.loading = true
      this.$api.put(`/component/utels_integration/boxes/${box.id}`, {
        exclude_from_stat: box.exclude_from_stat,
        comment: box.comment,
      }).then(r => {
        this.data = r.data
        this.$noty.success(this.$t('success_saved'))
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

.ont-status {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: #FAFAFA;
}

.ont-status-online {
  margin-left: 5px;
  margin-right: 5px;
  background: rgba(0, 116, 11, 1);
}

.ont-status-offline {
  margin-left: 5px;
  margin-right: 5px;
  background: darkred;
}
</style>