<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12 col-mini">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12 col-mini">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-mini">
        <button v-if="$auth.isPermitted('user_management')" class="btn btn-default" @click="$router.push({name: 'management_user_edit', params: {id: 'new'}})" style="float: left"><i class="fa fa-user-plus"></i> {{$t('create_new_user_btn')}}</button>
        <div v-cloak>
        <v-client-table v-model="data" :columns="table.columns" :options="table.options" class="btn-add-margin-top-for-mobile">
          <template v-slot:actions="{ row }" >
            <div v-if="$auth.isPermitted('user_management')">
            <router-link :to="{name: 'management_user_edit', params: {id:row.id}}" class="btn btn-default" style="margin: 3px" :title="$t('edit')"><i class="fa fa-edit"></i></router-link>
            <button :disabled="$auth.getUser().id === row.id || row.id <= 0" @click="deleteAccount(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete_account')"><i class="fa fa-trash"></i></button>
            </div>
          </template>
          <template v-slot:role_name="{ row }" >
            <router-link :to="{name: 'management_role_edit', params: {id: row.role.id}} ">{{row.role.name}}</router-link>
          </template>
          <template v-slot:last_activity="{ row }" >
              <span v-if="row.last_activity === null">
                  {{$t('newer')}}
              </span>
              <span v-if="row.last_activity !== null" style="">
                  {{row.last_activity}}
              </span>
          </template>
        </v-client-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";

export default {
  components: {SomethingWrong, Preloader},
  data() {
    return {
      data: [],
      table:  {
        columns: ['id','login', 'name', 'last_activity',  'role_name'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table  table-sm',
          headings: {
            id: 'Id',
            login: this.$t('login'),
            name: this.$t('name'),
            role_name: this.$t('role'),
            last_activity: this.$t('last_activity'),
            actions: '',
          },
          sortable: ['id', 'login', 'name', 'role_name', 'last_activity'],
          filterable: ['name', 'code', 'role_name'],

          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      loading: true,
      error: '',
    }
  },
  mounted() {
    this.$setRouteMeta(this.$t('user_list'))
    if(this.$auth.isPermitted('user_management')) {
      this.table.columns.push('actions')
    }
    this.load()
  },
  methods: {
    deleteAccount(userId) {
      if(confirm(this.$t("are_you_sure_for_delete_user"))) {
        this.loading = true
        this.$api.delete('/user/' + userId).then(() => {
          this.$noty.success(this.$t('user_success_deleted'))
          this.load()
        })
      }
    },
    tableData(dt) {
      var data = []
      dt.forEach(e => {
        if(e.role.display === false) return;
        data.push({
          id: e.id,
          name: e.name,
          login: e.login,
          role: e.role,
          role_name: e.role.name,
          last_activity: e.last_activity,
        })
      })
      return data
    },
    async load() {
      this.loading = true
      await this.$api.get('/user').then(data => {
        this.data = this.tableData(data.data)
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>
