<template>
  <div class="row">
    <div class="col-12"  v-if="loading">
      <Preloader></Preloader>
    </div>
    <div class="col-12" style="margin-top: 10px; margin-bottom: 20px; text-align: center" v-if="data.status === null && !loading">
      <h3 v-html="$t('information_not_collected_yet')"></h3>
    </div>
    <div class="col-sm-6 col-md-6  col-12" v-if="data.status  && !loading">
      <h3>{{$t('subscription_info')}}  </h3>
      <table style="width: 100%">
        <tr>
          <td>{{ $t('status') }}</td>
          <td v-if="data.status.license_type !== 'LICENSE'"><b>{{ data.status?.status }}</b></td>
          <td v-else><b>{{ $t('activated_box_license') }}</b></td>
        </tr>
        <tr>
          <td>{{ $t('agent_key') }}</td>
          <td><b>{{ data.status?.uuid }}</b></td>
        </tr>
        <tr>
          <td>{{ $t('registered_ip') }}</td>
          <td>{{ data.status?.ip_addr }}</td>
        </tr>
        <tr>
          <td>{{ $t('reserved_interfaces') }}</td>
          <td><h4>{{ data.status?.reserved_interfaces }}</h4></td>
        </tr>
        <tr>
          <td style="vertical-align: top">{{$t('allowed_components')}}</td>
          <td>
            <div style="overflow-y: auto; max-height: 350px">
              <li v-for="f in data.status?.components" :key="f">{{f}}</li>
            </div>

          </td>
        </tr>
      </table>

    </div>
    <div class="col-sm-6 col-md-6  col-12" v-if="data.status  && !loading && data.statistic">
      <h3>{{$t('usage_statistic')}}</h3>
      <table style="width: 100%">
        <tr>
          <td>{{ $t('version') }}</td>
          <td><h4>{{ data.statistic?.version}}</h4></td>
        </tr>
        <tr>
          <td>{{ $t('count_interfaces') }}</td>
          <td><h4>{{ data.statistic.count_interfaces}} <small>({{$t('from')}} {{data.status.reserved_interfaces}})</small></h4></td>
        </tr>
        <tr>
          <td>{{ $t('count_devices') }}</td>
          <td>{{ data.statistic.count_devices }}</td>
        </tr>
        <tr>
          <td>{{ $t('count_users') }}</td>
          <td>{{ data.statistic.count_users }}</td>
        </tr>
        <tr>
          <td>{{ $t('count_groups') }}</td>
          <td>{{ data.statistic.count_groups }}</td>
        </tr>
      </table>
      <br>
      <h3>{{$t('ifaces_detailed_statistic')}}</h3>
      <table style="width: 100%; text-align: left" class="table table-bordered table-sm table-active table-striped" v-if="ifacesStat && ifacesStat.types">
        <thead>
        <tr>
          <td><b>{{$t('type')}}</b></td>
          <td><b>{{$t('up')}}</b></td>
          <td><b>{{$t('down')}}</b></td>
          <td><b>{{$t('unknown')}}</b></td>
          <td><b>{{$t('all')}}</b></td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="iface in ifacesStat.types" :key="iface.type">
          <td><b>{{iface.type}}</b></td>
          <td>{{iface.up}}</td>
          <td>{{iface.down}}</td>
          <td>{{iface.unknown}}</td>
          <td>{{iface.up + iface.down + iface.unknown}}</td>
        </tr>
        <tr>
          <td></td>
          <td><b>{{ifacesStat.up}}</b></td>
          <td><b>{{ifacesStat.down}}</b></td>
          <td><b>{{ifacesStat.unknown}}</b></td>
          <td><b>{{ifacesStat.up + ifacesStat.down + ifacesStat.unknown}}</b></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
    </div>
  </div>
</template>


<script>
import Preloader from "../../../components/Preloader.vue";
import ModalForm from "../../../components/ModalForm.vue";
import VJsoneditor from "v-jsoneditor";

export default {
  components: {Preloader},
  data() {
    return {
      loading: false,
      error: null,
      data: {
        status: null,
        statistic: null,
        last_checking: null,
      },
      ifacesStat: null,
    }
  },
  methods: {
    async load() {
      this.loading = true
      await this.$api.get('/system/info')
          .then(response => {
            if(response.data.status) {
              this.data = response.data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      await this.$api.get('/device-interface/stat-by-types')
          .then(response => {
            this.ifacesStat = response.data
          })
          .catch(error => {
            console.error(error);
          });
      this.loading = false
    },
  },
  async mounted() {
    await this.load()
  },

}
</script>

<style>
.tbl thead tr th {
  font-weight: bold;
}
</style>