<template>
  <button class="btn btn-light btn-sm" @click="toggleFullScreen()" style=" padding-bottom: 0px"
          :title="$t('enable_fullscreen')">
    <i :class="'mdi mdi-18px ' + (!this.fullScreenEnabled ? 'mdi-fullscreen' : 'mdi-fullscreen-exit' )"></i>
  </button>
</template>

<script>
export default {
    props: {
       fullscreen: {
         type: Boolean,
         default: false,
       },
       object: null,
    },
    mounted() {
       if(this.fullscreen) {
         this.fullScreenEnabled = true
       }
    },
  data() {
      return {
        fullScreenEnabled: false,
      }
    },
  methods: {
    toggleFullScreen() {
      if (this.fullScreenEnabled) {
        this.exitFullscreen()
      } else {
        this.goFullscreen()
      }
      this.fullScreenEnabled = !this.fullScreenEnabled
    },
    goFullscreen() {
      const block = this.object;
      if (block.requestFullscreen) {
        block.requestFullscreen();
      } else if (block.webkitRequestFullscreen) {
        // Для Safari
        block.webkitRequestFullscreen();
      } else if (block.mozRequestFullScreen) {
        // Для Firefox
        block.mozRequestFullScreen();
      } else if (block.msRequestFullscreen) {
        // Для IE/Edge
        block.msRequestFullscreen();
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        // Для Safari
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Для Firefox
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        // Для IE/Edge
        document.msExitFullscreen();
      }
    },
  }
}
</script>