<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12 col-mini">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12 col-mini">
        <SomethingWrong/>
      </div>
    </div>
    <ModalForm :disabled-buttons="group.disableButtons" :title="group.title" ref="editGroup" @save="saveGroup">
      <template v-slot:content>
        <form class="container" style="padding: 20px">
        <div class="form-group row">
          <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{$t('name')}}</label>
          <div class="col-md-9 col-sm-9">
            <input type="text" v-model="group.name"
                   class="form-control" :placeholder="$t('name')">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{$t('description')}}</label>
          <div class="col-md-9 col-sm-9">
            <input type="text" v-model="group.description"
                   class="form-control" :placeholder="$t('description')">
          </div>
        </div>
        </form>
      </template>
    </ModalForm>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-mini">
        <button class="btn btn-default" @click="addGroup"  style="float: left;"><i class="fa fa-plus"></i> {{$t('create_new_group_btn')}}</button>
        <div v-cloak>
          <v-client-table ref="table" v-model="data" :columns="table.columns" :options="table.options" style="margin-top: 0px; ">
            <template v-slot:name="{row}">
              <span style="font-weight: bold">{{row.name}}</span>
            </template>
            <template v-slot:description="{row}">
              <span style="font-weight: bold">{{row.description}}</span>
            </template>
            <template v-slot:actions="{row }" >
              <div>
                <button @click="editGroup(row)" class="btn btn-default" style="margin: 3px" :title="$t('edit_group')"><i class="fa fa-edit"></i></button>
                 <button :disabled="row.id <= 0" @click="deleteGroup(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete_group')"><i class="fa fa-trash"></i></button>
              </div>
            </template>

          </v-client-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";
import ModalForm from "@/components/ModalForm";

export default {
  components: {ModalForm, SomethingWrong, Preloader},
  data() {
    return {
      data: [],
      group: {
        id: 0,
        disableButtons: false,
        title: '',
        name: '',
        description: '',
      },
      table:  {
        columns: ['id', 'name', 'description', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table  table-sm',
          headings: {
            id: 'Id',
            name: this.$t('name'),
            description: this.$t('description'),
            actions: '',
          },
          sortable: ['id',  'name'],
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      loading: true,
      error: '',
    }
  },
  mounted() {
    this.$setRouteMeta(this.$t('device_group_management'))
    this.load()
  },
  methods: {
    deleteGroup(id) {
      if(confirm(this.$t("are_you_sure_for_delete_group"))) {
        this.loading = true
        this.$api.delete('/device-group/' + id).then(() => {
          this.$noty.success(this.$t('group_success_deleted'))
          this.load()
        })
      }
    },
    saveGroup() {
      this.group.disableButtons = true
      if(this.group.id !== 0) {
        this.$api.put('/device-group/' + this.group.id, this.group).then(() => {
          this.load()
          this.$noty.success(this.$t('group_success_updated'))
          this.$refs.editGroup.hide()
        }).finally(() => {
          this.group.disableButtons = false
        })
      } else {
        this.$api.post('/device-group', this.group).then(() => {
          this.load()
          this.$noty.success(this.$t('group_success_created'))
          this.$refs.editGroup.hide()
        }).finally(() => {
          this.group.disableButtons = false
        })
      }
    },
    addGroup() {
      this.group = {
        title: this.$t('create_new_group'),
        disableButtons: false,
        id: 0,
        name: '',
        description: '',
      }
      this.$refs.editGroup.show()
    },
    editGroup(group) {
      this.group = {
        title: this.$t('edit_group_with_id', {id: group.id}),
        disableButtons: false,
        id: group.id,
        name: group.name,
        description: group.description,
      }
      this.$refs.editGroup.show()
    },
    tableData(dt) {
      var data = []
      dt.forEach(e => {
        data.push({
          id: e.id,
          name: e.name,
          description: e.description,
        })
      })
      return data
    },
    async load() {
      this.loading = true
      await this.$api.get('/device-group').then(data => {
        this.data = this.tableData(data.data)
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>
