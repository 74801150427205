<template>
  <div class="container-fluid">
    <div id="mobile-filter" role="tooltip" ref="mobileFilter" v-click-outside="hideMobileFilter">
      <div class="row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <a href="javascript:void(0)" @click="hideMobileFilter(null)" style="float: right; font-size: 14pt"><i
              class="mdi mdi-close"></i></a>
        </div>
        <div class="col-lg-12 col-sm-12 col-xl-12 col-md-12">
          <FilterBlock v-on:update-filter="updateFilter" :sorts="sorts" :models="getModels" :groups="groupsForFilter"></FilterBlock>
        </div>
      </div>
    </div>
    <Preloader v-if="loading"></Preloader>
    <div class="row" v-else>
      <div class="col-12 col-mini col-sm-12 col-md-8 col-lg-9 col-xl-10">
        <div class="row">
          <Preloader v-if="devicesIsLoading" style="position: absolute; top: 50px; z-index: 103"></Preloader>
          <div class="col-sm-12 " :style="(devicesIsLoading ? 'opacity: 0.5; ' : '')"
               v-if="filter.show_groups">
            <div class="col-12  col-sm-12 col-lg-12 col-xl-12" v-if="getGroups.length === 0 && !devicesIsLoading">
              <h3 style="text-align: center; color: #505050">{{ $t('devices_not_found') }}</h3>
            </div>
            <GroupBlock :name="gr.name" :description="gr.description"
                        :stat="gr.statistic"
                        :show="gr.isExpanded"
                        @change-show="function(expand) {gr.isExpanded = expand}"
                        class=" card-block" v-for="gr in getGroups" :key="gr.id">
              <div>
                <Blocks v-if="gr.isExpanded" :devices="gr.devices"></Blocks>
              </div>
            </GroupBlock>
          </div>
          <div class="col-sm-12 " :style="(devicesIsLoading ? 'opacity: 0.5; ' : '')" v-else>
            <div class="col-12  col-sm-12 col-lg-12 col-xl-12" v-if="devicesList.length === 0 && !devicesIsLoading">
              <h3 style="text-align: center; color: #505050">{{ $t('devices_not_found') }}</h3>
            </div>
            <div class="group" v-else>
              <div class="group-body">
                <div class=" card-block">
                  <Blocks :devices="devicesList"></Blocks>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 10px"
           class="order-first col-mini col-12 col-sm-12 col-md-12 col-lg-12 d-block d-sm-block d-md-none d-xl-none d-lg-none">
        <button id="mobile-filter-btn" @click="showMobileFilter" ref="mobileFilterBtn" class="btn btn-default btn-sm"
                style="margin-right: 5px; margin-bottom: 1px"><i
            class="mdi mdi-filter-menu"></i> {{ $t('filter') }}
        </button>
        <button v-if="filter.show_groups" @click="expandAllGroups" class="btn btn-dark btn-sm" style="margin-right: 5px; margin-bottom: 1px"><i
            class="mdi mdi-eye"></i> {{ $t('expand_all') }}
        </button>
        <button v-if="filter.show_groups" @click="hideAllGroups" class="btn btn-dark btn-sm" style="margin-right: 5px; margin-bottom: 1px"><i
            class="mdi mdi-eye-off"></i> {{ $t('hide_all') }}
        </button>
        <button  @click="reloadData" class="btn btn-dark btn-sm" style="margin-right: 5px; margin-bottom: 1px"><i
            class="mdi mdi-reload"></i> {{ $t('reload_info') }}
        </button>
      </div>
      <div style="margin-bottom: 10px"
           class="col-md-4 col-lg-3 col-xl-2 d-none d-sm-none d-md-block d-xl-block d-lg-block">
        <button v-if="filter.show_groups" @click="expandAllGroups" class="btn btn-dark btn-sm" style="margin-right: 5px; margin-bottom: 5px"><i
            class="mdi mdi-eye"></i> {{ $t('expand_all') }}
        </button>
        <button v-if="filter.show_groups" @click="hideAllGroups" class="btn btn-dark btn-sm" style="margin-right: 5px; margin-bottom: 5px"><i
            class="mdi mdi-eye-off"></i> {{ $t('hide_all') }}
        </button>
        <button @click="reloadData" class="btn btn-dark btn-sm" style="margin-right: 5px; margin-bottom: 5px"><i
            class="mdi mdi-reload"></i> {{ $t('reload_info') }}
        </button>
        <FilterBlock v-on:update-filter="updateFilter" :sorts="sorts" :models="getModels" :groups="groupsForFilter"></FilterBlock>
      </div>
    </div>
  </div>
</template>


<script>
import Blocks from "./DeviceList/Blocks";
import FilterBlock from "./DeviceList/FilterBlock";
import Preloader from "@/components/Preloader";
import GroupBlock from "./DeviceList/GroupBlock";

export default {
  name: "device_list",
  data() {
    return {
      filter: {
        show_groups: null,
        sort_by: '',
        query: '',
        groups: [],
        models: [],
        status: 'all',
      },
      loading: false,
      devicesIsLoading: false,
      devices: [],
      groups: [],
      groupsForFilter: [],
      models: [],
      modelKeys: [],
      expandedAll: false,
      sorts: [
        {key: 'ip', name: this.$t('ip')},
        {key: 'name', name: this.$t('name')},
        {key: 'location', name: this.$t('location')},
      ]
    }
  },
  components: {
    GroupBlock,
    Preloader,
    FilterBlock,
    Blocks,
  },
  watch: {
    filter: {
      handler(n) {
        this.recalcDisplayFromFilter(n)
      },
      deep: true,
    },
  },
  computed: {
    getModels() {
      return this.models.filter(e => {
        return this.modelKeys.indexOf(e.id) !== -1
      })
    },
    getGroups() {
      let groups = [];
      if (!this.filter.groups || this.filter.groups.length === 0) {
        this.groups.forEach(gr => {
          if (gr.devices.length !== 0) {
            groups.push(gr)
          }
        })
      } else {
        this.groups.forEach(group => {
          if (this.filter.groups.filter(e => {
            return group.id === e.id
          }).length !== 0 && group.devices.length !== 0) {
            groups.push(group)
          }
        })
      }
      return groups.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    },
    devicesList() {
      return this.devices.filter((device) => {
        if (this.filter.status === 'online' && device.pinger !== null && device.pinger.latency <= 0) {
          return false
        }
        if (this.filter.status === 'offline' && device.pinger !== null && device.pinger.latency > 0) {
          return false
        }
        if (this.filter.query !== '' && !device.query.includes(this.filter.query.toLowerCase())) {
          return false
        }
        if (this.filter.models.length !== 0 && this.filter.models.filter(model => {
          return model.id === device.model.id
        }).length === 0) {
          return false
        }
        if (this.filter.groups.length !== 0 && this.filter.groups.filter(group => {
          return group.id === device.group.id
        }).length === 0) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    hideMobileFilter(event) {

      if (!event || event === null) {
        this.$refs.mobileFilter.removeAttribute('data-show');
        return;
      }

      const mobileFilter = this.$refs.mobileFilter;
      const targetElement = event.target;

      if (targetElement.id === 'mobile-filter-btn') {
        this.$refs.mobileFilter.setAttribute('data-show', '');
        return;
      }

      if (!mobileFilter.contains(targetElement)) {
        this.$refs.mobileFilter.removeAttribute('data-show');
      }
    },
    showMobileFilter(element) {
      let filter = this.$createPopper(this.$refs.mobileFilterBtn, this.$refs.mobileFilter, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'left', 'bottom'],
              padding: 300,
              altBoundary: false,
              flipVariations: true,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      });
      this.$refs.mobileFilter.setAttribute('data-show', '');
    },
    expandAllGroups() {
      this.groups.forEach((e, i) => {
        setTimeout(() => {
          e.isExpanded = true
        }, i * 30)
      })
      this.$localCfg.set('device_dashboard.expand', true)
    },
    hideAllGroups() {
      this.groups.forEach((e, i) => {
        e.isExpanded = false
      })
      this.$localCfg.set('device_dashboard.expand', false)
    },
    async loadDevices() {
      let totalPages = this.$localCfg.get('device_dashboard_total_pages', null)
      var devices = []
      if(!totalPages) {
        let inProccess = true
        let page = 1
        while (inProccess) {
          await this.$api.get(`/dev-dashboard/devices`, {
            page: page,
            limit: 500,
            sort: this.filter.sort_by,
            down_on_top: this.$auth.getSettings().device_dashboard.down_devices_on_top ? 'yes' : 'no',
          }).then(r => {
            if (r.data.length === 0) {
              inProccess = false
            }
            if (page >= r.meta.total_pages) {
              inProccess = false
              this.$localCfg.set('device_dashboard_total_pages', r.meta.total_pages)
            }
            r.data.forEach(e => {
              devices.push({
                query: JSON.stringify(e).toLowerCase(),
                description: e.description,
                name: e.name,
                ip: e.ip,
                id: e.id,
                location: e.location,
                group: {
                  id: e.group.id,
                },
                model: {
                  id: e.model.id,
                  name: e.model.name,
                  icon: e.model.icon,
                  type: e.model.type,
                },
                ifaces_stat: e.ifaces_stat,
                pinger: e.pinger !== null ? {
                  latency: e.pinger.latency,
                } : null,
                isDown: e.pinger === null || Boolean(e.pinger?.latency <= 0) === true
              })
            })
          })
          page++
        }
      } else {
        this.$api.enableSupportWaiting()
        for(let page = 1; page <= totalPages; page++) {
          this.$api.get(`/dev-dashboard/devices`, {
            page: page,
            limit: 1000,
            sort: this.filter.sort_by,
            down_on_top: this.$auth.getSettings().device_dashboard.down_devices_on_top ? 'yes' : 'no',
          }).then(r => {
            this.$localCfg.set('device_dashboard_total_pages', r.meta.total_pages)
            r.data.forEach(e => {
              devices.push({
                query: JSON.stringify(e).toLowerCase(),
                description: e.description,
                name: e.name,
                ip: e.ip,
                id: e.id,
                location: e.location,
                group: {
                  id: e.group.id,
                },
                model: {
                  id: e.model.id,
                  name: e.model.name,
                  icon: e.model.icon,
                  type: e.model.type,
                },
                ifaces_stat: e.ifaces_stat,
                pinger: e.pinger !== null ? {
                  latency: e.pinger.latency,
                } : null,
                isDown: e.pinger === null || Boolean(e.pinger?.latency <= 0) === true
              })
            })
          })
        }
       await this.$api.waitResponses()
      }

      this.devices = devices
    },
    async loadGroups() {
      return  this.$api.get('/dev-dashboard/groups').then(r => {
        let data = []
        r.data.forEach(e => {
          e.isExpanded = false
          e.devices = []
          e.statistic = {
            devices: {
              all: 0,
              online: 0,
            },
            interfaces: {
              all: 0,
              online: 0,
            }
          }
          data.push(e)
        })
        return data
      })
    },
    async loadModels() {
      let data = []
      await this.$api.get('/dev-dashboard/models').then(r => {
        r.data.forEach(e => {
          data.push(e)
        })
      })
      this.models = data
    },
    reloadData() {
      this.reloadDevices()
    },
    updateFilter(filter) {
      this.filter = filter
    },
    recalcDisplayFromFilter(filter) {
      this.groups.forEach((group) => {
        let statistic = {
          devices: {
            online: 0,
            all: 0,
          },
          interfaces: {
            all: 0,
            online: 0,
          }
        }

        const compare = ( a, b ) => {
          if ( a[this.filter.sort_by] < b[this.filter.sort_by] ){
            return -1;
          }
          if ( a[this.filter.sort_by] > b[this.filter.sort_by] ){
            return 1;
          }
          return 0;
        }

        const compare2 = ( a, b ) => {
          if(!this.$auth.getSettings().device_dashboard.down_devices_on_top) return 0;

          if ( a.isDown < b.isDown ){
            return 1;
          }
          if ( a.isDown > b.isDown ){
            return -1;
          }
          return 0;
        }

        group.devices = this.devices
        .sort(compare)
        .sort(compare2)
        .filter((device) => {
          if (group.id !== device.group.id) {
            return false
          }
          if (filter.status === 'online' && device.pinger !== null && device.pinger.latency <= 0) {
            return false
          }
          if (filter.status === 'offline' && device.pinger !== null && device.pinger.latency > 0) {
            return false
          }
          if (filter.query !== '' && !device.query.includes(filter.query.toLowerCase())) {
            return false
          }
          if (filter.models.length !== 0 && filter.models.filter(model => {
            return model.id === device.model.id
          }).length === 0) {
            return false
          }
          if (filter.groups.length !== 0 && filter.groups.filter(group => {
            return group.id === device.group.id
          }).length === 0) {
            return false
          }
          statistic.devices.all++
          if (device.pinger !== null && device.pinger.latency > 0) {
            statistic.devices.online++
          }
          if (device.ifaces_stat !== null && (device.ifaces_stat.up || device.ifaces_stat.down) ) {
            statistic.interfaces.all += device.ifaces_stat.down + device.ifaces_stat.up
            statistic.interfaces.online += device.ifaces_stat.up
          }
          return true
        })
        group.statistic = statistic
      })
    },
    async reloadDevices() {
      this.devicesIsLoading = true
      await this.loadDevices()

      let models = {}
      this.devices.forEach(device => {
        models[device.model.id] = device.model.name
      })

      let modelkeys = []
      for (const [key] of Object.entries(models)) {
        modelkeys.push(parseInt(key))
      }
      this.modelKeys = modelkeys

      this.recalcDisplayFromFilter(this.filter)
      this.devicesIsLoading = false
    },
    async load() {
      this.expandedAll = this.$localCfg.get('device_dashboard.expand')
      this.loading = true
      this.$api.enableSupportWaiting()
      this.loadModels()
      this.loadGroups().then(r => {
        this.groups = r
        this.groupsForFilter = JSON.parse(JSON.stringify(this.groups))
      })
     await this.$api.waitResponses()
      this.$api.disableSupportWaiting()
      if (this.expandedAll) {
        this.expandAllGroups()
      }
     await this.reloadDevices()
      this.$nextTick(() => {
        this.updateFilter(this.filter)
      })
      this.loading = false
    }
  },
  activated() {
    this.$setRouteMeta(this.$t('devices_list'))
  },
  mounted() {
    this.$setRouteMeta(this.$t('devices_list'))
    this.$refs.mobileFilter.removeAttribute('data-show');

    if (typeof this.$route.query.status !== 'undefined' && this.$route.query.status !== '') {
      this.filter.status = this.$route.query.status
    }

    this.load()

  },
}
</script>

<style scoped>
.group {
  width: 100%;
  border: 1px solid #DADADA;
  background: rgba(250, 250, 250, 1);
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.group-header {
  border: 0 !important;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.group-body {
  background: rgba(0, 0, 0, 0);
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

a {
  color: black;
}

a:hover {
  color: black;
}

a:visited {
  color: black;
}

a:active {
  color: black;
}

@media only screen and (max-width: 580px) {
  .buttons-block {
    margin-top: -9px !important;
  }
}

.card-block {
  margin-bottom: 5px;
}

#mobile-filter {
  background: #FAFAFA;
  color: black;
  font-size: 13px;

  padding: 5px 10px 10px !important;
  border-radius: 1px;
  display: none;
  width: 98%;
  margin-left: -5px !important;
  margin-top: -10px !important;
  z-index: 101;
  -webkit-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.55);
  box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.55);
  inset: 120px 10px auto 10px !important;
  transform: none !important;
  max-width: 520px;
}

#mobile-filter[data-show] {
  display: block;
}

#mobile-filter[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#mobile-filter[data-popper-placement^='bottom'] > #arrow {
  top: 4px;
}

#mobile-filter[data-popper-placement^='left'] > #arrow {
  right: 4px;
}

#mobile-filter[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
</style>
