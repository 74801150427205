import { render, staticRenderFns } from "./LinkInfoSmall.vue?vue&type=template&id=a546028c&scoped=true"
import script from "./LinkInfoSmall.vue?vue&type=script&lang=js"
export * from "./LinkInfoSmall.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a546028c",
  null
  
)

export default component.exports