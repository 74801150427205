<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12 col-md-12">
          <FullscreenPreloader :show="preloading"></FullscreenPreloader>
        <router-link :to="{name: 'management_device_edit', params: {id: 'new'}}" style="float: left;"
                     class="btn btn-default"><i class="mdi mdi-plus"></i> {{ $t('add_device') }}
        </router-link>
          <WcaExportExcel style="float: right;" name="Device managment" v-if="!preloading" :fetch="exportXls.fetch" :fields="exportXls.fields" ></WcaExportExcel>
        <v-server-table :columns="table.columns" :options="table.options" ref="deviceTable"  @loading="() => {this.preloading = true}" @loaded="() => {this.preloading = false}"
                        class="btn-add-margin-top-for-mobile">
          <template v-slot:model="{row}">
            {{ row.model.name }}
          </template>
          <template v-slot:device_group="{row}">
            {{ row.group.name }}
          </template>
          <template v-slot:ip="{row}">
            <b>{{ row.ip }}</b>
            <div v-if="!row.enabled" style="color: darkred">({{$t('disabled')}})</div>
          </template>
          <template v-slot:access="{row}">
            {{ row.access.name }}
          </template>
          <template v-slot:actions="{row}">
            <router-link v-if="$auth.isPermitted('device_management')"
                         :to="{name: 'management_device_edit', params: {id:row.id}}" class="btn btn-default"
                         style="margin: 3px" :title="$t('edit')"><i class="fa fa-edit"></i></router-link>
            <button v-if="$auth.isPermitted('device_management')" @click="deleteDevice(row.id)" class="btn btn-danger"
                    style="margin: 3px" :title="$t('delete')"><i class="fa fa-trash"></i></button>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader.vue";
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";
import WcaExportExcel from "@/components/WcaExportExcel.vue";

var filter = {}

export default {
  components: {FullscreenPreloader,WcaExportExcel},
  mounted() {
    filter = this.filter
    this.$setRouteMeta(this.$t('device_management'))
    // this.$refs.deviceTable.setRequestParams({
    //   page: 1,
    //   limit: 50,
    // })
  },
  methods: {
    deleteDevice(deviceId) {
      if (confirm(this.$t("are_you_sure_for_delete_device"))) {
        this.loading = true
        this.$api.delete('/device/' + deviceId).then(() => {
          this.$noty.success(this.$t('device_success_deleted'))
          this.$refs.deviceTable.refresh()
        })
      }
    },
  },
  data() {
    return {
      exportXls: {
        fields: {
          'id':'id',
          'ip':'ip',
          'mac':'mac',
          'serial':'serial',
          'name':'name',
          'device_group':'group.name',
          'description':'description',
          'model':'model.name',
          'type':'model.type',
          'access':'access.name',
          'updated_at':'updated_at',
          'created_at':'created_at',
        },
        async fetch() {
          const {data} = await this.$api.get('/device', {limit: 500000})
          return data;
        }
      },
      preloading: false,
      table: {
        columns: ['id', 'ip', 'mac', 'name', 'device_group', 'description', 'model', 'access', 'updated_at', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table table-sm',
          requestFunction(data) {
            if(data.orderBy && data.orderBy === 'device_group') {
                data.orderBy = '_device_group_name'
            }
            return this.$api.get('/device', data).catch(function (e) {
              this.dispatch('error', e);
            }).then(resp => {
              resp.count = resp.meta.total_records
              return resp;
            });
          },
          perPage: 50,
          perPageValues: [50, 100,  500, 1000],
          headings: {
            id: 'Id',
            ip: 'IP',
            mac: 'MAC',
            name: this.$t('name'),
            device_group: this.$t('device_group'),
            description: this.$t('description'),
            model: this.$t('model'),
            access: this.$t('access'),
            updated_at: this.$t('updated_at'),
            actions: '',
          },
          sortable: ['id', 'name', 'device_group', 'description', 'mac', 'serial', 'ip'],
          filterable: ['ip', 'mac', 'serial', 'name', 'device_group', 'description'],
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
}

</script>