<template>
    <div class="container-fluid">
        <div class="row" v-if="loading && error === ''">
            <div class="col-12 col-mini">
                <Preloader/>
            </div>
        </div>
        <div class="row" v-if="error !== ''">
            <div class="col-12 col-mini">
                <SomethingWrong/>
            </div>
        </div>
        <ModalForm :modal-width="800" :disabled-buttons="access.disableButtons" :title="access.title" ref="editAccess"
                   @save="saveAccess">
            <template v-slot:content>
                <form class="container-fluid" style="padding: 10px; overflow-y: auto; max-height: 500px">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div class="form-group row">
                                <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{
                                    $t('name')
                                    }}</label>
                                <div class="col-md-9 col-sm-9">
                                    <input type="text" v-model="access.name"
                                           class="form-control" :placeholder="$t('name')">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{
                                    $t('public_community')
                                    }}</label>
                                <div class="col-md-9 col-sm-9">
                                    <input type="text" v-model="access.public_community"
                                           class="form-control" :placeholder="$t('public_community_placeholder')">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{
                                    $t('private_community')
                                    }}</label>
                                <div class="col-md-9 col-sm-9">
                                    <input type="text" v-model="access.private_community"
                                           class="form-control" :placeholder="$t('private_community_placeholder')">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{
                                    $t('login')
                                    }}</label>
                                <div class="col-md-9 col-sm-9">
                                    <input type="text" v-model="access.login"
                                           class="form-control" :placeholder="$t('login')">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{
                                    $t('password')
                                    }}</label>
                                <div class="col-md-9 col-sm-9">
                                    <input type="text" v-model="access.password"
                                           class="form-control" :placeholder="$t('password')">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <button onclick="return false" v-if="!access.params.sw_core_connection"
                                            @click="setConnectionParameters()"
                                            class="btn btn-block btn-default">
                                        {{ $t('change_default_connection_parameters') }}
                                    </button>
                                    <button onclick="return false" v-if="access.params.sw_core_connection"
                                            @click="removeConnectionParameters()"
                                            class="btn btn-block btn-default">{{
                                        $t('set_default_connection_parameters')
                                        }}
                                    </button>
                                </div>
                            </div>
                            <div v-if="access.params.sw_core_connection">
                                <div class="form-group row" style="margin-top: 5px">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right control-label col-form-label">
                                        {{ $t('connection_connection_type') }}
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                        <v-select v-model="access.params.sw_core_connection.console_connection_type"
                                                  class="style-chooser"
                                                  label="value"
                                                  :clearable="false"
                                                  :reduce="e => e.key"
                                                  :options="connection_types"></v-select>
                                    </div>
                                </div>
                                <div class="form-group row" style="margin-top: 5px">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right control-label col-form-label">
                                        {{ $t('port') }}
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                        <input v-model="access.params.sw_core_connection.console_port"
                                               class="form-control" type="number">
                                    </div>
                                </div>
                                <div class="form-group row" style="margin-top: 5px">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right control-label col-form-label">
                                        {{ $t('timeout_sec') }}
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                        <input v-model="access.params.sw_core_connection.console_timeout_sec"
                                               class="form-control"
                                               type="number">
                                    </div>

                                </div>
                                <div class="form-group row" style="margin-top: 5px">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right control-label col-form-label">
                                        {{ $t('snmp_timeout_sec') }}
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                        <input v-model="access.params.sw_core_connection.snmp_timeout_sec"
                                               class="form-control"
                                               type="number">
                                    </div>
                                </div>
                                <div class="form-group row" style="margin-top: 5px">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right control-label col-form-label">
                                        {{ $t('snmp_version') }}
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                        <v-select v-model="access.params.sw_core_connection.snmp_version"
                                                  class="style-chooser"
                                                  label="value"
                                                  :clearable="false"
                                                  :reduce="e => e.key"
                                                  :options="snmp_versions"></v-select>
                                    </div>
                                </div>
                                <div class="form-group row" style="margin-top: 5px">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right control-label col-form-label">
                                        {{ $t('snmp_repeats') }}
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                        <input v-model="access.params.sw_core_connection.snmp_repeats"
                                               class="form-control" type="number">
                                    </div>
                                </div>
                                <div class="form-group row" style="margin-top: 5px">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right control-label col-form-label">
                                        {{ $t('snmp_port') }}
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                        <input v-model="access.params.sw_core_connection.snmp_port" class="form-control"
                                               type="number">
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <h4 align="center" style="margin-top: 15px; margin-bottom: 15px">
                                    {{ $t('used_default_connections_parameters') }}</h4>
                            </div>
                        </div>
                    </div>
                </form>
            </template>
        </ModalForm>
        <div class="row" v-if="!loading && error === ''">
            <div class="col-12 col-mini">
              <button class="btn btn-default" @click="addAccess" style="float: left; margin-top: 5px"><i
                  class="fa fa-plus"></i> {{ $t('create_new_access_btn') }}
              </button>
                <div v-cloak>
                    <v-client-table ref="table" v-model="data" :columns="table.columns" :options="table.options"
                                    style="margin-top: 5px; ">
                        <template v-slot:name="{row}">
                            <span style="font-weight: bold">{{ row.name }}</span>
                        </template>
                        <template v-slot:public_community="{row}">
                            <span v-if="row.unmasked">{{ row.public_community }}</span>
                            <span v-if="!row.unmasked">{{ '*'.repeat(row.public_community.length) }}</span>
                        </template>
                        <template v-slot:private_community="{row}">
                            <span v-if="row.unmasked">{{ row.private_community }}</span>
                            <span v-if="!row.unmasked">{{ '*'.repeat(row.private_community.length) }}</span>
                        </template>
                        <template v-slot:login="{row}">
                            <span v-if="row.unmasked">{{ row.login }}</span>
                            <span v-if="!row.unmasked">{{ '*'.repeat(row.login.length) }}</span>
                        </template>
                        <template v-slot:password="{row}">
                            <span v-if="row.unmasked">{{ row.password }}</span>
                            <span v-if="!row.unmasked">{{ '*'.repeat(row.password.length) }}</span>
                        </template>
                        <template v-slot:actions="{row }">
                            <div>
                                <a v-if="!row.unmasked" @click="unmask(row.id)"
                                   style="padding-top: 25px; margin-right: 15px; margin-left: 15px; font-size: 20px"
                                   :title="$t('show')"><i class="fa fa-eye"></i></a>
                                <a v-if="row.unmasked" @click="unmask(row.id)"
                                   style="padding-top: 10px; margin-right: 15px; margin-left: 15px; font-size: 20px"
                                   :title="$t('hide')"><i class="fa fa-eye-slash"></i></a>
                                <button @click="editAccess(row)" class="btn btn-default" style="margin: 3px"
                                        :title="$t('edit_access')">
                                    <i class="fa fa-edit"></i></button>
                                <button @click="deleteAccess(row.id)" class="btn btn-danger" style="margin: 3px"
                                        :title="$t('delete_access')"><i class="fa fa-trash"></i></button>
                            </div>
                        </template>

                    </v-client-table>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";
import ModalForm from "@/components/ModalForm";

export default {
    components: {ModalForm, SomethingWrong, Preloader},
    data() {
        return {
            connection_types: [],
            snmp_versions: [],
            defaultConnectionAccess: null,
            data: [],
            access: {
                params: {
                    sw_core_connection: null,
                },
                id: 0,
                disableButtons: false,
                title: '',
                name: '',
                community: '',
                login: '',
                password: '',
            },
            table: {
                columns: ['id', 'name', 'public_community', 'private_community', 'login', 'password', 'actions'],
                options: {
                    skin: 'VueTables__table table table-striped table-bordered table-hover styled-table  table-sm',
                    headings: {
                        id: 'Id',
                        name: this.$t('name'),
                        public_community: this.$t('public_community'),
                        private_community: this.$t('private_community'),
                        login: this.$t('login'),
                        password: this.$t('password'),
                        actions: '',
                    },
                    sortable: ['id', 'name', 'public_community', 'login', 'password'],
                    texts: {
                        count: this.$t('dt_table.count'),
                        first: this.$t('dt_table.first'),
                        last: this.$t('dt_table.last'),
                        filter: this.$t('dt_table.filter'),
                        filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
                        limit: this.$t('dt_table.limit'),
                        page: this.$t('dt_table.page'),
                        noResults: this.$t('dt_table.noResults'),
                        filterBy: this.$t('dt_table.filterBy'),
                        loading: this.$t('dt_table.loading'),
                        defaultOption: this.$t('dt_table.defaultOption'),
                        columns: this.$t('dt_table.columns'),
                    },
                },
            },
            loading: true,
            error: '',
        }
    },
    mounted() {
        this.$setRouteMeta(this.$t('device_access_management'))
        this.connection_types = [
            {key: 'telnet', value: "Telnet"},
            {key: 'ssh', value: "SSH"},
        ]
        this.snmp_versions = [
            {key: '1', value: "v1"},
            {key: '2c', value: "v2c"},
        ]

        this.load()
    },
    methods: {
        setConnectionParameters() {
            this.access.params.sw_core_connection = JSON.parse(JSON.stringify(this.defaultConnectionAccess))
            console.log(this.access.params)
        },
        removeConnectionParameters() {
            this.access.params.sw_core_connection = null
        },
        async getDefaultConnectionConfiguration() {
            await this.$api.get('/device-access-defaults').then(r => {
                this.defaultConnectionAccess = r.data
            })
        },
        deleteAccess(id) {
            if (confirm(this.$t("are_you_sure_for_delete_access"))) {
                this.loading = true
                this.$api.delete('/device-access/' + id).then(() => {
                    this.$noty.success(this.$t('access_success_deleted'))
                    this.load()
                })
            }
        },
        saveAccess() {
            this.access.disableButtons = true
            if (this.access.id !== 0) {
                this.$api.put('/device-access/' + this.access.id, this.access).then(() => {
                    this.load()
                    this.$noty.success(this.$t('access_success_updated'))
                    this.$refs.editAccess.hide()
                }).finally(() => {
                    this.access.disableButtons = false
                })
            } else {
                this.$api.post('/device-access', this.access).then(() => {
                    this.load()
                    this.$noty.success(this.$t('access_success_created'))
                    this.$refs.editAccess.hide()
                }).finally(() => {
                    this.access.disableButtons = false
                })
            }
        },
        addAccess() {
            this.access = {
                title: this.$t('create_new_access'),
                disableButtons: false,
                id: 0,
                name: '',
                login: '',
                password: '',
                public_community: '',
                private_community: '',
                params: {
                    sw_core_connection: null,
                },
            }
            this.$refs.editAccess.show()
        },
        editAccess(access) {
            this.access = {
                title: this.$t('edit_access_with_id', {id: access.id}),
                disableButtons: false,
                id: access.id,
                name: access.name,
                login: access.login,
                password: access.password,
                public_community: access.public_community,
                private_community: access.private_community,
                params: access.params,
            }
            this.$refs.editAccess.show()
        },
        unmask(id) {
            this.data.forEach((e, k) => {
                if (id !== e.id) return false
                this.data[k].unmasked = !e.unmasked
            })
        },
        tableData(dt) {
            var data = []
            dt.forEach(e => {
                if (typeof e.params !== 'object' || e.params == null) {
                    e.params = {}
                }
                if (typeof e.params.sw_core_connection === 'undefined' || e.params.sw_core_connection === null) {
                    e.params.sw_core_connection = null
                } else if (typeof e.params.sw_core_connection !== 'object') {
                    e.params.sw_core_connection = null
                }
                data.push({
                    unmasked: false,
                    id: e.id,
                    name: e.name,
                    public_community: e.public_community,
                    private_community: e.private_community,
                    login: e.login,
                    password: e.password,
                    params: e.params,
                })
            })
            return data
        },
        async load() {
            this.loading = true
            await this.getDefaultConnectionConfiguration()
            await this.$api.get('/device-access').then(data => {
                this.data = this.tableData(data.data)
            }).catch((e) => {
                this.error = 'ERROR'
                console.log(e)
            })
            this.loading = false
        },
    }
}
</script>
