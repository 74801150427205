<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12 col-mini">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12 col-mini">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-mini">
        <button class="btn btn-default" @click="$router.push({name: 'management_role_edit', params: {id: 'new'}})" style="float: left"><i class="fa fa-users"></i> {{$t('create_new_role_btn')}}</button>
        <div v-cloak>
        <v-client-table v-model="data" :columns="table.columns" :options="table.options"  class="btn-add-margin-top-for-mobile">
          <template v-slot:name="{row}">
            <span style="font-weight: bold">{{row.name}}</span>
          </template>
          <template v-slot:actions="{ row }" >
            <div>
            <router-link :to="{name: 'management_role_edit', params: {id:row.id}}" class="btn btn-default" style="margin: 3px" :title="$t('edit')"><i class="fa fa-edit"></i></router-link>
            <button :disabled="$auth.getUser().role.id === row.id || row.id <= 0" @click="deleteRole(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete_role')"><i class="fa fa-trash"></i></button>
            </div>
          </template>
          <template v-slot:display="{ row }" >
            <span v-if="row.display" style="border-radius: 5px; padding: 5px; background-color: #316301; color: white">
              {{$t('enabled')}}
            </span>
            <span v-if="!row.display" style="border-radius: 5px; padding: 5px; background-color: #5f5f5f; color: white">
              {{$t('disabled')}}
            </span>
          </template>

        </v-client-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";

export default {
  components: {SomethingWrong, Preloader},
  data() {
    return {
      data: [],
      table:  {
        columns: ['id', 'name', 'display', 'description', 'permissions', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table  table-sm',
          headings: {
            id: 'Id',
            name: this.$t('name'),
            description: this.$t('description'),
            permissions: this.$t('role_permissions_count'),
            display: this.$t('display'),
            actions: '',
          },
          sortable: ['id',  'name'],
          filterable: ['name', 'description'],
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      loading: true,
      error: '',
    }
  },
  mounted() {
    this.$setRouteMeta(this.$t('user_role_list'))
    this.load()
  },
  methods: {
    deleteRole(userId) {
      if(confirm(this.$t("are_you_sure_for_delete_role"))) {
        this.loading = true
        this.$api.delete('/user-role/' + userId).then(() => {
          this.$noty.success(this.$t('role_success_deleted'))
          this.load()
        })
      }
    },
    tableData(dt) {
      var data = []
      dt.forEach(e => {
        if (e.permissions === null) {
          e.permissions = []
        }
        data.push({
          id: e.id,
          name: e.name,
          display: e.display,
          permissions: e.permissions.length,
          description: e.description,
        })
      })
      return data
    },
    async load() {
      this.loading = true
      await this.$api.get('/user-role').then(data => {
        this.data = this.tableData(data.data)
      }).catch((e) => {
        console.log(e)
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>
