<template>
  <div style="height: 80vh; width: 100%">
    <l-map
        @click="setMarker"
        v-if="showMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        @locationfound="onLocationFound"
        @locationerror="onLocationError"
        @ready="onReady"
        ref="map"
    >
      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :subdomains="tileProvider.subdomains"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"/>
      <l-marker v-if="marker !== null" :lat-lng="marker"/>
      <l-control position="topleft" style="width: 100%; ">
        <div style="margin-left: 45px;  margin-top: -74px; border-radius: 3px; width: 100%; padding-right: 125px ">
          <v-select label="display_name"
                    :options="options"
                    v-model="choosed"
                    :filterable="false"
                    @option:selected="setByForm"
                    @search="onSearch"
                    class="style-chooser"
                    style="min-width: 250px"
          >
            <template v-slot:no-options>
              {{ $t('address_not_found') }}
            </template>
            <template v-slot:option="option">
              {{ option.display_name }}
            </template>
            <template v-slot:selected-option="option">
              <div style=" overflow: hidden">
                {{ option.display_name }}
              </div>
            </template>
          </v-select>
        </div>
      </l-control>
      <l-control position="topleft" >
        <div class="leaflet-control">
          <button class="leaflet-control-button" @click="locate" href="javascript:void(0)" style="font-size: 14pt; padding-top: 1.5pt"><i class="mdi mdi-crosshairs-gps"></i> </button>
        </div>
      </l-control>
      <template v-if="location">
        <l-circle-marker :lat-lng="location.latlng" :fillOpacity="1" :radius="0.1" />
        <l-circle :lat-lng="location.latlng" :radius="location.accuracy / 2" :stroke="false" />
      </template>
    </l-map>

  </div>
</template>


<script>
import {latLng} from "leaflet";
import axios from 'axios';

export default {
  props: {
    coordinates: {
      type: Object,
      default: null,
    }
  },
  mounted() {
    if(this.coordinates !== null) {
      this.choosed = this.coordinates
      this.marker = latLng(this.choosed.lat, this.choosed.lon)
      this.centerUpdate(this.marker)
    }
  },
  data() {
    let tileProviders = [
      {
        name: 'Google (street)',
        visible: false,
        attribution:
            '&copy; <a target="_blank" href="https://www.google.com/maps">Google maps</a>',
        url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        subdomains:['mt0','mt1','mt2','mt3'],
      },
      {
        name: 'Google (hybrid)',
        visible: false,
        attribution:
            '&copy; <a target="_blank" href="https://www.google.com/maps">Google maps</a>',
        url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
        subdomains:['mt0','mt1','mt2','mt3'],
      },
      {
        name: 'OpenStreetMap',
        visible: false,
        attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        subdomains: null,
      },
      {
        name: 'OpenTopoMap',
        visible: false,
        url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        subdomains: null,
        attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
      },
    ]

    let tileSetted = false
    tileProviders.forEach((t) => {
      if(this.$config.map.default_tile && this.$config.map.default_tile === t.name) {
        t.visible = true
        tileSetted = true
      }
    })
    if(!tileSetted) {
      tileProviders[0].visible = true
    }

    return {
      options: [],
      choosed: null,
      marker: null,
      zoom: 12,
      center: latLng(this.$config.map.coordinates.lat, this.$config.map.coordinates.lon),
      showParagraph: false,
      currentLocation: null,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      map: null,
      location: null,
      tileProviders: tileProviders,
    };
  },
  methods: {
    onReady() {
      this.map = this.$refs["map"].mapObject;
    },
    locate() {
      this.map.locate({setView: true,
        maxZoom: 19});
    },
    onLocationFound(l) {
      console.log(l);
      this.location = l;
    },
    onLocationError(l) {
      console.log(l);
    },
    async setMarker(clc) {
      this.loading = true
      this.marker = latLng(clc.latlng.lat,clc.latlng.lng)
      await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&polygon=1&addressdetails=1&lat=${clc.latlng.lat}&lon=${clc.latlng.lng}`).then(r => {
        this.choosed = r.data
      })
      this.choosed.lat = clc.latlng.lat
      this.choosed.lon = clc.latlng.lng
      this.$emit('updated', this.choosed)
      this.loading = false
    },
    setByForm() {
      this.marker = latLng(this.choosed.lat, this.choosed.lon)
      this.centerUpdate(latLng(this.choosed.lat, this.choosed.lon))
      this.zoomUpdate(17)
      this.$emit('updated', this.choosed)
    },
    zoomUpdate(zoom) {
      this.zoom = zoom;
    },
    centerUpdate(center) {
      this.center = center;
    },
    async onSearch(search, loading) {
      loading(true)
      await axios.get(`https://nominatim.openstreetmap.org/search?format=json&polygon=1&addressdetails=1&q=${search}`).then(r => {
        this.options = r.data
      })
      loading(false)
    },

  }
}
</script>
