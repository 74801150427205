<template>
  <div :style="`height: ${height}px; width: 100%; z-index: -1`">
    <l-map
        v-if="showMap"
        :zoom="zm"
        :center="center"
        :options="mapOptions"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
    >
      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :subdomains="tileProvider.subdomains"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"/>
      <l-marker v-if="marker !== null" :lat-lng="marker"/>
    </l-map>

  </div>
</template>


<script>
import {latLng} from "leaflet";

export default {
  props: {
    coordinates: {
      type: Object,
      default: null,
    },
    height: {
      type: [Number, String],
      default: 400,
    },
    zoom: {
      type: [Number, String],
      default: 10,
    },
  },
  mounted() {
    setTimeout(() => {
      if(this.coordinates !== null) {
        this.choosed = this.coordinates
        this.marker = latLng(this.choosed.lat, this.choosed.lon)
        this.centerUpdate(this.marker)
        this.zoomUpdate(this.zoom)
      }
    }, 10)
    setTimeout(() => {
      this.showMap = true
    }, 500)
  },
  watch: {
    coordinates: {
      handler() {
        if(this.coordinates !== null) {
          this.choosed = this.coordinates
          this.marker = latLng(this.choosed.lat, this.choosed.lon)
          this.centerUpdate(this.marker)
          this.zoomUpdate(this.zoom)
        }
      },
      deep: true,
    },
  },
  data() {
    let tileProviders = [
      {
        name: 'Google (street)',
        visible: false,
        attribution:
            '&copy; <a target="_blank" href="https://www.google.com/maps">Google maps</a>',
        url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        subdomains:['mt0','mt1','mt2','mt3'],

      },
      {
        name: 'Google (hybrid)',
        visible: false,
        attribution:
            '&copy; <a target="_blank" href="https://www.google.com/maps">Google maps</a>',
        url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
        subdomains:['mt0','mt1','mt2','mt3'],
      },
      {
        name: 'OpenStreetMap',
        visible: false,
        attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      {
        name: 'OpenTopoMap',
        visible: false,
        url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
      },
    ]

    let tileSetted = false
    tileProviders.forEach((t) => {
      if(this.$config.map.default_tile && this.$config.map.default_tile === t.name) {
          t.visible = true
          tileSetted = true
      }
    })
    if(!tileSetted) {
       tileProviders[0].visible = true
    }




    return {
      options: [],
      choosed: null,
      zm: 10,
      marker: null,
      center: latLng(this.$config.map.coordinates.lat, this.$config.map.coordinates.lon),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 1
      },
      showMap: false,
      tileProviders: tileProviders
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.zm = zoom;
    },
    centerUpdate(center) {
      this.center = center;
    },
  }
}
</script>