<template>
  <div :style="styles" class="leaflet-popup leaflet-zoom-animated">
    <div class="leaflet-popup-content-wrapper" style="overflow-y: auto">
      <div class="leaflet-popup-content"
           style="min-height: 150px;   max-height: 270px; margin: 0; padding: 0; ">
        <LinkInfoSmall :link="link" />
      </div>
    </div>
    <div class="leaflet-popup-tip-container" v-if="!$helpers.isMobile()">
      <div class="leaflet-popup-tip"></div>
    </div>
    <a class="leaflet-popup-close-button" role="button" aria-label="Close popup" href="#close"
       @click.prevent="close"><span aria-hidden="true">×</span></a>
  </div>
</template>

<script>

import LinkInfoSmall from "@/components/Links/LinkInfoSmall.vue";

export default {
  components: {LinkInfoSmall},
  props: {
    link: Object,
    styles: Object,
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent;
}
</style>