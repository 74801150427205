export default {
    clients_not_found: "Абонентів не знайдено",
    "count_filled_pon_boxes": "Кількість заповнених PON-боксів",
    "utels_pon_boxes": "PON-бокси",
    "only_filled": "Тільки заповнені",
    "olt_name": "Ім'я OLT",
    "count_ports": "Кількість портів",
    "count_registered": "Кількість зареєстрованих",
    "loaded_prc": "Завантажено %",
    "has_issues": "Є зауваження",
    "is_full": "Заповнено",
    "exclude_from_stat": "Виключити зі статистики",
    utilization_info_not_avaliable: "Немає інфо про навантаження",
    time_ago: "Час тому",
    upward_topology: "Висхідна топологія ",
    link_list: "Список з'єднань",
    links_with_highload: "Кількість навантажених з'єднань",
    only_high_utilization: "Тільки навантажені",
    topology: "Топологія",
    maps: "Мапи",
    onts_with_bad_signals: "ОНУшок з поганим сигналом",
    only_bad_signals: "З поганими сигналами",
    "is_internal_rule": `
     Це внутрішнє правило  <br>
     <small>Деякі параметри недоступні для редагування, вони контролюються системою</small>
    `,
    "src_iface": "Вихідний інтерфейс",
    "dest_iface": "Цільовий інтерфейс",
    "uplinks": "Вхідні канали",
    "downlinks": "Вихідні канали",
    "positions_success_saved": "Позиції успішно збережено",
    "auto_reload": "Автоперезавантаження",
    "open_parameters": "Відкрити параметри",
    "enable_fullscreen": "Увімкнути повноекранний режим",
    "unknown_interface": "Невідомий інтерфейс",
    "utilization": "Завантаження",
    "link_speed": "Швидкість каналу",
    "configure_your_topology": "Налаштуйте візуалізацію топології",
    "minimal_link_utilization": "Показати канали із завантаженням більше %",
    "enable_physics": "Увімкнути фізику",
    "save_object_positions": "Зберегти позиції об'єктів",
    "save_position": "Зберегти позицію",
    "hide_devices_without_links": "Приховати пристрої без каналів",
    "hide_level_above": "Приховати пристрої вище рівня",
    "util_time_period": "Період підрахунку навантаження",
    "has_errors": "Має помилки",
    "has_modules": "Має модулі",
    "traps": {
        "name": "SNMP Трапи",
        "export": "Експорт трапа"
    },
    no_links: "No links",
    no_links_small_info: "Аплинків немає. Щоб додати лінк - перейдіть в редагування пристрою",
    adjacent_interfaces: "Суміжні інтерфейси",
    not_found_adjacent_interfaces: "Суміжних інтерфейсів не знайдено",
    any: "Любе",
    active: "Активно",
    not_active: "Не активно",
    signal_type: "Тип сигналу",
    no_data_to_show: "Немає даних для відображення",
    iface_history_table: "Таблиця аптайму",
    uptime_time: "Час аптайму",
    days_short: "дн.",
    no_interface: "Без інтерфейса",
    start_write_for_filter: "Почніть вводити для фільтрації",
    object_filter: "Фільтр об'єктів",
    log_traps: "SNMP-traps",
    srv_profile: "Сервіс-профайл",
    not_found_session_in_billing: "Не знайдено активну сесію в білінгу ({error}) ",
    not_found_in_billing: "Не знайдено інформації в білінгу",
    pon_box: "PON-бокс",
    cvlan: "CVLAN",
    dhcpoption: "DHCP-опція",
    house: "д.",
    apartment_short: "кв.",
    fio: "ФІО",
    export_excel: "Завантажити в excel",
    "google_twofa_pin_instruction": "Будь ласка, введіть шестизначний код, отриманий у додатку для авторизації",
    "choose_device": "Виберіть пристрій",
    "choose_interface": "Виберіть інтерфейс",
    info: "Інфо",
    select: "Вибрати",
    onts_registration: {
        preview: "Перегляд команд для виконання",
        exec: "Результат реєстрації",
        onu_success_registered_searching: "ONT зареєстрована! \nПошук...",
        onu_success_registered_and_found: "ONT успішно зареєстрована та знайдена!",
        registered_but_not_found: "ONT зареєстрована, але не знайдена автоматично. Будь ласка, перевірте журнали або знайдіть вручну",

        reload_variables_from_device: "Перезавантажити дані з пристрою",
        registration_template_not_configured_for_model: "<span style='color: darkred'>Не знайдено шаблону реєстрації для даної моделі, реєстрація неможлива. <br>Будь ласка, створіть шаблон</span>",
        please_choose_unregistered_ont: "Будь ласка, виберіть незареєстровану ОНУ",
        config: "Реєстрація ОНУ",
        list: "Список макросів для реєстрації ОНУ",
        import: "Імпорт макроса",
        add: "Додати макрос",
        edit: "Редагувати макрос",
        remove: "Видалити макрос",
        export: "Експорт макроса (json)",
        edit_macros: "Редагування макросів '{name}'",
        model_vendor: "Виробник пристрою",
        models: "Моделі пристроїв",
        device: "Пристрій",
        confirm_remove: "Ви впевнені, що хочете видалити шаблон?",
        success_deleted: "Успішно видалено",
        success_created: "Успішно створено",
        fail_updated: "Не вдалося оновити макроси",
        success_updated: "Успішно оновлено",
        add_new: "Додати новий макрос",
        unregisteredOnts: "Незареєстровані ОНУ",
        error_read_imported_macros: "Помилка завантаження імпортованих макросів",
    },
    pinger_info: "Інформація ICMP-пінгера",
    increasing_errors_by_day: "Ріст помилок за останні 24 години",
    step: "крок",
    increasing_errors: "Помилки на інтерфейсах",
    chart: "Графік",
    increasing_errors_for_iface: "Графік росту помилок на интерфейсі {iface}",
    counter_in_errors: "Вхід. помилки",
    counter_out_errors: "Вих. помилки",
    increasing_in_errors: "Ріст вхід. помилок",
    increasing_out_errors: "Ріст вих. помилок",
    copy: "Копіювати",
    copied: "Скопійовано",
    twofa_confirm: "Ви дійсно хочете відключити 2FA?",
    twofa: "2FA",
    twofa_please_enter_pin: "Будь-ласка, введіть пін-код",
    twofa_instruction: "Відскануйте QR-код за допомогою <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US&pli=1'>Google Authenticator</a>, і введіть отриманий код",
    twofa_disconnect: "Відключити",
    twofa_manual: "Або введіть код вручну",
    twofa_connect: "Включити",
    twofa_connect_submit: "Підтвердити",
    wrong_pin: "Пін-код невірний",
    twofa_connected: "2FA увімкнено",
    connect_2fa: "Відскануйте QR код через Google Authenticator",
    twofa_pin: "ПІН код",
    twofa_enabled: "2FA увімкнено",
    n_a: "Н/Д",
    macros_not_found: "Макроси не знайдено",
    hide_online: "Сховати онлайн",
    show_only_favorite: "Тільки вибрані",
    save_fdb_history: "Зберігати FDB в історію",
    duration: "Тривалість",
    availability: "Доступність",
    periods: {
        '24h': "1 день",
        '1d': "1 день",
        '3d': "3 дня",
        '7d': "7 днів",
        '30d': "30 днів",
    },
    down_logs: "Логи падіння",
    packet_loss: "Втрати пакетів",
    online_from: "Онлайн з ",
    offline_from: "Офлайн з ",
    durations: {
        year: 'г',
        month: 'міс',
        week: 'нд',
        day: 'д',
        hour: 'г',
        minute: 'хв',
        seconds: 'с',
        millis: 'мс',
    },
    no_pollers: "Немає опитувальників",
    lacp_interfaces: "Інтерфейси в LACP",
    clone: "Клонувати",
    uni_interfaces_vlans: "Влани на UNI",
    ont_registered_but_not_found_automaticaly: "ОНУ зареєстровано, але не вдалося знайти перейти до ОНУ. Спробуйте перейти на ОНУ вручну, попередньо оновивши вкладку",
    onts_list: "Список ОНУ",
    ont_statistic: "Статистика ОНУ",
    traffic_chart: "Трафік (графік)",
    traffic_live_view: "Трафік (графік онлайн)",
    ont_success_reset: "Налаштування ОНУ успішно скинуті!",
    are_you_sure_to_reset_ont: "Впевнені, що хочете скинути налаштування ОНУ?",
    duplicated_onts: "Задубльовані ОНУ",
    device_is_offline_big_message: `
    <h4>Пристрій офлайн (не відповідає за SNMP/консолю)</h3> Деяка інформація може бути відображена з історії
    `,
    please_wait_working_with_device: "Зачекайте, будь ласка, запитуються дані з обладнання...",
    filters_modal: "Фільтри",
    show_devices: "Показати пристрої",
    show_onts: "Показати онти",
    show_links: "Показати посилання",
    clustering: "Групування",
    source: "Джерело",
    destination: "Призначення",
    ont_level_strength: "Рівні сигналу ОНУ",
    duplicated_mac: "Задубльований MAC",
    duplicated_macs: "Задубльовані MAC-и",
    count_duplicates: "Кіл. дублів",
    duplicates: "Дублікати",
    first: "Перший",
    last: "Останній",
    ifaces_detailed_statistic: "Детально про інтерфейси",
    interfaces_stat_type: "Тип статистики",
    splitted: "За типами",
    rules_not_found: "Правила не знайдено",
    uni_port_state_success_changed: "Стан UNI-порта успішно змінено",
    activated_box_license: "IN-BOX лицензія",
    abonent: "Абонент",
    information_not_collected_yet: "Інформація поки що недоступна, спробуйте пізніше",
    open_in_billing: "Відкрити в біллінгу",
    uid: "UID",
    toggle_not_avaliable_becouse_input: "Переключення неможливе, датчик знаходиться в стані - Вхідний",
    analog_lines_list: "Аналогові лінії",
    digital_lines_list: "Цифрові лінії",
    power_control_output_list: "Керування живленням",
    power_sensor_state: "Датчик живлення",
    sensors_not_found: "Датчики не знайдено",
    knock_sensor_state: "Датчик удару",
    digital_direction_input: "Вхідний",
    digital_direction_output: "Вихідний",
    low: "Низький",
    high: "Високий",
    power_supply: "Мережа 220В",
    security: "Безпека",
    water_leak: "Детектор протікання",
    smoke_detector: "Детектор диму",
    direction: "Направлення",
    controlled_by_pings: "Контролюється пінгом",
    controlled_by_analog_line: "Контролюється входом",
    seems_sensor_not_supported: "Схоже, тип датчиків не підтримується",
    temperature: "Температура",
    mark_as: "Маркер",
    not_marked: "Немає маркера",
    no_related_information_to_show: "Немає інформації для відображення",
    edit_port_state: "Змінити порт",
    are_you_sure_to_reset_port: "Ця дія може призвести до вимкнення всіх ONU на інтерфейсі. \nВпевнені, що хочете скинути порт?",
    port_success_reset: "Порт успішно скинутий!",
    reset_port: "Скинути порт (викл-вкл + ресет)",
    card_list: "Список карт",
    card_status: "Статуси карт",
    oper_status: "Актуальний стан",
    physical_ports_not_found: "Фізичні порти не виявлені",
    big_noty_subscription_limit_reached: `
        <h3>Досягнуто ліміт інтерфейсів підписки!</h3>
        Деякий функціонал, такий як: додавання нових пристроїв, збір нових інтерфейсів недоступний
    `,
    big_noty_connect_to_validation_server_failed: `
        <h3>Немає підключення до сервера валідації!</h3>
        Будь-ласка, перевірте доступ до інтернет та доступність api.wildcore.tools на сервері з wildcore 
    `,
    favorite_interfaces: "Вибрані інтерфейси",
    tagged_interfaces: "Інтерфейси з тегами",
    favorite_list: "Список вибраних",
    error: "ПОМИЛКА",
    interface_mark: "Відмітки інтерфейса",
    tags: "Теги",
    local_id: "Локальний ID",
    write_list_ip_address_with_cidr_format: "Пропишіть список дозволенних IP/мереж в форматі CIDR",
    strict_by_ip_access: "Обмеження входу по IP",
    enable_strict_ip: "Ввімкнути обмеження по IP",
    billing_info: "Инфо з біллінгу",
    contract: 'Договір',
    on: 'Ввімкнено',
    off: 'Вимкнено',
    device_not_found_in_oxidized: "Пристрій не знайдено в Oxidized",
    last_config: "Конфігурація",
    oxidized_configuration: "Конфігурація Oxidized",
    last_status: "Останній статус",
    start: "Початок",
    end: "Кінець",
    spent: "Зайняло",
    open_oxidized: "Відкрити в oxidized",
    oxidized: "Oxidized",
    backup_oxidized_enabled: "Ввімкнути бекапи (Oxidized)",
    agent_key: "Ключ агенту",
    registered_ip: "Зареестровані IP",
    reserved_interfaces: "Зарезервовані інтерфейси",
    from: "З",
    allowed_components: "Дозволені компоненти",
    version: "Версія",
    count_groups: "Кількість групп",
    usage_statistic: "Статистика використання",
    subscription_info: "Інформація про підписку",
    preview_commands_before_send: "Переглянути команди перед виконанням",
    interfaces_not_loaded: "Інтерфейси ще не завантажені...",
    are_you_sure_for_clear_poller_history: "Ви впевнені, що хочете очистити історию?",
    success_cleared: "Успішно очищено",
    clear_history: "Clear poll history",
    ont_statuses: "ONT статуси",
    device_statuses: "Статуси пристроїв",
    analytics_click_on_chart_for_load_data: "Натисніть на потрібний час, щоб переглянути таблицю більш детально на вибраний час",
    graph_step: "Крок графіка",
    chart_data: "Дані діаграми",
    analytics: "Аналітика",
    rebuild_required_description: `
      <span style="font-weight: bold; color: darkred">*</span> Щоб застосувати змінений параметр, потрібно перебудувати контейнери, виконати команду на сервері <pre>cd /opt/wildcore-dms && sudo docker compose up -d --build</pre>
    `,
    are_you_sure_to_reboot_onu: "Ви впевнені, що перезавантажити ONT?",
    are_you_sure_to_delete_ont: "Ви впевнені, що хочете видалити ONT?",
    ont_success_deleted: "ONT успішно видалено",
    breadcrumb_show: "Показати назву пристрою",
    has_active_pollers: "Пристрій має активні фонові опитувальники. Отримання інформації може тривати довше.",
    history_is_empty: "Історія несправностей порожня",
    public_community: "RO community",
    private_community: "RW community",
    public_community_placeholder: "громадськість",
    private_community_placeholder: "приватність",
    snmp_version: "Версія SNMP",
    interfaces_list: "Список інтерфейсів",
    current_channel: "Поточний канал",
    auth_mode: "Режим авторизації",
    sn_bind: "SN зв'язати",
    dba_mode: "Режим DBA",
    fec: "FEC",
    fec_actual_mode: "Фактичний режим FEC",
    pps1_tod: "PPS1 TOD",
    auto_replace: "Автозаміна",
    mcast_encrypt: "Шифрування Mcast",
    down_history_table: "Таблиця спадної історії",
    port_loading: "Завантаження порту",
    poller_in_process: "Опитувальник в процесі",
    reboot_device: "Перезавантажте пристрій",
    save_config: "Зберегти конфігурацію на пристрої",
    field_name: "Назва поля",
    ident: "Ідент",
    optical_rx: "Оптичний RX",
    optical_tx: "Оптичний TX",
    optical_olt_rx: "Оптичний OLT TX",
    optical_temperature: "Оптична температура",
    optical_distance: "Оптична відстань",
    no_filter: "Без фільтра",
    poller_data: "Дані опитувальника",
    ont_list: "Список ONT",
    agreement: "Угода",
    value: "Значення",
    filter_field_name: "Поле фільтра",
    no_data: "Немає даних",
    disable_ont: "Вимкнути ONT",
    lease_info: "Інформація про оренду",
    go_to_boxes_list: "Перейти до списку ящиків",
    pon_ports_list: "Список портів PON",
    pon_ports_optical: "Порти PON оптичні",
    sys_resources: "Системні ресурси",
    incorrect_value_for: "Неправильне значення для '{value}'",
    supported_modules: "Підтримувані модулі",
    interface_not_loaded: "Інтерфейс не завантажується",
    reboot_ont: "Перезавантаження ONT",
    address_list_info: "Інформація про список адрес",
    arp_info: "ARPs",
    dhcp_server_info: "DHCP сервери",
    leases_info: "Оренда",
    simple_queue_info: "Проста черга",
    device_is_offline: "Пристрій офлайн",
    device_dash: {
        tabs: {
            history_log: "Лог статусів",
            macros: 'Макроси',
            pinger: 'Пінгер',
            onts_tree: "Дерево ONT",
            events: "Події",
            pon_boxes: "Pon ящики",
            unregistered_onts: "Незареєстровані ONT",
            deprecated_unregistered_onts: "Незареєстровані ONT (deprecated)",
            ports: "Порти",
            interfaces: "Інтерфейси",
            address_list: "Список адрес",
            arps: "ARPs",
            leases: "Оренда",
            dhcp_servers: "DHCP серверів",
            simple_queues: "Прості черги",
            bgp_sessions: "Сесії BGP",
            topology: "Топологія",
            vlans: "Влани",
            oxidized: "Бекапи",
            card_interfaces: "Фіз. інтерфейси",
        }
    },
    uni_extra_info: {
        type: "Тип",
        flow_control_status: "Статус керування потоком",
        pvid_mode: " Режим PVID",
        admin_status: "Адмін статус",
        vlan_id: "Vlan ID",
        vlan_mode: "Режим Vlan",
        admin_state: "Адмін статус",
        duplex: "Дуплекс",
        speed: "Швидкість",
        id: "ID",
        name: "Ім'я",
        mode: "Режим",
        pvid: "PVID",
        trunk_vlans: "Магістральні vlans",
        uni_interfaces_vlans: "vlans на UNI ",
        auto_negotation: "Автопогодження",
        flow_control: "Управління потоком",
        vlan_pvid: "PVID",
        vlan: "VLAN",
        admin_speed: "Адмін швидкість",
        power_control: "Контроль потужності",
    },
    gpon_profiles: "Профілі GPON",
    auth_info: "Авторизація",
    ont_configuration: "Конфігурація ONT",
    last_dereg: "Останнє скасування",
    last_dereg_since: "Останнє скасування",
    last_reg: "Остання реєстрація",
    parse_interface: "Розбір інтерфейсу",
    olt: "OLT",
    ont_status: "ONT статус",
    storage_info: "Інформація про зберігання",
    vendor_info: "Інформація про постачальника",
    poll_enabled: "Опитування ввімкнено",
    ver_hardware: "Версія обладнання",
    ver_software: "Версія програмного забезпечення",
    extra_info: "Додаткова інформація",
    go_to_events: "Перейти до подій",
    go_to_devices_list: "Перейти до списку пристроїв",
    from_storage: "Зі складу",
    from_device: "З пристрою",
    device_calling: "Виклик пристрою",
    has_configuration: "Має конфігурацію",
    view: "Перегляд",
    component_configuration: "Конфігурація компонентів",
    load_config: "Завантажити конфігурацію",
    loading_is_very_slow: `Завантаження відбувається дуже повільно :-( <br><small>Спробуйте перезавантажити сторінку</small>`,
    reload_page: "Перезавантажити сторінку",
    online_devices: "Онлайн пристрої",
    up_interfaces_chart: "Графіки інтерфейсів UP",

    ont_statuses_pie: "Статуси ОНУ",
    widgets: {
        "count_filled_pon_boxes": "Кількість заповнених PON-боксів",
        tags: "Теги",
        favorite_interfaces: "Вибрані інтерфейси",
        high_links_utilization: "Кількість навантажених з'єднань",
        bad_ont_signal: "Кількість ОНУ з поганим сигналом",
        ont_statuses_pie: "Статуси ОНУ",
        ont_signal_bar: "Рівні сигналів ОНУ",
        pinger_stat: "Pinger stat",
        events_stat: "Статистика подій (за ступенем тяжкості)",
        events_stat_by_name: "Статистика подій (по імені)",
        last_user_activity: "Остання активність користувача",
        events_table: "Таблиця подій",
        unregistered_onts_table: "Незареєстрована таблиця ONT (deprecated)",
        unregistered_onts: "Незареєстрована таблиця ONT",
        system_stat: "Статистика системи",
        device_calling_errors_stat: "Статистика помилок виклику пристрою",
        device_status_chart: "Графіки стану пристрою",
        online_onts_chart: "Онлайн графік ONT",
        up_ifaces_chart: "Діаграма онлайн-інтерфейсів",
    },
    delete_access: "Видалити доступ",
    edit_access: "Доступ до редагування",
    dashboard_saved: "Дашборд збережено",
    events_by_name_stat: "Кількість подій за ім'ям (ост. доба)",
    count: "Кількість",
    device_calling_errors_stat: "Помилки роботи з обладнанням (ост. доба)",
    errors_count: "Кількість помилок",
    not_responding_count: "Кількість без відповіді",
    top_devices_by_errors: "Топ обладнання з помилками",
    system_stat: "Зведені сховища",
    count_device_groups: "Кільк. груп",
    count_users: "Кільк. користувачів",
    count_roles: "Кільк. ролей",
    unregistered_onts_not_found: "Незареєстровані ОНУ не знайдені",
    set_for_all_users: "Встановити для всіх",
    role_successful_updated: "Роль успішно оновлено",
    user_role: "Користувальницькі ролі",
    add_widget: "Додати віджет",
    choose_widget: "Виберіть віджет",
    pinger: "Пінгер",
    last_user_activity: "Ост. активність",
    events_table: "Таблиця подій",
    events_stat: "Зведена подій",
    onu_offline_signals_not_avaliable: "ОНУ офлайн, перегляд сигналів у реалтайм неможливо",
    live_traffic_view: "Реалтайм трафік",
    update_interval: "Інтервал оновлень",
    in: "In",
    out: "Out",
    current: "Поточні",
    avg: "Середні",
    max: "Максимальні",
    enable_ont: "Включити ОНУ",
    last_change: "Остання зміна",
    omcc_version: "OMCC версія",
    model_id: "Model ID",
    versions: "Версії ПЗ",
    vlan_profile_name: "VLAN profile name",
    mode: "Mode",
    trunk_vlans: "Trunk vlans",
    description_success_updated: "Опис успішно оновлено",
    ont_success_enable: "ОНУ успішно ввімкнено",
    ont_success_disable: "ОНУ успішно вимкнено",
    are_you_sure_to_disable_ont: "Впевнені, що хочете вимкнути ОНУ?",
    are_you_sure_to_enable_ont: "Впевнені, що хочете ввімкнути ОНУ?",
    check_uplink: "Перевіряти аплінк",
    link_types: {
        manual: "Manual",
        lldp: 'LLDP',
        fdb: 'FDB',
    },
    coordinates_not_setted: "Координати не встановлено",
    interfaces_list_success_polled: "Список інтерфейсів успішно опитаний",
    not_setted: "Не встановлено",
    not_choosed: "Не вибрано",
    choose_device_is_required: "Пристрій вибирати обов'язково",
    delete_link: "Видалити лінк",
    are_you_sure_delete_link: "Впевнені, що хочете видалити лінк?",
    links_not_added: "З'єднання не додано",
    link_connection_type: "Тип з'єднання для цього пристрою",
    uplink: "Uplink",
    downlink: "Downlink",
    uplink_device: "Uplink-пристрій",
    uplink_interface: "Uplink-інтерфейс",
    downlink_device: "Downlink-пристрій",
    downlink_interface: "Downlink-інтерфейс",
    snmp_port: "SNMP порт",
    choose_device_coordinates_before_start_work_with_device: "Спочатку встановіть координати пристрою",

    phpmyadmin: "phpMyAdmin",
    creating_new_link: "Створити новий link",
    searching_string: "Фільтр пошуку пристроїв",
    choose_devices_for_send_notifications: "Вибір пристроїв для відправки повідомлень",
    edit_monitoring_devices: "Змінити пристрої для моніторінга",
    edit_notification_devices: "Змінити пристрої для моніторінга",
    address_not_found: "Адреса не знайдена",
    click_to_button_map_for_set_coordinates: "Нажміть на кнопку, щоб встановити координати",
    choose_coordinate: "Вказати координати",
    display_by_status: "Підсвічувати за статусом",
    by_status: "Cтатус",
    no_mon_interfaces: "Не моніториться",
    count_mon_interfaces: "Моніториться ОНУ",
    location_from_coordinates: "Положення за координатами",
    add_new_box: "Додати новий бокс",
    create_new_box: "Додавання нового бокса",
    template_info: "Про шаблон",
    template_id: "ID шаблона",
    count_boxes: "Кількість боксів",
    count_links: "Кількість з'єднань",
    template: "Шаблон",
    choose_onts_for_monitoring: "Виберіть ОНУ для моніторингу",
    mon_onts: "Моніторинг ОНУ",
    to_boxes: "До списку боксів",
    view_on_map: "Відкрити шаблон на мапі",
    box_id: "ID боксу",
    box_info: "Інформация про бокс",
    links: "З'єднання",
    success_saved: "Успішно збережено!",
    are_you_sure_to_delete_box: "Впевнені, що хочите видалити бокс?",
    are_you_sure_delete_element: "Впевнені, що хочите видалити єлемент?",
    are_you_sure_to_delete_link: "Впевнені, що хочите видалити з'єднання?",
    edit_box_with_name: "Редагування боксу з іменем {name}",
    neighbor: "Сусід",
    editing_link_with_id: "Редагування з'єднання з ID={id}",
    coordinates: "Положення",
    type_or_name_not_setted_object_deleted: "Тип чи ім'я об'єкту не вказано, створення буде скасовано",
    mon_interfaces: "Кількість ОНУ на моніторингу",
    add_link: "Додати з'єднання",
    pon_boxes: {
        name: 'PON-бокси',
        list: 'Список боксів',
        map: 'Мапа',
        types: 'Налаштування типів',
        map_builder: "Редагування на мапі",
        tabs: {
            boxes: 'Типи боксів',
            links: "Типи з'єднань",
        },
    },
    edit_type: 'Редагування типу',
    general: 'Основне',
    styling: 'Стилізація',
    size: 'Розмір',
    color: 'Колір',
    stroke_size: 'Розмір підсвічування',
    stroke_color: 'Колір підсвічування',
    box_types: {
        tabs: {
            boxes: 'Бокси',
            links: "З'єднання",
        }
    },
    add_element: 'Додати єлемент',
    map: 'Мапа',
    edit_box: 'Редагувати бокс',
    box_name: "Им'я боксу",
    monitoring_onts: "ОНУшки для моніторингу",
    control: 'Управління',
    edit_link: "Редагувати з'єднання",
    element_success_created: 'Елемент успішно створено',
    for_control_choose_device_and_interface: 'Виберіть ОНУ для моніторингу бокса',
    error_choose_device_must_contain_coordinates: 'Помилка завантаження пристрою. Пристрій повинен мати координати',
    poller_not_worked_yet: "Опитувальник ще не працював",
    start_poller_requested: "Запит опитувальника відправлено",
    error_loading_info: "Помилка отримання інформації",
    global: "Глобальні",
    notification_place: "Розміщення повідомлень",
    noty_places: {
        topLeft: "Зверху ліворуч",
        topRight: "Зверху праворуч",
        topCenter: "Зверху по центру",
        center: "По центру",
        centerLeft: "По центру ліворуч",
        centerRight: "По центру праворуч",
        bottom: "Знизу",
        bottomLeft: "Знизу ліворуч",
        bottomCenter: "Знизу по центру",
        bottomRight: "Знизу праворуч",
    },
    vlan_pvid: "VLAN Pvid",
    vlan_mode: "VLAN Mode",
    auto_negotation: "Auto negotation",
    flow_control: "Flow control",
    dhcp_snooping: "DHCP Snooping",

    start_write_for_search: "Searching...",
    start_write_for_search_by: "Searching by...",
    show_groups: "Відображати в группах",
    old_agent_version_reload_your_page: "Ви використовуєте стару версію веб-панелі. Будь-ласка, перезавантажте сторінку без кешу (CTRL+F5)",
    agent_is_disabled: "Агент вимкнено. Будь-ласка, зв'яжіться з support@wildcore.tools",
    incorrect_field_must_be_regex: "Неправильне значення. Повинно відповідати '{regexp}'",
    last_reg_since: "Зареєстровано з",
    detailed_info: "Атрибути",
    ignore_actions: "Ігнорувати дії",
    rule_success_validated: "Правило успішно перевірено",
    show_pinger_info: "Відображати інформацію з пінгера",
    delete_role: "Видалити роль",
    validate_rule: "Перевірити роль",
    add_action_for_notification: "Додати дію для повідомлень",
    portal_settings: "Налаштування порталу",
    show_error_counters: "Відображати лічильники помилок",
    show_events: "Відображати події",
    show_unregistered_onts: "Відображати незареєстровані ОНУ",
    expand_pon_tree: "Розкривати дерево PON",
    device_dashboard: "Список пристроїв",
    down_devices_on_top: "Впавші пристрої спочатку",
    sort_by: "Сортувати по",
    your_role: "Ваша роль",
    notifications_configuration: "Налаштування повідомлень",
    log_poller: "Логи опитувача",
    pollers: "Опитувачі",
    poller: "Опитувач",
    default_pollers: "Опитувачі за замовчуванням",
    poller_configuration: "Налаштування опитувача",
    use_default_poll_configuration: "Використовувати налаштування опитувачів за замовченням",
    poller_name: "Ім'я опитувача",
    interval: "Інтервал (сек)",
    configure_port_polling: "Налаштування опитувачів по портам",
    count_interfaces: "Кількість інтерфейсів",
    count_polled: "Кількість опитуванних інтерфейсів",
    edit_ports: "Змінити порти",
    bind_key: "Bind ID",
    enable_polling: "Ввімкнути опитування",
    edit_interface_polling: "Налаштування опитувань по порту",
    interfaces_list_not_loaded: "Список інтерфейсів не завантаженний",
    poll_interfaces: "Опитати інтерфейси",
    run_poller: "Запустити опитування",
    roles: "Ролі",
    user_role_list: "Ролі користувачів",
    create_new_role_btn: "Створити нову роль",
    role_permissions_count: "Кількість прав",
    edit_role: "Змінити роль",
    role_privileges: "Привілегії",
    create_new_role: "Створити нову роль",
    role_successful_created: "Роль успішно створена",
    are_you_sure_for_delete_role: "Впевнені, що хочете видалити роль?",
    role_success_deleted: "Роль успішно видалена",
    role: "Роль",
    configure_notifications_by_events: "Налаштувати сповіщення по подіям",
    configure_notifications_by_actions: "Налаштувати сповіщення по діям",
    add_event_for_notification: "Додати сповіщення про подію",
    event_name: "Ім'я події",
    send_resolved: "Відправляти завершення",
    delay_before_send: "Очікувати перед відправкою",
    action_name: "Ім'я дії",
    send_on_status_failed: "Відправляти при FAILED",
    send_on_status_success: "Відправляти при SUCCESS",
    action_notification: "Повідомлення по діям",
    event_configuration: "Налаштування подій",
    group_name: "Ім'я группи",
    for: "Для",
    alert_name: "Ім'я алерту",
    expression: "Вираз",
    annotation_summary: "Анотація",
    annotation_description: "Опис",
    notification_configuration_success_saved: "Налаштування сповіщень успішно збережена!",
    add_rule: "Додати правило",
    events: {
        name: "Події",
        tabs: {
            alertmanager: "Alertmanager",
        }
    },
    your_login: "Ваш логін",
    id: "ID",
    language: "Мова",
    rules_success_saved: "Правила успішно збережені!",
    notifications: {
        contact_telegram_message: `
        <div style="margin-top: 10px">
        Дізнатись Telegram chat ID можна через бот - <a href="#" target="_blank">@bot</a>. Бот поверне ваш ID, введіть його тут<br>
        <div style="text-align: center; font-weight: bold">АБО</div>
        <div  style="">
            <li>Додайте контакт "номер телефону для телеграм"</li>
            <li>Перейдіть до свого бота - <a href="{url}" target="_blank">@{name}</a> і відправте команду /start. Ваш чат буде додано автоматично</li>
        </div>
        </div>
    `,
        phone_for_telegram_descriptions: `
            <br><h4>Вказаний номер телефону використовується лише для реєстрації в телеграм</h4>
        `,
        types: {
            EMAIL: "Email",
            TELEGRAM_ID: "Telegram",
            PHONE_FOR_TELEGRAM: "Номер телефону для Telegram",
            PHONE: "Номер телефону",
        },
        channels_not_configured: {
            email: `
            Відправка через Email не налаштована.
            Ви можете додати контакт, але відправка буде працювати після налаштування каналу`,
            phone: `Номер телефону`,
            phone_for_telegram: `Номер телефону використовується лише для реєстрації в телеграм`,
            telegram: `Відправка через телеграм не налаштована`,
        },
        tabs: {
            events_config: "Правила подій",
            actions_config: "Правила дій",
            email: "Канал відправки - Email",
            telegram: "Канал відправки - Telegram",
        },
        severities: {
            NOTIFICATION: "Notify",
            INFO: "Info",
            WARNING: "Warning",
            CRITICAL: "Critical",
        },
    },
    sfp_diag: "Діаг SFP",
    speed: 'Швидкість',
    duplex: 'Duplex',
    uni_interfaces_status: "Статус интерфейсів UNI",
    pon_onts_down_history: "Історія падінь ONT",
    pon_onts_configuration: "Конфігурація ONT",
    interface_counters: "Показники на інтерфейсі",
    conf_status: "Стан конфігурації",
    auth_method: "Спосіб авторизації",
    isolation: "Ізоляція",
    attributes: "Атрибути",
    down_reason: "Причина падіння",
    web: "Web",
    telnet: "Telnet",
    ssh: "SSH",
    system: "Система",
    ports: "Порти",
    diag: "Діагностика",
    sfp_info: "Інформація про SFP",
    media_info: "Інформація про Media",
    tx_bias: "Зміщення TX",
    serial_num: "Серійний номер",
    connector_type: "Тип роз'єму",
    fiber_type: "Тип волокна",
    eth_compliance_codes: "Коди відповідності Eth",
    baud_rate: "Швидкість передачі даних",
    vendor_name: "Ім'я постачальника",
    part_number: "Part number",
    networks_success_saved: "Мережі успішно збережено!",
    are_you_sure_to_delete_element: "Ви впевнені,що хочете видалити елемент?",
    autodiscovery_networks_not_found: "Конфігурацій автоматичного виявлення не знайдено",
    add_network: "Додати мережу",
    network_cidr: "Мережа CIDR",
    device_access: "Доступ до пристрою",
    add_to_device_group: "Додайте нові пристрої до групи",
    autodiscovery_device_access_is_required: "Потрібен доступ до пристрою! Ви повинні вибрати доступ до використання для мережі {network}",
    autodiscovery_device_group_is_required: "Необхідна група пристроїв! Ви повинні вибрати групу пристроїв для мережі {network}",
    config_autodiscovery: "Автовиявлення",
    welcome_to_nms: "Ласкаво просимо!",
    welcome_message: `
<h3 class="text-info">Ласкаво просимо  в веб-панель <b>WildCore DMS</b>!</h3>
            <div>
              <h5 class="text-info">Оцініть можливості діагностики і моніторингу обладнання</h5>
              <ol>
                <li>Додайте свій перший доступ до обладнання(community/login/password)
                  <a href="/management/device-access">тут</a>
                </li>
                <li>Додайте свій перший пристрій
                  <a href="/management/device">тут</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Налаштуйте надсилання повідомлень до Telegram або на електронну пошту</h5>
              <ol>
                <li>Налаштуйте способи надсилання повідомлень -
                  <a href="/config/notifications">Налаштування алертів</a>
                </li>
                <li>Додайте свої контакти для отримання повідомлень -
                  <a href="/account/settings">Налаштування акаунта</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Надайте доступ до wildcoreDMS колегам</h5>
              <ol>
                <li>Створіть свою групу користувачів,надамо необхідні їм доступи  -
                  <a href="/management/user-group">Групи</a>
                </li>
                <li>Додайте нових користувачів -
                  <a href="/management/user">Користувачі</a>
                </li>
              </ol>
            </div>    
    `,
    here: "тут",
    bind_status: "Статус прив'язки",
    olt_rx: "OLT RX",
    onu_statistic: "ONU статистика(лічильники)",
    pon_onts_reasons: "Причини падіння",
    discards: "Discards",
    multicast: "Multicast",
    broadcast: "Broadcast",
    pon_onts_status: "PON ONU статус",
    interface_descriptions: "Опис інтерфейсу",
    pon_onts_vendor: "Інформація про постачальника",
    used_default_connections_parameters: "Використовуються параметри підключень за замовчуванням",
    change_default_connection_parameters: "Встановити спеціальні параметри підключення",
    set_default_connection_parameters: "Встановити параметри підключення за замовчуванням",
    connection_connection_type: "Тип підключення",
    port: "Порт",
    timeout_sec: "Тайм-аут (сек)",
    snmp_timeout_sec: " Тайм-аут SNMP (сек)",
    snmp_repeats: "SNMP повтори",
    labels: "Теги",
    ignore_events: "Ігнорувати події",
    add_contact: "Додати контакт",
    edit_contact: "Редагувати контакт",
    are_you_sure_delete_contact: "Ви впевнені, що потрібно видалити контакт?",
    configured_sources_not_found: `
       Не знайдено налаштованих джерел <br>
        <small>Будь ласка,спочатку налаштуйте джерела</small>
    `,
    telegram_bot_configuration: "Конфігурація Telegram-бота",
    configuration_success_updated: "Конфігурацію успішно оновлено",
    reset: "Скинути",
    telegram_bot_name: "Ім'я бота",
    telegram_bot_api: "Ключ API бота",
    smtp_host: "SMTP хост",
    your_bot_username: "Ім'я вашого бота ",
    your_api_key: "Ваш ключ API",
    smtp_port: "SMTP порт",
    smtp_secure: "Використовуйте безпечно",
    smtp_username: "Ім'я користувача SMTP",
    smtp_password: "пароль SMTP",
    from_name: "Від імені",
    from_email: "Відправляти з адреси",
    template_configuration: "Конфігурація шаблону",
    alert_template: "Шаблон сповіщення",
    alert_resolved: "Шаблон вирішення",
    mock_object: "Макетний об'єкт",
    alerts_configuration: "Конфігурація сповіщень",
    smtp_configuration: " Конфігурація SMTP",
    cpu_util_chart: " Графік використання CPU",
    memory_util_chart: "Графік використання пам'яті",
    disk_util_chart: "Графік використання диска",
    no_more_events: "Більше ніяких подій",
    no_events: "Подій не зафіксовано",
    severities: "Важливість",
    only_not_resolved: "Не вирішено",
    severity: "Важливість",
    resolved_at: "Вирішено о",
    names: "Ім'я",
    created_at: "Створено в",
    not_resolved: "Не вирішено",
    no_device: "Немає пристрою",
    are_you_sure_for_resolve_event: "Ви впевнені, що хочете закрити подію з id={id}?",
    are_you_sure_for_resolve_all_filtered_events: "Ви впевнені, що хочете закрити всі події за допомогою фільтра?",
    events_success_resolved: "Успішно вирішено {count} подій",
    resolve: "Завершити",
    event_success_resolved: "Подія з id={id} успішно завершена",
    resolve_displayed: "Розв'язання відфільтровано",
    grafana: "Grafana",
    alertmanager: "Alertmanager",
    prometheus: "Prometheus",
    external_apps: "Зовнішні додатки",
    models: "Моделі",
    device_group: "Група пристрою",
    icon: "Значок",
    controller: "Контролер",
    edit_model: "Зміна моделі {name}",
    device_model_success_updated: "Модель {name} успішно оновлена",
    agent_disabled: "Жодна активна підписка/агент не вимкнена, зв’яжіться з support@wildcore.tools",
    no: "Ні",
    yes: "Так",
    limit_at: " Ліміт At",
    max_limit: "Максимальний ліміт",
    dynamic: "Динаміка",
    target: "Ціль",
    reload: "Перезавантажити",
    traffic: "Трафік",
    last_link_up_time: "Час останнього посилання",
    interface_name: "Ім'я інтерфейсу",
    comment: "Коментар",
    remote_address: "Віддалений адрес",
    remote_as: "Віддалений AS",
    local_address: "Місцевий адрес",
    established: "Встановлено",
    host_name: "Ім'я хоста",
    server: "Сервер",
    lease_time: "Час оренди",
    up: "Up",
    down: "Down",
    address: "Адреса",
    expired_at: "Термін дії закінчується",
    address_pool: "Адресний пул",
    count_arps: "ARPs<br><small>Кількість</small>",
    bgp_sessions: "BPG-сесії<br><small>(Активні/All)</small>",
    interfaces_status: "Стан інтерфейсів<br><small>(Активні/All)</small>",
    interfaces_state: "Статус інтерфейсу адміністратора<br><small>(Відключені/All)</small>",
    router_os: {
        tabs: {
            interfaces_list: "Інтерфейси",
            arps: "ARPs",
            bgp_sessions: "BGP-сесії",
            address_lists: "Address list",
            leases: "Leases",
            dhcp_servers_list: "DHCP-сервери",
            simple_queue_list: "Шейпер",
        }
    },
    chart_translations: {
        Online: "Online",
        Offline: "Offline",
        PowerOff: "PowerOff",
        LOS: "LOS",
        up: 'Up',
        down: 'Down'
    },
    pinger_host_stat: "Стан пристроїв (ICMP)",
    optical_level_chart: "Сила оптичного рівня",
    displayed_with_step: "Відображається з кроком",
    temp_chart: "Графіка температури",
    voltage_chart: "Графіка напруги",
    last_6_hours: "Останні 6 годин",
    last_12_hours: "Останні 12 годин",
    last_24_hours: "Останні 24 години",
    last_3_days: "Останні 3 дні",
    last_7_days: "Останні 7 днів",
    last_14_days: "Останні 14 днів",
    last_21_days: "Останній 21 день",
    last_30_days: "Останні 30 днів",
    choose_group_is_required: "Вибрати групу обов'язково!",
    filter: 'Фільтр',
    expand_all: 'Відкрити все',
    hide_all: 'Згорнути все',
    search_query: 'Пошук',
    device_models: 'Моделі обладнання',
    clear_filters: 'Очистити фільтр',
    group_success_created: 'Група успішно створена',
    group_success_updated: 'Група успішно оновлена!',
    cleared: 'Очищено!',
    saved: 'Збережено!',
    device_groups: "Групи пристроїв",
    device_group_management: "Управління групами пристроїв",
    create_new_group_btn: "Додати нову групу",
    edit_group_with_id: "Редагувати групу з ідентифікатором id {id}",
    checked: "Перевірено",
    parameters_is_empty_are_you_sure: "Параметри не встановлені! Упевнені, що бажаете зберегти?",
    fdb_history_card: "FDB історія",
    history_not_found: "Записів не знайдено",
    fdb_history: "Історія FDB - {interface}",
    active_now: "Активно зараз",
    key: "Ключ",
    schedule_reports: "Рапорти планувальника",
    schedule_keys: "Ключі планувальника",
    hide_finished: "Сховати збережені",
    schedule: "Завдання",
    started_at: "Запущений",
    finished_at: "Завершений",
    schedule_configuration: "Конфігурація планувальника",
    last_run: "Ост. запуск",
    component: "Компонент",
    period: "Крон",
    command: "Команда",
    state: "Статус (Вкл/Викл)",
    system_cron: "СИСТЕМА",
    schedule_updated: "Планувальник оновлений!",
    choose_model_is_required: "Потрібно вибрати модель",
    choose_access_is_required: "Потрібно вибрати доступ",
    ip_address_not_valid: "Некоректний IP адрес",
    default_value: "Значення за замовчуванням",
    system_configuration: "Конфігурація системи",
    error_load_fdb: "Помилка завантаження FDB",
    pon_onts_serial: "Серійний номер",
    location: "Розміщення",
    load_info_from_device: "Отримати інформацію з пристрою",
    start_at: "Запустив",
    stop_at: "Закінчив",
    unknown: "невідомо",
    error_get_data_check_in_logs: "Помилка отримання даних. Дивіться  логи виклики пристрою для отримання більш детальної інформації",
    on_device: "на пристрої",
    ont_ident: "Ідентифікатор ОНУ",
    mac_in_fdb: "MAC в FDB",
    device_is_offline_and_history_not_found: "Пристрій не в мережі, інформація недоступна",
    edit_port: "Конфігурування порта {port}",
    interface_info: "Про інтерфейс",
    admin_speed: "Адмін. швидкість",
    vlans_by_port: "Влани на портах",
    rmon: "RMON",
    port_success_updated: "Порт {port} успішно змінено!",
    diag_check: "Перевірити",
    link_info: "Інфо про з'єднання",
    vlans: "Влани",
    errors: "Помилки",
    clear_counters: "Очистити показники",
    reboot: "Перезагрузка",
    are_you_sure_to_reboot_device: "Впевнені, що бажаєте перезавантажити пристрій?",
    are_you_sure_to_clear_counters: "Впевнені, що хочите скинути показники?",
    counters_success_reset: "Показники успішно скинуто!",
    device_success_sended_to_reboot: "Пристрій успіншно відправлено в перезагрузку",
    fdb: "FDB",
    cable_diag: "Діаг. кабеля",
    port_num: "# порта",
    vlan: "Влан",
    counters: "Трафік",
    stat: "Статистика",
    uni_ports: "Порти ОНУ",
    are_you_sure_to_reset_onu: "Впевнені, що хочете скинути ОНУ?",
    are_you_sure_to_clear_counters_onu: "Впевнені, що хочете очистити показники?",
    onu_success_reseted: "ОНУ успішно скинута",
    onu_success_counters_cleared: "Показники успішно очищені",
    action_clear_counters_onu: "Очистити показники",
    action_reset_onu: "Скинути ОНУ",
    admin_status: "Адмін статус",
    mac_address: "MAC-адрес",
    info_status: "Інформація про статус",
    last_down_reason: "Остання причина падіння",
    online_since: "В онлайні",
    offline_since: "В офлайні",
    distance: "Відстань",
    check_modules_status: "Статус інфо",
    pon_fdb: "FDB таблиця",
    onu_status: "Стан ОНУ",
    pon_onts_general_info: "Основна інфа про стан",
    pon_onts_mac_addr: "МАК-адрес ОНУ",
    vendor: "Виробник",
    hardware_ver: "Версія апаратного забезпечення",
    software_ver: "Версія ПО",
    optical_info: "Інфо про оптичний сигнал",
    rx: "RX",
    tx: "TX",
    temp: "Температура",
    voltage: "Напруга",
    uni_port: "Порт в ОНУ",
    meta_information: "Мета інформація",
    onts_info: "Інформація про ОНУ",
    pon_onts_mac_addresses: "МАК-адреса ОНУ",
    pon_onts_optical: "Оптичні сигнали ОНУ",
    pon_onts_status_detailed: "Стан ОНУ",
    from_cache: "с кешу",
    big_notify: {
        device_is_offline: "<h3>Устройство не в мережі!</h3><b>Відображена інформация з історії</b>"
    },
    onu_success_sended_to_reboot: "ОНУ успішно відправлена в перезавантаження",
    account_settings: "Параметри аккаунту",
    logout: "Вийти с системи",
    extra_parameters: "Додаткові параметри",
    onts_not_found_by_search: "ОНУ не знайдені за запитанням",
    server_errors: {
        SWC_ERROR_WORK_WITH_DEVICE: "Помилка роботи с обладнанням",
        GENERAL_SERVER_ERROR: "Виникла помілка при роботі з сервером, зверніться до адмінистратора",
        SERVER_ERROR: "Виникла непередбачувана помилка, зверніться до адміністратора",
        SWC_SNMP_EXCEPTION: "Виникла помилка опитування обладнання по SNMP",
        INSUFFICIENT_PRIVILEGES: "Недостатньо прав, зверніться до адмінистратора",
        DEVICE_ALREADY_EXIST: "Пристрій вже існує",
    },
    device_success_deleted: "Обладнання успішно видалено",
    search_placeholder: "Начніть вводити для пошуку...",
    go_to_device: "Перейти до обладнання",
    my_account: "Мій акаунт",
    parameters_success_saved: "Параметри успішно збережені",
    account_settings_saved: "Параметри збережені",
    reload_from_cache: "Перезавантажити",
    device_success_registered: "Пристрій успішно збереженно",
    cancel: "Відмінити",
    service_profile: "Service профайл",
    config_state: "Стан конфігурації",
    vport_mode: "Vport режим",
    register: "Зареєструвати",
    action_dereg_onu: "Видалити ОНУ",
    fdb_is_empty: "FDB таблиця пуста",
    are_you_sure_to_dereg_onu: "Впевнені, що хочете видалити ОНУ?",
    are_you_sure_reboot_onu: "Впевнені, що хочете перезавантажити ОНУ?",
    onu_success_registered: "ОНУ успішно зареєстрована",
    ont_success_sended_to_reboot: "ОНУ успішно відправлена в перезавантаження",
    onu_success_deregistrated: "ОНУ успішно видалена",
    type: "Тип",
    onu_registration: {
        duplicate_key: "Дублікат ключа з іменем '{key}'",
        template_success_saved: "Шаблон успішно збережено",
        twig_easy_doc: `
            <h4>Для генерації шаблону використовується Twig</h4>
            <span style="color: darkred; font-weight: bold">Читайте <a href="https://twig.symfony.com/doc/3.x/templates.html" target="_blank">офіційну документацію 'Twig for designers'</a>, щоб дізнатися можливості Twig. <br></span><br>
            <span style="color: gray">При внесені змін, в вікні 'живий результат' буде відображатись сгенерованих сервером список команд  на базі шаблона, які будуть виконані на обладнанні. </span>
        `,
        select_from_predefined_description: `
            Вкажіть список параметрів використовуючи перенос строки 
        `,
        unregistered_ont: "Незареєстрована ОНУ",
        deprecated_unregistered_ont: "Незареєстрована ОНУ (deprecated)",
        key_reg_description: `
            Ключ повинен відповідати регулярному виразу: ^[a-z][a-z_0-9]{1,}$.
            Звернення до значення  ключа повинно бути по params.<key name>`,
        visible_if_description: `Вимога на javascript для відображення`,
        select_from_predefined: "Вибрати з встановлених",
        select_from_variable: "Вибрати зі змінних ",
        save_parameters: "Зберегти параметри",
        input_variable: "Поле для введення з значенням",
        input_string: "Поле для введення",
        checkbox: "Чекбокс",
        all_device_types: "Всіх типів",
        live_result: "Живий результат",
        add_parameter: "Добавити параметр",
        template: "Шаблон",
        visible_for: "Відображати для",
        source_param_key: "Джерело значення",
        default_value: "Значення за замовчуванням",
        variants: "Варіанти",
        parameter_can_be_empty: "Параметр може бути пустим",
        key: "Ключ",
        visible_name: "Відображення імені",
        parameter_type: "Тип параметра",
        template_variables: "Конфігурація параметрів",
        predefined_variables_detailed: "Виберіть пристрій для отримання змінних",
        profile_line: "Line профайл",
        profile_remote: "Remote профайл",
        epon_registration_template: "Шаблон реєстрації для EPON",
        gpon_registration_template: "Шаблон реєстрації для GPON",
        onu_number: "Номер ОНУ",
        input_string_description: `Дозволяє додати поле введення при реєстрації ОНУ, наприклад для встановлення опису. Значення буде доступне по params.<key>`,
        input_string_variable: `Дозволяє добавити поле введення з значенням підставленим з змінних . Це значення можна буде змінити при реєстрації`,
        select_from_variable_description: `
            Дозволяє добавити поле для вибору (select) з змінних.<br>
            Вибрати можна тільки масив з простих типів (строка, число).
         `,
        select_from_variables_list: "Список значення для вибору",
        variable_block: `
               <h4 style="color: darkred">Щоб б керувати параметрами - виберіть пристрій і ОНУ <small>Пристрій повинен мати незареєстроване ОНУ</small></h4>
              
         `,
        parameters_descriptions: `Можна додати параметри, які стануть доступні в шаблоні в полі <b><i>params.&lt;param key&gt</i></b> `,
        parameters_success_saved: "Параметри успішно збережені",
        are_you_sure_to_delete: "Впевнені, що хочете видалити даний параметр?",
        element_success_deleted: "Параметр успішно видалений, збережіть результат",
        variables: "Змінні",
        templates: "Шаблони",
        parameters: "Параметри",
        input_variable_description: "Дозволяє встановлювати значення за замовчуванням для параметрів",
        registration_form: "Форма введення параметрів",
        template_block: "Блок работи з шаблоном",
        save_template: "Зберегти шаблони",
        template_compile_problem: "Помилка компіляції шаблона: ",
        onu_form_registration: "Форма реєстрації ОНУ",
        is_required: "Обов'язковий",

        visible_if: "Умова відображення",
    },
    field_is_required: "Обов'язкове поле",
    all: "Все",
    lang_short: 'uk',
    time_range: "Період часу",
    actions: "Дії",
    action: "Дія",
    loading: "Завантаження...",
    unregistered_onts: "Незареєстровані ОНУ",
    deprecated_unregistered_onts: "Незареєстровані ОНУ (deprecated)",
    data: "Дані",
    time: "Час",
    message: "Повідомлення",
    filters: "Фільтри",
    detected_unregistered_onts: "Знайдено незареєстрировані ОНУ",
    log_statuses: {
        SUCCESS: "Успішно",
        FAILED: "ПОМИЛКА",
    },
    sorry_no_matching_options: "Вариантів не знайдено",
    select_all: "Вибрати все",
    unselect_all: "Видалити все",
    user: "Користувач",
    'module': "Модуль",
    modules: "Модулі",
    arguments: "Аргументи",
    switcher_core_logs: "Список виклику обладнання",
    log_device_calling: "Виклики обладнання",
    log_actions: "Дії",
    log_actions_full: "Список дій",
    system_info: "Інформація про пристрій",
    add_device: "Додати пристрій",
    serial: "Серійний номер",
    updated_at: "Оновлено",
    access: "Доступ",
    interfaces: "Інтерфейси",
    back_to_interfaces: "Повернутися до інтерфейсів",
    reload_info: "Оновити",
    action_reboot_onu: "Перезавантажити ОНУ",
    epon_onu_status: "Статус EPON ОНУ",
    gpon_onu_status: "Статус GPON ОНУ",
    fdb_table: "FDB таблиця",
    logs: "Логи",
    signals: "Рівні сигналів",
    reg_time: "Реєстрація",
    auth_time: "Авторизація",
    dereg_time: "Дереєстрація",
    reason: "Причина",
    technology: "Технологія",
    interface: "Інтерфейс",
    type_configured: "Тип(налаштовано)",
    type_reported: "Тип(репортовано)",
    ver_firmware: "Версія прошивки",
    ether_info: "Інфо по Ethernet-портах ОНУ",
    status_changed: "Кол. змін",
    line_profile: "Line-профайл",
    admin_state: "Адмін. стан",
    distantion: "Відстань",
    online_duration: "В онлайні",
    model: "Модель",
    uptime: "Аптайм",
    found_onts: "Знайдено ОНУ",
    devices_not_found: "Пристрої не найдені",
    last_updated: "Оновлено",
    type_text_for_filter: "Почніть вводити текст для фільтрації...",
    online: "Онлайн",
    offline: "Офлайн",
    power_off: "Виключено",
    search_device_placeholder: "Введить ім'я, IP або модель пристрою...",
    dashboard: "Дашборд",
    devices: "Пристрої",
    device_management: "Керування пристроями",
    accesses: "Доступи",
    users_management: "Користувачі",
    users: "Користувачі",
    groups: "Групи",
    config: "Конфігурація",
    devices_list: "Список пристроїв",
    no_results: "Немає результатів",
    count_devices: "Кількість пристроїв",
    error_in_poller_by_day: "Помилки опитувальника<br><small>за добу</small>",
    errors_switcher_core_by_day: "Помилки роботи з обладнанням<br><small>за добу</small>",
    page_not_found: "СТОРІНКА НЕ ЗНАЙДЕНА!",
    page_not_found_descr: "ВИ МОЖЕТЕ ПОВЕРНУТИСЯ ДОДОМУ І СПРОБУВАТИ ПОШУКАТИ ПОТРІБНУ СТОРІНКУ ЩЕ РАЗ!",
    back_to_home: "Повернутися додому",
    something_wrong: "Виникли проблеми при роботі з сервером, спробуйте перезагрузити сторінку",
    try_reload_page: "Перезагрузити сторінку",
    username: "Ім'я користувача",
    password: "Пароль",
    confirm_password: "Підтвердіть пароль",
    confirm_password_error: "Паролі не співпадають",
    password_instruction: "Залиште поле введення паролю пустим, якщо не хочите встановити новий",
    sign_in: "Зайти",
    login_or_password_incorrect: "Логін або пароль неправильний",
    unknown_error: "Невідома помилка",
    newer: "Ніколи",
    delete_account: "Видалити аккаунт",
    dt_table: {
        count: "Відображено {from} до {to} із {count} записів|{count} записів|Один запис",
        first: 'Перша',
        last: 'Остання',
        filter: "Фільтр:",
        filterPlaceholder: "Пошук",
        limit: "Записів:",
        page: "Сторінка:",
        noResults: "Записів не знайдено",
        filterBy: "Фільтр по {column}",
        loading: 'Загрузка...',
        defaultOption: 'Обрано {column}',
        columns: 'Стовпців'
    },
    personal_info: "Інформація про користувача",
    login: "Логін",
    name: "Ім'я",
    user_login: "Логін користувача",
    user_name: "Ім'я користувача",
    group: "Групи",
    status: "Статус",
    active_sessions: "Активні сесії",
    new_password: "Новий пароль",
    last_activity: "Остання активність",
    save: 'Зберегти',
    create: 'Створити',
    user_list: 'Список користувачів',
    edit_user: 'Змінити користувача %{login}',
    user_successful_updated: "Користувач %{name} успішно оновлений",
    remote_addr: "IP адреса",
    device: "Пристрій",
    close_session: "Закрити сесію",
    session_closed: "Сесія %{id} успішно закрита",
    are_you_sure_for_delete_user: "Ви впевнені що хочите видалити користувача? Це призведе до видалення всієї інформації про користувача і його активності",
    are_you_sure_for_delete_group: "Ви впевнені що хочите видалити групу? Це призведе до видалення всіх користувачів які є в цій групі",
    are_you_sure_for_delete_device: "Ви впевнені, що хочите видалити пристрій?",
    group_successful_updated: "Група %{name} успішно оновлена",
    group_successful_created: "Група %{name} успішно створена",
    group_success_deleted: "Група успішно видалена",
    delete_group: "Видалити групу",
    user_success_deleted: "Користувача успішно видалено",
    user_successful_created: "Користувач %{login} успішно створено",
    create_new_user_btn: "Додати користувача",
    create_new_usergroup_btn: "Додати групу",
    create_new_user: "Додавання нового користувача",
    create_new_group: "Додавання нової групи",
    group_permissions_count: "Кількість дозволів",
    disabled: "Виключено",
    general_info: "Спільна інформація",
    enabled: "Включено",
    hidden: "Прихований",
    group_privileges: "Привілеї групи",
    edit_group: "Зміна групи %{name}",
    display: "відображення",
    description: "Опис",
    enter_name: "Введіть ім'я",
    enter_description: "Введіть опис",
    user_statuses: {
        enabled: 'Ввімкнений',
        disabled: 'Відключений'
    },
    hide_success: "Приховати успішні",
    contain_text: "Містить текст",
    edit: "Змінити",
    delete: "Видалити",

    // Access page
    device_access_management: 'Управління доступами',
    community: 'Комуніті',
    create_new_access_btn: "Створити новий доступ",
    edit_access_with_id: "Зміна доступу #%{id}",
    close: "Закрити",
    access_success_updated: "Доступ успішно оновлений",
    are_you_sure_for_delete_access: "Впевнені, що хочите видалити доступ? Це приведе до видалення обладнання використовуючих даний доступ",
    access_success_deleted: "Доступ успішно видалено",
    access_success_created: "Новий доступ успішно добавлено",
    create_new_access: "Внесення нового доступу",

    // Add device page
    create_new_device: "Внесення нового пристрою",
    mac: "MAC",
    ip: "IP",
    device_success_updated: "Пристрій %{name} успішно оновлено",
    device_success_created: "Пристрій %{name} успішно створено",
    main: "Основне",
    edit_device: "Зміна пристрою %{name}",
    device_id: "ID пристрою",
    created: "Створено",
    updated: "Оновлено",
    device_view: "Перегляд пристрою",
    device_edit: "Редагування пристрою",
    macros: {
        "item_filter": "Фільтр елементів",
        "item_filter_description": "Обмежене вираз на JavaScript, яке фільтрує можливі варіанти за умовою. Умова повинна повертати логічне значення. Наприклад, якщо елемент {id: 0, name: 'defaultProfile'}, і ви хочете його приховати: item.id != 0",
        "model_vendor": "Фільтр виробників моделей",
        "item_name": "Назва елемента",
        "item_name_description": "Обмежений вираз на JavaScript, який задає змінне ім'я для доступу до об'єктів у масиві, наприклад, ${item.name} для доступу до [{item...}, {item_2...}]. 'item' - ключове слово; ім'я може бути настроюваним.",
        "execute_name": "Виконання макросу '{name}'",
        "preview_commands": "Попередній перегляд виконаних команд",
        "executed_output": "Результати виконаних команд",
        "execution": "Виконання",
        "show": "Макроси",
        "modal": "Макроси",
        "output": "Вивід",
        "execution_success": "Успішно виконано",
        "edit_macros": "Редагування макросів - \"%{name}\"",
        "add_new": "Додати новий макрос",
        "params_empty": "Цей макрос не має параметрів",
        "incorrect_value_in_fields": "Деякі помилки у полях",
        "success_updated": "Макрос успішно оновлено",
        "success_created": "Макрос успішно створено",
        "fail_updated": "Помилка оновлення",
        "fail_created": "Помилка створення",
        "confirm_remove": "Ви впевнені, що хочете видалити цей макрос?",
        "close": "Закрити",
        "preview": "Попередній перегляд",
        "remove": "Видалити",
        "cancel": "Скасувати",
        "execute": "Виконати",
        "config": "Макроси",
        "list": "Макроси",
        "add": "Додати новий",
        "import": "Імпорт",
        "export": "Експорт",
        "edit": "Редагувати",
        "interfaces": "Інтерфейси",
        "common": "Загальне",
        "form": "Макроси",
        "create": "Створити",
        "save": "Зберегти",
        "display_for": "Показувати для",
        "display_for_options": {
            "DEVICE": "Пристрої",
            "PON": "PON порту",
            "PORT": "Порту",
            "ONU": "ONU"
        },
        "display_output": "Відображення виводу",
        "display_output_options": {
            "all": "Всі команди",
            "last": "Остання команда",
            "no": "Нічого"
        },
        "name": "Ім'я",
        "roles": "Ролі",
        "user_roles": "Ролі користувача",
        "device": "Пристрій",
        "models": "Моделі",
        "description": "Опис",
        "regular_expr": "Регулярний вираз",
        "input_regular_expression": "Ви можете вказати регулярний вираз (PCRE) для значення у полі вводу. Необов'язково.",
        "duplicate_key": "Дублювання ключа з ім'ям '{key}'",
        "template_success_saved": "Шаблон успішно збережено",
        "twig_easy_doc": `
        <h4>Компіляція шаблонів надана Twig.</h4>
        <span style="color: darkred; font-weight: bold">Читайте <a href="https://twig.symfony.com/doc/3.x/templates.html" target="_blank">офіційну документацію 'Twig для дизайнерів'</a>. <br></span><br>
    `,
        "select_from_predefined_description": "Вкажіть предефіновані значення, по одному на рядок.",
        "key_reg_description": "Повинен відповідати регулярному виразу: ^[a-z][a-z_0-9]{1,}$.",
        "visible_if_description": "Умова JavaScript для видимості властивості у випливаючому вікні виконання макроса. Ви також можете посилатися на інші властивості з цього макроса, наприклад, params.property === 'value'.",
        "select_from_predefined": "Випадаючий список з предефінованих",
        "select_from_variable": "Випадаючий список зі змінних",
        "save_parameters": "Зберегти параметри",
        "input_variable": "Поле вводу з джерелом значення",
        "input_string": "Текстове поле",
        "checkbox": "Прапорець",
        "live_result": "Живий результат",
        "add_parameter": "Додати параметр",
        "template": "Шаблон",
        "source_param_key": "Джерело значення",
        "default_value": "Значення за замовчуванням",
        "variants": "Варіанти",
        "parameter_can_be_empty": "Необов'язковий",
        "key": "Ключ",
        "visible_name": "Ім'я для відображення",
        "parameter_type": "Тип параметра",
        "template_variables": "Конфігурація змінних",
        "predefined_variables_detailed": "Виберіть пристрій для завантаження змінних",
        "profile_line": "Профіль лінії",
        "profile_remote": "Віддалений профіль",
        "onu_number": "Номер ONU",
        "input_string_description": ``,
        "input_string_variable": ``,
        "select_from_variable_description": ``,
        "select_from_variables_list": "Список передвстановлених значень",
        "variable_block": `<h4 style="color: darkred">Виберіть пристрій та інтерфейс, до яких будуть застосовані параметри</h4>`,
        "parameters_descriptions": `Додайте параметри нижче. До них можна звертатися за допомогою точкової нотації, наприклад: {{ params.&lt;property&gt; }}`,
        "parameters_success_saved": "Параметри успішно збережено",
        "are_you_sure_to_delete": "Ви впевнені, що хочете видалити цей параметр?",
        "element_success_deleted": "Параметр успішно видалено",
        "variables": "Змінні",
        "templates": "Шаблони",
        "parameters": "Параметри",
        "input_variable_description": "Ви можете встановити значення за замовчуванням для цієї властивості.",
        "parameters_form": "Форма параметрів",
        "template_block": "Блок шаблону",
        "save_template": "Зберегти шаблон",
        "template_compile_problem": "Помилка компіляції шаблону: ",
        "is_required": "Обов'язково",
        "visible_if": "Умова видимості"
    },

    password_too_short: "Пароль має бути не менше 8 символів",
    password_no_uppercase: "Пароль повинен містити хоча б 1 велику літеру",
    password_no_lowercase: "Пароль повинен містити хоча б 1 маленьку букву",
    password_no_digit: "Пароль повинен містити хоча б 1 цифру",
    password_no_special: "Пароль повинен містити хоча б 1 спеціальний символ",
}
