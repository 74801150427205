export default {
    permissions: {
        group_descriptions: {
            web_portal: "System information, Global search rules required for correctly working web portal",
            prom_wrapper: "Rules for display charts, as optical history, traffic on ONU or interface",
        },
        groups: {
            utels_integration: "UTELS integration",
            macros: "Component: Macros",
            sensor_devices: "Sensor devices",
            billing_integration: "Billing integrations",
            oxidized: "Config backups (oxidized)",
            analytics: "Analytics",
            live_traffic: "Live traffic",
            web_portal: "Web portal",
            links: "Component: Links",
            pon_boxes: "Component: PON-boxes",
            olts: "OLTs",
            switches: "Switches",
            notifications: "Component: Notifications",
            events: "Component: Events",
            router_os: "Component: RouterOS",
            prom_wrapper: "Component: Prometheus",
            diag: "Component: Diagnostic",
            sd: "Component: Device searching",
            fdb_history: "Component: FDB history",
            system: "System",
            sys_info: "System information",
            user_management: "User management",
            device_management: "Device management",
            switcher_core_dev_control: "SwitcherCore control",
            switcher_core: "SwitcherCore",
            onu_registration: "Component: ONT registration on ZTE",
            external_apps: "External apps",
            router: "Router",
        },
        keys: {
            router_info: "Router info",
            utels_allow_exclude_from_stat: "Allow exclude from stat",
            utels_allow_set_comment: "Allow set comment",
            notifications_view_history: "Allow see notifications history",
            unregistered_onts_result_output: "ONT registration: Allow view console output on error ",
            macros_execute: "List and execute macros",
            macros_edit: "Configure macros",
            analytics: "Show analytics",
            olts_ctrl_uni_ports: "Control UNI ports on ONU",
            sensor_devices_view: "View data from sensor",
            sensor_devices_allow_switch_modes: "Allow switch controls on view",
            sensor_devices_configure: "Allow configure sensors",
            olts_ctrl_port: "Allow control PON/physical ports",
            device_iface_manage_marks: "Manage favorite/tags on interface",
            user_management_config_strict_ip: "Configure strict access IP/networks",
            billing_integration_show_info: "Allow diagnostic for billing",
            system_cross_auth_user: "Allow authorize another users (generate keys)",
            billing_integration: "Allow billing systems run diagnostic",
            external_apps_oxidized: "Allow read configs",
            poller_info_by_device: "Poller info on device dashboard",
            device_iface_management: "Device interface management",
            run_poller_by_device: "Run poller on device dashboard",
            analytics_ont_list: "ONT list (detailed current info)",
            analytics_ont_status_history: "ONT status history",
            analytics_iface_status_history: "Ifaces status history",
            analytics_device_online_history: "Device status history",
            switches_save_config: "Allow save config",
            switches_vlan_port_control: "VLAN control",
            system_status: "System status",
            live_traffic_info: "See live traffic info",
            dashboard_global_edit: "Dashboard global edit",
            dashboard_edit: "Dashboard self edit",
            olts_onu_disable: 'Allow disable ONT',
            olts_onu_description: 'Allow change ONT description',
            olts_ctrl_dereg: 'Allow dereg ONT',
            olts_ctrl_reboot: 'Allow reboot ONT',
            olts_ctrl_clear_counters: 'Allow clear ONT counters',
            olts_ctrl_reset: 'Allow reset ONT',
            olts_ctrl_disable: 'Allow disable/enable ONT',
            olts_ctrl_description: 'Allow change ONT description',
            links_view: "Allow view links",
            links_edit: "Allow edit links",
            external_apps_phpmyadmin: "phpMyAdmin",
            pon_boxes_view: "Viewing boxes/maps",
            pon_boxes_map_editing: "Editing boxes/maps",
            events_see_all: "Allow to see all events",
            notifications_send_global_notify: "Send global notifications (without device)",
            log_poller: "Poller logs",
            unregistered_onts: "Register ONTs",
            unregistered_onts_config: "Configure ONT registration",
            events_configuration: "Configure events",
            olts_info: "Info from OLTs",
            olts_onu_reboot: "Allow reboot ONT",
            olts_onu_dereg: "Allow dereg ONT",
            olts_onu_reset: "Allow reset ONT",
            olts_onu_clear_counters: "Allow clear counters",
            switches_info: "Info from switches",
            switches_reboot_device: "Allow reboot device",
            switches_clear_counters: "Allow clear counters",
            switches_set_port_description: "Allow set description",
            switches_set_port_admin_state: "Allow set admin state",
            switches_set_port_admin_speed: "Allow set admin speed",
            notifications_full_access: "Full access",
            notifications_configure_contacts: "Allow configure all contacts",
            notifications_configure_self_contacts: "Allow configure only self contacts",
            events_show: "Allow display events",
            events_resolve: "Allow resolve(close) events",
            external_apps_grafana: "Grafana",
            external_apps_prometheus: "Prometheus",
            external_apps_alertmanager: "Alertmanager",
            router_os_info: "Get router OS info",
            prom_chart_info: "Charts",
            diag_arp_ping: "ARP ping",
            diag_icmp_ping: "ICMP ping",
            diag_traceroute: "Traceroute",
            diag_interface: "Interface diagnostic for billings",
            sd_search_ip_with_port: "Search ARP with FDB(port searching)",
            device_groups_management: "Device groups management",
            sd_search_mac: "Allow MAC-address searching",
            sd_search_ip: "Allow IP-address searching",
            fdb_history_by_interface: "Show history by interface",
            fdb_history_search: "Allow search by history",
            schedule_reports: "See schedule reports",
            schedule_configuration: "Schedule configuration",
            system_configuration: "Read/edit system settings",
            global_search: "Global search",
            system_info: "System information",
            user_management: "All Users management",
            user_self_control: "Update self information",
            user_display: "Display users list",
            device_access_management: "Device access control",
            device_show: "Show device info",
            device_management: "Device management",
            full_sw_core_access: "Fill SwitcherCore access",
            swc_reboot_onu: "ONT reboot",
            system_logs_actions: "Show actions log",
            users_list: "Users list",
            log_switcher_core_actions: "Show device logs",
            zte_unregistered_onts: "Allow ONT registration",
            zte_unregistered_onts_config: "Configure ONT registration (variables/template)",
            unregistered_onts_preview: "Preview config in ONT registration form",
        }
    },
}