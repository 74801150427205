<template>
  <div>
    <div class="error" v-if="error !== '' && !loading" style="margin-top: 100px; margin-bottom: 100px">
      <SomethingWrong :error-message="error"></SomethingWrong>
    </div>
    <Preloader v-if="loading"  :with-text="true" :loading-text="$t('please_wait_working_with_device')"></Preloader>
    <div v-if="notFound">
       <PageNotFound></PageNotFound>
    </div>
    <div v-else>
      <keep-alive>
        <router-view :device-store="deviceStore" v-if="!loading && error === ''" ref="rot"/>
      </keep-alive>
    </div>
    <BigNotify :color="'rgba(159, 0, 0, 0.8)'" ref="noty" :timeout="30000" :hide-on-click="true"></BigNotify>
  </div>
</template>


<script>

import Preloader from "../../../components/Preloader.vue";
import SomethingWrong from "../../../components/SomethingWrong.vue";
import currentDeviceStore from './currentDeviceStore'
import BigNotify from "@/components/BigNotify.vue";
import PageNotFound from "@/views/PageNotFound.vue";

export default {
  components: {PageNotFound, SomethingWrong, Preloader,BigNotify},
  async mounted() {
    this.$setRouteMeta(this.$t('loading'))
    await this.loadDeviceInfo()
  },
  data() {
    return {
      loading: true,
      deviceStore: null,
      error: '',
      notFound: false,
    }
  },
  watch: {
    '$route': {
      async handler(n, l) {
        if (n.name !== 'device_iface_dashboard' && n.name !== 'device_dashboard') {
          this.deviceStore = null
          this.loading = true
          return
        }
        if (n.params.id !== this.deviceStore.device.id) {
          console.log("Device is changed in parameters")
          this.mustReloadByRoute()
        } else if (n.name === 'device_dashboard' && n.query?.from && n.query.from === 'device') {
          console.log("device_dashboard and reload ?from=device")
          this.mustReloadByRoute()
        } 
      },
      deep: true,
    },
    'deviceStore.deviceIsOffline': {
      handler(n) {
        if(n) {
          this.$refs.noty.displayNoty(`<span style="color: white">${this.$t('device_is_offline_big_message')}</span>`)
        }
      },
      deep: true,
    }
  },
  methods: {
    async mustReloadByRoute() {
      console.log("Device changed, reloading...")
      this.loading = true
      this.deviceStore = null
      await this.loadDeviceInfo()
    },
    async loadDeviceInfo() {
      this.loading = true
      let deviceId = this.$route.params.id
      this.deviceStore = await currentDeviceStore().setDeviceByID(deviceId)
      if(!this.deviceStore.isDeviceSuccessFound) {
          this.notFound = true
      }
      this.loading = false
    },
  },
  deactivated() {
    this.deviceStore = null
    this.loading = false
    this.error = ''
  }
}
</script>
