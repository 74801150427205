<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-mini col-sm-12">
                <FullscreenPreloader v-show="preloading"></FullscreenPreloader>
                <Card :name="$t('filters')" :show="true" :disable-hide="true">
                    <form class="row" @submit.prevent="search">
                        <div class="col-lg-5 col-xl-6 col-sm-12 col-md-12">
                            <div class="form-group">
                                <label  class="mb-0">{{ $t('devices') }}</label>
                                <v-select class="style-chooser"
                                          v-model="filter.devices"
                                          label="displayName"
                                          :options="deviceList.filter(e => {return e.model.type === 'OLT' && filter.devices.filter(s => e.id === s.id).length === 0})"
                                          :multiple="true"
                                          :close-on-select="false"
                                />
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-sm-4 col-md-4">
                            <div class="form-group">
                                <label class="mb-0">{{ $t('field_name') }}</label>
                                <v-select class="style-chooser"
                                          v-model="filter.field"
                                          label="value"
                                          :reduce="k => k.key"
                                          :options="fieldsList"
                                          :close-on-select="true"
                                          :placeholder="$t('filter_field_name')"
                                />
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-1 col-sm-2 col-md-2">
                            <div class="form-group">
                                <label class="mb-0">&nbsp;</label>
                                <v-select class="style-chooser"
                                          v-model="filter.expression"
                                          :options="expressionList"
                                          :close-on-select="true"
                                          :clearable="false"
                                />
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-sm-4 col-md-4">
                            <div class="form-group">
                                <label class="mb-0">{{ $t('value') }}</label>
                                <input name="value" v-model="filter.value" class="form-control"
                                       :placeholder="$t('value')">
                            </div>
                        </div>
                        <div class="col-lg-1 col-xl-1 col-sm-2 col-md-2">
                            <div class="form-group">
                                <label class="mb-0"> &nbsp;</label>
                                <button class="btn btn-default btn-block btn-sm" style="padding: 2.5px" >
                                    <i
                                            class="mdi mdi-table-search mdi-18px"></i></button>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-sm-3 col-md-3 col-6">
                          <div class="form-group">
                            <label class="mb-0">{{$t('only_bad_signals')}}</label><br>
                            <label class="switch mb-0">
                              <input type="checkbox" v-model="filter.bad_signals">
                              <span class="slider "></span>
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-sm-3 col-md-3 col-6">
                          <div class="form-group">
                            <label class="mb-0">{{$t('status')}}</label>
                            <v-select class="style-chooser"
                                      v-model="filter.ont_status"
                                      :options="ontStatuses"
                                      :close-on-select="true"
                                      :clearable="false"
                            />
                          </div>
                        </div>
                    </form>
                </Card>
               <Preloader v-if="loading"/>
                <SomethingWrong  v-if="error !== '' && !loading" :error-message="error" :show-reload-button="false"></SomethingWrong>
                <v-server-table :columns="table.columns" :options="table.options" ref="table" v-if="isMounted"
                                v-show="error === '' && !loading"
                                @loading="() => {this.preloading = true}" @loaded="() => {this.error = ''; this.preloading = false}"
                                @error="errorLoadTable"
                                class="table-sm">
                  <template v-slot:afterFilterWrapper>
                    <WcaExportExcel name="ONT list"  v-if=" !preloading && !error"  :fetch="exportXls.fetch" :fields="exportXls.fields" style="float: left; margin-top: 3px; min-width: 150px"></WcaExportExcel>
                  </template>
                  <template v-slot:device="{row}">
                    <router-link :to="{name: 'device_dashboard', params: {id: row.interface.device.id}}">
                      {{ row.interface.device.ip }}
                      <br>
                      <small>{{ row.interface.device.name }}</small>
                    </router-link>
                  </template>
                  <template v-slot:interface="{row}">
                    <router-link
                        :to="{name: 'device_iface_dashboard', params: {id: row.interface.device.id, 'interface': row.interface.bind_key}}">
                      {{ row.interface.name }}
                    </router-link>
                      <router-link target='_blank'
                                   :to="{name: 'device_iface_dashboard', params: {id: row.interface.device.id, 'interface': row.interface.bind_key}}">

                        <i class="mdi mdi-open-in-new"></i>
                      </router-link>
                  </template>
                  <template v-slot:ident="{row}">
                    <b>{{ row.ident }}</b> <small>({{ row.type }})</small>
                  </template>
                  <template v-slot:status="{row}">
                    <div :style="'padding: 5px; color: white; text-align: center; border-radius: 3px; font-weight: bold; ' + ( row.interface.status !== 'Online' ? 'background: darkred; border: 1px solid red;' : 'background: darkgreen; border: 1px solid green;')">
                        <span>{{ row.interface.status }}</span>
                    </div>
                  </template>
                  <template v-slot:last_change="{row}">
                    <div>
                      {{row.interface.status_changed}}
                    </div>
                  </template>
                  <template v-slot:created_at="{row}">
                    <div>
                      {{row.interface.created}}
                    </div>
                  </template>
                  <template v-slot:description="{row}">
                    {{ row.interface.description }}
                  </template>
                  <template v-slot:agreement="{row}">
                    {{ row.interface.agreement }}
                  </template>
                  <template v-slot:optical_rx="{row}">
                    <div :style="row.optical.bad_rx ? 'padding-left: 5px; padding-top: 2px; padding-right: 5px; color: white; background: darkred; border: 1px solid red; border-radius: 3px; font-weight: bold' : ''">
                      <span v-if="row.optical.rx !== null">{{ row.optical.rx.toFixed(2) }}</span>
                      <span v-if="row.optical.rx === null"
                            style="font-size: 95%; color: grey">{{ $t('n_a') }} </span><br>
                      <small>{{row.optical.bad_rx}}</small>
                    </div>
                  </template>
                  <template v-slot:optical_olt_rx="{row}">
                    <div  :style="row.optical.bad_olt_rx ? 'padding-left: 5px; padding-top: 2px;  padding-right: 5px; color: white; background: darkred; border: 1px solid red; border-radius: 3px; font-weight: bold' : ''">
                      <span v-if="row.optical.olt_rx !== null">{{ row.optical.olt_rx.toFixed(2) }}</span>
                      <span v-if="row.optical.olt_rx === null"
                            style="font-size: 95%; color: grey">{{ $t('n_a') }} </span><br>
                      <small>{{row.optical.bad_olt_rx}}</small>
                    </div>
                  </template>
                  <template v-slot:optical_temperature="{row}">
                    <div>
                      <span v-if="row.optical.temperature !== null">{{ row.optical.temperature.toFixed(0) }}</span>
                      <span v-if="row.optical.temperature === null"
                            style="font-size: 95%; color: grey">{{ $t('n_a') }}  </span>
                    </div>
                  </template>
                  <template v-slot:optical_distance="{row}">
                    <div>
                      <span v-if="row.optical.distance !== null">{{ row.optical.distance.toFixed(0) }}</span>
                      <span v-if="row.optical.distance === null"
                            style="font-size: 95%; color: grey">{{ $t('n_a') }}  </span>
                    </div>
                  </template>
                  <template v-slot:model="{row}">
                    <div>
                      <div v-if="row.vendor === null || !row.vendor.model" style="font-size: 95%; color: gray">{{$t('n_a')}}</div>
                      <div v-else>
                        {{row.vendor.model}}
                      </div>
                    </div>
                  </template>

                </v-server-table>
            </div>
        </div>

    </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";
import SomethingWrong from "@/components/SomethingWrong.vue";
import WcaExportExcel from "@/components/WcaExportExcel.vue";

var filter = {}

export default {
    components: {
      WcaExportExcel,
        SomethingWrong,
        FullscreenPreloader,
        Card,
        Preloader,
    },
    data() {
        return {
            exportXls: {
              fields: {
                'Created At' : 'created_at',
                'Device IP' : 'interface.device.ip',
                'Device Name': 'interface.device.name',
                'Device Model': 'interface.device.model.name',
                'Name': 'interface.name',
                'Description': 'interface.description',
                'Agreement': 'interface.agreement',
                'Ident': 'ident',
                'Status': 'interface.status',
                'Last status changed': 'interface.status_changed',
                'Optical RX': 'optical.rx',
                'Optical OLT RX': 'optical.olt_rx',
                'Optical Distance': 'optical.distance',
                'ONT Model': 'vendor.model',
                'ONT HW': 'vendor.ver_hardware',
                'ONT FW': 'vendor.ver_software',
                'Bind key': 'interface.bind_key',
              },
              async fetch() {
                  let data = []
                  await this.$api.put('/component/analytics/table/ont-list', {'filter': filter, limit: 50000}, true).catch((e) => {
                    this.$noty.warning(this.$t('error_export_xls'))
                    console.log(e)
                  }).then(resp => {
                    resp.data.forEach(e => {
                      data.push(e)
                    })
                  });
                  return data
                }
            },
            data: [],
            error: '',
            isMounted: false,
            export_excel: {
              fetch: null,
              fields: [],
              loading: false,
            },
            filter: {
                devices: [],
                field: 'any',
                expression: '~',
                value: null,
                ont_status: 'All',
                bad_signals: false,
            },
            preloading: true,
            loading: false,
            actionQuery: '',
            fieldsList: [
                {key: 'any', value: this.$t('any')},
                {key: 'ident', value: this.$t('ident')},
                {key: 'type', value: this.$t('type')},
                {key: 'interface.name', value: this.$t('interface')},
                {key: 'optical.olt_rx', value: this.$t('olt_rx')},
                {key: 'optical.rx', value: this.$t('rx')},
                {key: 'optical.distance', value: this.$t('distance')},
                {key: 'interface.status', value: this.$t('status')},
                {key: 'interface.description', value: this.$t('description')},
                {key: 'interface.agreement', value: this.$t('agreement')},
                {key: 'vendor', value: this.$t('vendor')},
                {key: 'model', value: this.$t('model')},
            ],
            expressionList: [
              '~',
                '=',
                '!=',
                '>',
                '<',
                '>=',
                '<=',
            ],
            ontStatuses: [
              'All',
              'LOS',
              'PowerOff',
              'Offline',
              'Online',
              'Offline|PowerOff',
            ],

            deviceList: [],
            table: {
                columns: ['status','device', 'interface', 'ident', 'description', 'agreement',  'last_change','created_at',  'optical_rx', 'optical_olt_rx', 'optical_temperature', 'optical_distance', 'model'],
                options: {
                    requestFunction(data) {
                        data.filter = filter
                        switch (data.orderBy) {
                            case 'device':
                                data.orderBy = 'interface.device.ip';
                                break;
                            case 'interface':
                                data.orderBy = 'interface.name';
                                break;
                            case 'optical_rx':
                                data.orderBy = 'optical.rx';
                                break;
                            case 'optical_tx':
                                data.orderBy = 'optical.tx';
                                break;
                            case 'optical_temperature':
                                data.orderBy = 'optical.temperature';
                                break;
                            case 'optical_distance':
                                data.orderBy = 'optical.distance';
                                break;
                            case 'last_change':
                                data.orderBy = 'interface.status_changed';
                                break;
                            case 'created_at':
                                data.orderBy = 'interface.created';
                                break;
                            case 'status':
                                data.orderBy = 'interface.status';
                                break;
                            case 'description':
                                data.orderBy = 'interface.description';
                                break;
                        }
                        return this.$api.put('/component/analytics/table/ont-list', data, true).catch((e) => {
                            this.dispatch('error', e);
                        }).then(resp => {
                            resp.count = resp.meta.total_records
                            return resp;
                        });
                    },
                    skin: 'VueTables__table table table-striped table-bordered table-hover styled-table table-sm',
                  perPage: 50,
                  perPageValues: [50, 100, 300, 500],
                    headings: {
                        'created_at': this.$t('created_at'),
                        'device': this.$t('device'),
                        'interface': this.$t('interface'),
                        'ident': this.$t('ident'),
                        'description': this.$t('description'),
                        'agreement': this.$t('agreement'),
                        'status': this.$t('status'),
                        'last_change': this.$t('last_change'),
                        'optical_rx': this.$t('optical_rx'),
                        'optical_olt_rx': this.$t('optical_olt_rx'),
                        'optical_temperature': this.$t('optical_temperature'),
                        'optical_distance': this.$t('optical_distance'),
                        'model': this.$t('model'),
                    },
                    sortable: ['created_at', 'device', 'description', 'interface', 'ident', 'status', 'last_change', 'optical_rx', 'optical_olt_rx', 'optical_temperature', 'optical_distance'],
                    filterable: false,
                    texts: {
                        loadingError: 'Oops! Something went wrong',
                        count: this.$t('dt_table.count'),
                        first: this.$t('dt_table.first'),
                        last: this.$t('dt_table.last'),
                        filter: this.$t('dt_table.filter'),
                        filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
                        limit: this.$t('dt_table.limit'),
                        page: this.$t('dt_table.page'),
                        noResults: this.$t('dt_table.noResults'),
                        filterBy: this.$t('dt_table.filterBy'),
                        loading: this.$t('dt_table.loading'),
                        defaultOption: this.$t('dt_table.defaultOption'),
                        columns: this.$t('dt_table.columns'),
                    },
                },
            },
        }
    },
    async mounted() {
        filter = this.filter
        this.$setRouteMeta(this.$t('ont_list'))
        this.$api.enableSupportWaiting()
        this.loadDevicesList()
        if (typeof this.$route.query.device_id !== 'undefined') {
            console.log("DeviceID setted")
            this.filter.devices.push(await this.getDevice(this.$route.query.device_id))
        }
        if (typeof this.$route.query.bad_signals !== 'undefined' && this.$route.query.bad_signals === 'yes') {
            console.log("Bad signals setted")
            this.filter.bad_signals = true
        }
        if (typeof this.$route.query.ont_status !== 'undefined' && this.$route.query.ont_status !== '') {
            console.log("Ont status setted")
            this.filter.ont_status = this.$route.query.ont_status
        }
        this.isMounted = true
        await this.$api.waitResponses()

        this.loading = false
    },
    methods: {
        errorLoadTable(e) {
            this.preloading = false
            this.error = e.response.data.error.description
            console.log(e)
        },
        search() {
            this.$refs.table.refresh()
        },
        async loadUsersList() {
            await this.$api.get('/user-list').then(r => {
                this.usersList = r.data
            }).catch(() => {
            })

        },
        async loadDevicesList() {
            await this.$api.get('/device/options?type=OLT').then(r => {
                this.deviceList = []
                r.data.forEach(elem => {
                    if (elem.name.trim() === '') {
                        elem.name = this.$t('no_name')
                    }
                    elem.displayName = `${elem.ip} - ${elem.name}`
                    this.deviceList.push(elem)
                })
            }).catch(() => {
            })
        },
        async getDevice(deviceId) {
            let data = {}
            await this.$api.get('/device/' + deviceId).then(r => {
                r.data.displayName = `${r.data.ip} - ${r.data.name}`
                data = r.data
            }).catch(() => {
            })
            return data
        }
    }
}
</script>
<style>

.VueTables__limit-field label {
    display: inline;
    margin: 5px;
}

.json-tree {
    padding-left: 10px !important;
}

.json-tree-key {
    font-size: 14px;
}
</style>