<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <FullscreenPreloader v-show="preloading"></FullscreenPreloader>
        <Card :name="$t('filters')" :show="true" :disable-hide="true">
          <form @submit.prevent>
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12">
                <div class="form-group">
                  <label class="mb-0">{{ $t('devices') }}</label>
                  <v-select class="style-chooser"
                            v-model="filter.devices"
                            label="displayName"
                            :options="deviceList.filter(e => {return filter.devices.filter(s => e.id === s.id).length === 0})"
                            :multiple="true"
                            :close-on-select="false"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-xl-2 col-sm-4 col-md-4">
                <div class="form-group">
                  <label class="mb-0">{{ $t('search') }}</label>
                  <input name="value" v-model="filter.value" class="form-control"
                         :placeholder="$t('value')">
                </div>
              </div>
              <div class="col-lg-1 col-xl-1 col-sm-2 col-md-2">
                <div class="form-group">
                  <label class="mb-0"> &nbsp;</label>
                  <button class="btn btn-default btn-block btn-sm" style="padding: 2.5px" @click="search">
                    <i
                        class="mdi mdi-table-search mdi-18px"></i></button>
                </div>
              </div>
              <div class="col-lg-1 col-xl-1 col-sm-2 col-md-2 col-6">
                <div class="form-group">
                  <WcaExportExcel style="margin-top: 20px" name="TaggedInterface"  v-if=" !preloading && !error"  :fetch="exportXls.fetch" :fields="exportXls.fields"></WcaExportExcel>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label class="mb-0">{{ $t('modules') }}</label>
              </div>
              <div class="col-sm-6">
                <button style="margin: 3px" class="btn btn-sm btn-success"
                        @click="tagsList.forEach(e => {e.selected = true})"><i class="fa fa-plus"></i>
                  {{ $t('select_all') }}
                </button>
                <button style="margin: 3px" class="btn btn-sm btn-secondary"
                        @click="tagsList.forEach(e => {e.selected = false})"><i class="fa fa-minus"></i>
                  {{ $t('unselect_all') }}
                </button>
              </div>
              <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12">
                <div style="display: inline">
                  <a v-for="action in tagsList.filter(e => {return e.name.indexOf(actionQuery) !== -1})"
                     :key="action.name"
                     :class="'badge badge-pill a-to-badge' + (action.selected ? ' badge-success':' badge-secondary')"
                     style="font-size: 15px; margin: 3px"
                     @click="action.selected = !action.selected"
                     href="javascript:void(0)"
                  >
                    <i class="fa fa-plus" v-if="!action.selected"></i>
                    <i class="fa fa-minus" v-if="action.selected"></i>
                    {{ action.name }}
                  </a>
                </div>
              </div>

            </div>
          </form>
        </Card>
        <Preloader v-if="loading"/>
        <SomethingWrong v-if="error !== '' && !loading" :error-message="error"
                        :show-reload-button="false"></SomethingWrong>
        <v-server-table :columns="table.columns" :options="table.options" ref="table"
                        v-show="error === '' && !loading"
                        v-if="isMounted"
                        @loading="() => {this.preloading = true}"
                        @loaded="() => {this.error = ''; this.preloading = false}"
                        @error="errorLoadTable"
                        class="table-sm">
          <template v-slot:device="{row}">
            <router-link :to="{name: 'device_dashboard', params: {id: row.device.id}}">
              {{ row.device.ip }}
              <br>
              <small>{{ row.device.name }}</small>
            </router-link>
          </template>
          <template v-slot:interface="{row}">
            <router-link
                :to="{name: 'device_iface_dashboard', params: {id: row.device.id, 'interface': row.bind_key}}">
              {{ row.name }}
            </router-link>
          </template>
          <template v-slot:ident="{row}">
            <span v-if="row.ident"><b>{{ row.ident.ident }}</b> <small>({{ row.ident.type }})</small></span>
          </template>
          <template v-slot:status="{row}">
            <div>
                        <span v-if="row.status === 'Up' || row.status === 'Online'"
                              style="font-weight: bold; color: darkgreen">{{ row.status }}</span>
              <span v-else
                    style="font-weight: bold; color: darkred">{{ row.status }}</span>
            </div>
          </template>
          <template v-slot:description="{row}">
            {{ row.description }}
          </template>
          <template v-slot:tags="{row}">
            <div style="display: inline">
            <span v-for="tag in row.tags"
                  :key="tag"
                  :class="'badge badge-pill a-to-badge badge-success'"
                  style="font-size: 15px; margin: 3px"
            >
              {{ tag }}
            </span>
            </div>
          </template>

        </v-server-table>
      </div>
    </div>

  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";
import SomethingWrong from "@/components/SomethingWrong.vue";
import WcaExportExcel from "@/components/WcaExportExcel.vue";

var filter = {}

export default {
  components: {
    WcaExportExcel,
    SomethingWrong,
    FullscreenPreloader,
    Card,
    Preloader,
  },
  watch: {
    tagsList: {
      handler(n) {
        let tags = []
        n.forEach(tag => {
          if(tag.selected) {
            tags.push(tag.name)
          }
        })
        this.filter.tags = tags
      },
      deep: true,
    }
  },
  data() {
    return {
      isMounted: false,
      exportXls: {
        fields: {
          'Created At' : 'ident.created_at',
          'Device IP' : 'device.ip',
          'Device Name': 'device.name',
          'Device Model': 'device.model.name',
          'Name': 'name',
          'Description': 'description',
          'Ident': 'ident.ident',
          'Status': 'status',
          'Last status changed': 'status_changed',
          'Tags': 'tags'
        },
        async fetch() {
          let data = []
          await this.$api.put('/interface-marks/tagged/list', {'filter': filter, limit: 50000}, true).catch((e) => {
            this.$noty.warning(this.$t('error_export_xls'))
            console.log(e)
          }).then(resp => {
            resp.data.forEach(e => {
              data.push(e)
            })
          });
          return data
        }
      },
      data: [],
      error: '',
      filter: {
        devices: [],
        value: '',
        tags: [],
      },
      preloading: true,
      loading: true,
      actionQuery: '',
      deviceList: [],
      tagsList: [],
      table: {
        columns: ['created_at', 'device', 'interface', 'ident', 'description', 'status', 'tags'],
        options: {
          requestFunction(data) {
            data.filter = filter
            switch (data.orderBy) {
              case 'device':
                data.orderBy = 'interface.device.ip';
                break;
              case 'interface':
                data.orderBy = 'interface.name';
                break;
            }
            return this.$api.put('/interface-marks/tagged/list', data, true).catch((e) => {
              this.dispatch('error', e);
            }).then(resp => {
              resp.count = resp.meta.total_records
              return resp;
            });
          },
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          perPage: 100,
          perPageValues: [100, 300, 500],
          headings: {
            'created_at': this.$t('created_at'),
            'device': this.$t('device'),
            'interface': this.$t('interface'),
            'ident': this.$t('ident'),
            'description': this.$t('description'),
            'status': this.$t('status'),
            'tags': this.$t('tags'),
          },
          sortable: ['created_at', 'device', 'description', 'interface', 'ident', 'status',],
          filterable: false,
          texts: {
            loadingError: 'Oops! Something went wrong',
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  async mounted() {
    filter = this.filter
    this.$setRouteMeta(this.$t('tagged_interfaces'))
    this.$api.enableSupportWaiting()
    this.loadDevicesList()
    if (typeof this.$route.query.device_id !== 'undefined') {
      console.log("DeviceID setted")
      this.filter.devices.push(await this.getDevice(this.$route.query.device_id))
    }
    this.loadTags()

    await this.$api.waitResponses()

    if(this.$route.query.tag) {
      this.filter.tags = []
      this.tagsList.forEach(e => {e.selected = this.$route.query.tag === e.name})
      this.filter.tags.push(this.$route.query.tag)
    }
    this.isMounted = true
    this.loading = false
  },
  methods: {
    errorLoadTable(e) {
      this.preloading = false
      this.error = e.response.data.error.description
      console.log(e)
    },
    search() {
      this.$refs.table.refresh()
    },
    async loadDevicesList() {
      await this.$api.get('/device/options').then(r => {
        this.deviceList = []
        r.data.forEach(elem => {
          if (elem.name.trim() === '') {
            elem.name = this.$t('no_name')
          }
          elem.displayName = `${elem.ip} - ${elem.name}`
          this.deviceList.push(elem)
        })
      }).catch(() => {
      })
    },
    async loadTags() {
      this.tagsList = []
      await this.$api.get('/interface-marks/existed-tags').then(r => {
        r.data.forEach(tag => {
          this.tagsList.push({name: tag, selected: true})
        })
      })
    },
    async getDevice(deviceId) {
      let data = {}
      await this.$api.get('/device/' + deviceId).then(r => {
        r.data.displayName = `${r.data.ip} - ${r.data.name}`
        data = r.data
      }).catch(() => {
      })
      return data
    }
  }
}
</script>
<style>

.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}

.json-tree {
  padding-left: 10px !important;
}

.json-tree-key {
  font-size: 14px;
}
</style>