<template>
  <div>
    <fullscreen-preloader :show="loading"></fullscreen-preloader>

    <ModalForm :title="$t('choose_coordinate')" :hide-footer="true" :modal-width="900" ref="searchMapCoordinates">
      <template v-slot:content>
        <CoordinatesMapSearch style="z-index: 99" @updated="updateCoordinates"
                              :coordinates="coordinates"></CoordinatesMapSearch>
      </template>
      <template v-slot:footer>
        <div></div>
      </template>
    </ModalForm>
    <ModalForm :title="$t('edit_link')" ref="editLink" @save="saveLink">
      <template v-slot:content>
        <div class="container-fluid" v-if="temp.link !== null">
          <div style="margin-top: 10px; margin-bottom: 10px">
            <div v-if="temp.link.link.id !== null">{{ $t('editing_link_with_id', {id: temp.link.link.id}) }}</div>
            <div v-else>{{ $t('creating_new_link') }}</div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
            <div class="col-sm-9">
              <input v-model="temp.link.link.name" class="form-control">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('type') }}</label>
            <div class="col-sm-9">
              <v-select class="style-chooser"
                        v-model="temp.link.link.type" label="name" :options="options.lineTypes"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('neighbor') }}</label>
            <div class="col-sm-9">
              <v-select class="style-chooser"
                        v-model="temp.link.neighbor" label="name" :options="neighbors"></v-select>
            </div>
          </div>
        </div>
      </template>

    </ModalForm>
    <div class="container-fluid" v-if="!loading">
      <div class="row" :style="`margin-bottom: 5px;` + (box_id ? 'margin-top: -10px;' : 'margin-top: 3px;') ">
        <div class="col-12 flex-btn-menu" style="margin-bottom: 5px;">
          <button class="btn-default btn btn-menu" v-if="!box_id " @click="$router.push({name: 'pon_boxes_boxes', query: {refresh: 'yes'}})"><i class="mdi mdi-backburger"></i> {{ $t('to_boxes') }}</button>
          <button class="btn-default btn btn-menu" @click="saveOnServer" v-if="isEditingAllowed && !box_id "><i class="mdi mdi-content-save"></i>
            {{ $t('save') }}
          </button>
          <button class="btn-danger btn btn-menu" @click="deleteBox" v-if="!isNewBox && isEditingAllowed && !box_id" ><i class="mdi mdi-trash-can"></i>
            {{ $t('delete') }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class=" col-12 col-lg-6 col-xl-6">
          <Card :name="$t('main')" :show="true">
            <div class="row" v-if="!isNewBox">
              <div class="col-sm-3" style="text-align: center; font-weight: bold">
                <i class="mdi mdi-map-marker-circle" style="font-size: 36px; "></i><br>
                {{ $t('box_info') }}
              </div>
              <div class="col-sm-9" style="margin-bottom: 20px;">
                <table style="width: 100%">
                  <tr>
                    <th>{{ $t('box_id') }}</th>
                    <td>{{ data.id }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('created') }}</th>
                    <td>{{ data.created_at }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('mon_interfaces') }}</th>
                    <td>{{ data.mon_interfaces.length }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <hr v-if="!isNewBox">
            <div class="form-group row">
              <label class="col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
              <div class="col-sm-9">
                <input type="text" :disabled="!isEditingAllowed" v-model="data.name" class="form-control" :placeholder="$t('name')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 text-right control-label col-form-label">{{ $t('type') }}</label>
              <div class="col-sm-9">
                <v-select class="style-chooser" :disabled="!isEditingAllowed"
                          v-model="data.type" label="name" :options="options.boxesTypes"></v-select>
              </div>
            </div>
          </Card>
          <Card :name="$t('links')" :show="true" v-if="!isNewBox">
            <div style="overflow-y: auto; max-height: 350px">
              <table class="table table-sm">
                <thead>
                <tr>
                  <th>{{ $t('id') }}</th>
                  <th>{{ $t('name') }}</th>
                  <th>{{ $t('type') }}</th>
                  <th>{{ $t('neighbor') }}</th>
                  <th v-if="isEditingAllowed">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="link in links" :key="link.id">
                  <td>{{ link.link.id }}</td>
                  <td><b>{{ link.link.name }}</b></td>
                  <td>{{ link.link.type.name }}</td>
                  <td>{{ link.neighbor.name }}<br><small>{{ link.neighbor.type.name }}</small></td>
                  <td v-if="isEditingAllowed">
                    <button class="btn btn-sm btn-default"  @click="editLink(link)"
                            style="margin-right: 3px; margin-bottom: 3px"><i class="mdi mdi-table-edit"></i></button>
                    <button class="btn btn-sm btn-danger" @click="deleteLink(link)"
                            style="margin-right: 3px; margin-bottom: 3px"><i class="mdi mdi-trash-can"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div style="float: right">
              <button v-if="isEditingAllowed" class="btn btn-default btn-sm" @click="addLink()"><i class="mdi mdi-plus-box"></i>
                {{ $t('add_link') }}
              </button>
            </div>
          </Card>
          <Card :name="$t('mon_onts')" :show="true" v-if="isEditingAllowed">
            <div class="row">
              <div class="col-sm-12" >
                {{ $t('choose_onts_for_monitoring') }}
              </div>
              <div class="col-sm-6" >
                <button style="margin: 6px" class="btn btn-sm btn-success" :disabled="options.onts.length === 0"
                        @click="options.onts.forEach(e => {e.selected = true})"><i class="fa fa-plus"></i>
                  {{ $t('select_all') }}
                </button>
                <button style="margin: 6px" class="btn btn-sm btn-secondary" :disabled="options.onts.length === 0"
                        @click="options.onts.forEach(e => {e.selected = false})"><i class="fa fa-minus"></i>
                  {{ $t('unselect_all') }}
                </button>
              </div>
              <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12" style="overflow-y: auto; max-height: 200px;">
                <div style="display: inline">
                  <a v-for="ont in options.onts"
                     :key="ont.name"
                     :class="'badge badge-pill a-to-badge' + (ont.selected ? ' badge-success':' badge-secondary')"
                     style="font-size: 15px; margin: 3px"
                     @click="ont.selected = !ont.selected"
                     href="javascript:void(0)"
                  >
                    <i class="fa fa-plus" v-if="!ont.selected"></i>
                    <i class="fa fa-minus" v-if="ont.selected"></i>
                    {{ ont.name }}
                  </a>
                </div>
              </div>
            </div>
          </Card>
          <Card :name="$t('mon_onts')" :show="true" v-else>
            <div class="row">
              <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12" style="overflow-y: auto; max-height: 200px;">
                <div style="display: inline">
                  <a v-for="ont in options.onts"
                     :key="ont.name"
                     :class="'badge badge-pill a-to-badge' + (ont.selected ? ' badge-success':' badge-secondary')"
                     style="font-size: 15px; margin: 3px"
                     href="javascript:void(0)"
                  >
                    <i class="fa fa-plus" v-if="!ont.selected"></i>
                    <i class="fa fa-minus" v-if="ont.selected"></i>
                    {{ ont.name }}
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div class=" col-12 col-lg-6 col-xl-6">
          <Card :name="$t('coordinates')" :show="true">
            <div class="row">
              <div class="col-10">
                <div v-if="coordinates !== null">
                  {{ coordinates.display_name }}
                </div>
                <div v-else>
                  <h4>{{ $t('click_to_button_map_for_set_coordinates') }}</h4>
                </div>
              </div>
              <div class="col-2">
                <button v-if="isEditingAllowed" class="btn btn-info btn-block" @click="$refs.searchMapCoordinates.show()"><i
                    class="mdi mdi-map"></i></button>
              </div>
            </div>
            <div class="row" v-if="coordinates !== null">
              <div class="col-12">
                <hr>
              </div>
              <div class="col-12">
                <CoordinatesMapMini :zoom="17" :coordinates="coordinates"></CoordinatesMapMini>
              </div>
            </div>
          </Card>
          <Card :name="$t('template_info')" :show="true">
            <div class="row" v-if="template.id !== null" style="font-size: 95%">
              <div class="col-sm-3" style="text-align: center; font-weight: bold">
                <i class="mdi mdi-ethernet" style="font-size: 36px"></i>
                <br>
                {{ $t('interface') }}
              </div>
              <div class="col-sm-9">
                <table style="width: 100%">
                  <tr>
                    <th>{{ $t('id') }}</th>
                    <td>{{ template.interface.id }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('name') }}</th>
                    <td>{{ template.interface.name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('device') }}</th>
                    <td>{{ template.interface.device.ip }} <br><i>({{ template.interface.device.name }})</i></td>
                  </tr>
                  <tr v-if="template.interface.comment">
                    <th>{{ $t('comment') }}</th>
                    <td>{{ template.interface.comment }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <hr>
            <div class="row" v-if="template.id !== null" style="font-size: 95%">
              <div class="col-sm-3" style="text-align: center; font-weight: bold">
                <i class="mdi mdi-map" style="font-size: 36px"></i>
                <br>
                {{ $t('template') }}
              </div>
              <div class="col-sm-9">
                <table style="width: 100%">
                  <tr>
                    <th>{{ $t('template_id') }}</th>
                    <td>{{ template.id }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('created') }}</th>
                    <td>{{ template.created_at }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('updated') }}</th>
                    <td>{{ template.updated_at }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('count_boxes') }}</th>
                    <td>{{
                        templateObjects.filter(o => {
                          return o.type.type === 'box'
                        }).length
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('count_links') }}</th>
                    <td>{{
                        templateObjects.filter(o => {
                          return o.type.type === 'line'
                        }).length
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

          </Card>
        </div>
        <div class="col-12" v-if="isEditingAllowed && box_id " style="padding-bottom: 15px">
          <button class="btn-default btn btn-menu " @click="saveOnServer" ><i class="mdi mdi-content-save"></i>
            {{ $t('save') }}
          </button>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import FullscreenPreloader from "../../components/FullscreenPreloader";
import Card from "@/components/Card"
import CoordinatesMapMini from "../../components/Blocks/CoordinatesMapMini";
import CoordinatesMapSearch from "../../components/Blocks/CoordinatesMapSearch";
import ModalForm from "@/components/ModalForm";
import axios from "axios";

export default {
  components: {ModalForm, CoordinatesMapSearch, CoordinatesMapMini, Card, FullscreenPreloader},
  props: {
    box_id: {
      type: [Number, String],
      default: null,
    }
  },
  computed: {
    isNewBox() {
      return this.data.id === null
    },
    isEditingAllowed() {
      return this.$auth.isPermitted('pon_boxes_map_editing')
    },
  },
  data() {
    return {
      updatedLocally: false,
      loading: true,
      template: null,
      links: [],
      data: null,
      neighbors: [],
      options: {
        boxesTypes: [],
        lineTypes: [],
        onts: [],
      },
      temp: {
        link: null,
        linksForDel: [],
      },
      coordinates: null,
      templateObjects: [],
    }
  },
  async mounted() {
    this.initData()
  },
  methods: {
    addLink() {
      console.log("Try add link")
      this.temp.link = {
        link: {
          name: '',
          id: null,
          type: null,
          coordinates: null,
        },
        neighbor: null,
      }
      this.$refs.editLink.show()
    },
    deleteLink(l) {
      if (!confirm(this.$t('are_you_sure_to_delete_link'))) {
        return
      }
      if (l.link.id !== null) {
        this.temp.linksForDel.push(l)
      }
      this.links = this.links.filter(dd => {
        return dd.link !== l.link
      })
    },
    editLink(link) {
      this.temp.link = link
      this.$refs.editLink.show()
    },
    saveLink() {
      if (this.temp.link.link.id === null) {
        this.links.push(this.temp.link)
      }
      this.$refs.editLink.hide()
    },
    updateCoordinates(b) {
      this.coordinates = b
    },
    async initData() {
      this.loading = true
      if (this.box_id) {
        this.data = await this.getBoxById(this.box_id)
        this.template = await this.getTemplateById(this.data.template.id)
      } else if (this.$route.params.id !== 'new') {
        this.data = await this.getBoxById(this.$route.params.id)
        this.$setRouteMeta(this.$t('edit_box_with_name', {name: this.data.name}))
        this.template = await this.getTemplateById(this.data.template.id)
      } else if (this.$route.query.iface_id) {
        this.$setRouteMeta(this.$t('create_new_box'))
        this.template = await this.getTemplateByInterface(this.$route.query.iface_id)
        console.log(this.template)
        this.data = {
          coordinates: [this.template.interface.device.coordinates.lat, this.template.interface.device.coordinates.lon],
          id: null,
          created_at: null,
          mon_interfaces: [],
          name: '',
          template: this.template,
          type: null,
        }
      }
      this.$api.enableSupportWaiting()
      this.fillObjectData()
      this.loadTypes()
      this.loadOnts()
      await this.$api.waitResponses()
      this.loading = false

    },
    async deleteBox() {
      if (!confirm(this.$t('are_you_sure_to_delete_box'))) {
        return
      }
      this.loading = true
      this.$api.enableSupportWaiting()
      this.links.forEach(dt => {
        if (dt.link.id === null) {
          return
        }
        this.$api.delete('/component/pon_boxes/object/' + dt.link.id).then(r => {
        })
      })
      await this.$api.waitResponses()
      await this.$api.delete('/component/pon_boxes/object/' + this.data.id)
      this.$router.push({name: 'pon_boxes_boxes', query: {refresh: 'yes'}})
      this.loading = false
    },
    async saveOnServer() {
      let isInvalid = false
      if(this.data.type === null) {
        isInvalid = true
        this.$noty.warning(this.$t('type_of_box_is_required'))
      }
      if(this.data.name.trim() === '') {
        isInvalid = true
        this.$noty.warning(this.$t('name_of_box_is_required'))
      }
      // if(this.data.coordinates[0] === this.template.interface.device.coordinates.lat && this.data.coordinates[1] === this.template.interface.device.coordinates.lon) {
      //   isInvalid = true
      //   this.$noty.warning(this.$t('coordinates_must_be_different_from_device'))
      // }
      if(isInvalid) {
        return false
      }
      this.loading = true
      let hasError = null
      if (this.data.id === null) {
        this.data.template = this.template
        await this.$api.post('/component/pon_boxes/object', this.data).then(r => {
          this.data = r.data
          this.$router.push({name: 'pon_boxes_edit_box', params: {id: this.data.id}})
        }).catch(e => {
          console.log(e);
          hasError = e
        })
        this.loading = false
        return
      }

      await this.$api.put('/component/pon_boxes/object/' + this.data.id, this.data).then(r => {
        this.data = r.data
      }).catch(e => {
        console.log(e);
        hasError = e
      })
      if (hasError) {
        this.loading = false
        return
      }
      this.$api.enableSupportWaiting()
      this.links.forEach(dt => {
        if (hasError) {
          this.loading = false
          return
        }
        if (dt.link.coordinates === null) {
          dt.link.coordinates = [
            [this.data.coordinates[0], this.data.coordinates[1]],
            [dt.neighbor.coordinates[0], dt.neighbor.coordinates[1]],
          ]
        }
        if (dt.link.id === null) {
          dt.link.template = this.template
          this.$api.post('/component/pon_boxes/object', dt.link).then(r => {
            dt.link = r.data
          }).catch(e => {
            console.log(e);
            hasError = e
          })
        } else {
          this.$api.put('/component/pon_boxes/object/' + dt.link.id, dt.link).then(r => {
            dt.link = r.data
          }).catch(e => {
            console.log(e);
            hasError = e
          })
        }
      })
      if (hasError) {
        this.loading = false
        return
      }
      this.temp.linksForDel.forEach(dt => {
        this.$api.delete('/component/pon_boxes/object/' + dt.link.id).then(r => {
          dt.link = r.data
        }).catch(e => {
          console.log(e);
          hasError = e
        })
      })
      this.temp.linksForDel = []
      if (hasError) {
        this.loading = false
        return
      }
      await this.$api.waitResponses().catch(e => {
        console.log(e);
        hasError = e
      })
      if (hasError) {
        this.loading = false
        return
      }
      this.$api.disableSupportWaiting()
      this.loading = false
      this.$noty.success(this.$t('success_saved'))
    },
    async getBoxById(boxId) {
      let obj = {}
      await this.$api.get('/component/pon_boxes/object/' + boxId).then(r => {
        obj = r.data
      })
      return obj
    },
    async getTemplateByInterface(ifaceId) {
      let template = {}
      await this.$api.get('/component/pon_boxes/template/by-interface/' + ifaceId).then(r => {
        template = r.data
      })
      return template
    },
    async getTemplateById(id) {
      let template = {}
      await this.$api.get('/component/pon_boxes/template/' + id).then(r => {
        template = r.data
      })
      return template
    },
    async loadTypes() {
      await this.$api.get('/component/pon_boxes/type').then(r => {
        this.options.boxesTypes = []
        this.options.lineTypes = []
        r.data.forEach(elem => {
          if (elem.type === 'line') {
            this.options.lineTypes.push(elem)
          } else if (elem.type === 'box') {
            this.options.boxesTypes.push(elem)
          }
        })
      })
    },
    async loadOnts() {
      await this.$api.get(`/component/pon_boxes/options/interfaces/${this.template.interface.device.id}/${this.template.interface.id}?type=ONU`).then(r => {
        this.options.onts = []
        r.data.forEach(e => {
          e.selected = this.data.mon_interfaces.filter(m => {
            return m.id === e.id
          }).length > 0
          this.options.onts.push(e)
        })
      })
    },
    async fillObjectData() {
      if (this.data === null || this.data.coordinates === null || this.template === null) {
        alert('getObjectsByTemplate required defined this.data and template this.variables')
        return
      }
      this.neighbors = []
      this.links = []
      await this.$api.get('/component/pon_boxes/object/by-template/' + this.template.id).then(r => {
        this.templateObjects = r.data


        this.neighbors.push({
          id: null,
          coordinates: [this.template.interface.device.coordinates.lat, this.template.interface.device.coordinates.lon],
          name: this.$t('device') + `: ${this.template.interface.device.ip}`,
          status: 'unknown',
          count_interfaces: null,
          type: {
            id: null,
            name: this.$t('device'),
            type: 'device',
            description: '',
          }
        })

        //Add neighbors
        r.data.forEach(elem => {
          if (elem.type.type === 'box' && elem.id !== this.data.id) {
            this.neighbors.push(elem)
          }
        })
        console.log(this.neighbors)

        //Fetch links
        if (!this.isNewBox) {
          r.data.forEach(elem => {
            if (elem.type.type !== 'line') {
              return
            }
            let coordinateFound = false
            elem.coordinates.forEach(coordinate => {
              if (coordinate[0] == this.data.coordinates[0]
                  &&
                  coordinate[1] == this.data.coordinates[1]
              ) {
                coordinateFound = true
              }
            })
            if (coordinateFound) {
              this.neighbors.forEach(neighbor => {
                elem.coordinates.forEach(coordinate => {
                  if (coordinate[0] == neighbor.coordinates[0]
                      &&
                      coordinate[1] == neighbor.coordinates[1]
                  ) {
                    this.links.push({neighbor: neighbor, link: elem})
                  }
                })
              })
            }
          })
        }
      })


      if (this.data.coordinates) {
        await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&polygon=1&addressdetails=1&lat=${this.data.coordinates[0]}&lon=${this.data.coordinates[1]}`).then(r => {
          this.coordinates = r.data
        })
      }
    }
  },
  watch: {
    data: {
      handler(n) {
        this.updatedLocally = true
      },
      deep: true,
    },
    $route: {
      handler(n) {
        if (n.name === 'pon_boxes_edit_box') {
          if (n.params.id !== this.data.id) {
            this.initData()
          }
        }
      }
    },
    links: {
      handler(n) {
        this.updatedLocally = true
        console.log("Links was updated!")
      },
      deep: true,
    },
    'options.onts': {
      handler(onts) {
        this.data.mon_interfaces = onts.filter(f => {
          return f.selected
        })
      },
      deep: true,
    },
    coordinates: {
      handler(n) {
        this.links.forEach((larr, num) => {
          let mustBeUpdated = false
          larr.link.coordinates.forEach((coordinate, id) => {
            if (coordinate[0] === this.data.coordinates[0] && coordinate[1] === this.data.coordinates[1]) {
              larr.link.coordinates[id] = [n.lat, n.lon]
              mustBeUpdated = true
            }
          })
          if (mustBeUpdated) {
            this.$set(this.links, num, larr)
          }
        })
        this.updatedLocally = true
        this.data.coordinates = [
          n.lat,
          n.lon,
        ]
      },
      deep: true,
    },
  }
}
</script>

<style>
.btn-menu {
  border-radius: 0 !important;
  border: 1px solid #f0f0f0;
}

.btn-menu:hover {
  border-radius: 0 !important;
  opacity: 0.8;
}

.flex-btn-menu {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.flex-btn-menu > button {
  flex: 1 1 200px;
}
</style>