export default {
    sys_config: {
        tabs: {
            system_configuration: "Конфігурація системи",
            components: "Компоненти",
            schedule: "Планувальник",
            system_info: "Системна інформація",
        },
        groups: {
            auto_topology: "Автоматичная топологія",
            notifications: "Сповіщення",
            web_panel: "Веб-панель",
            system: "Система",
            security: "Безпека",
            proxy: "Проксі",
            switcher_core: "Работа с пристроями",
            all_ok_billing: "Компонент: AllOkBilling",
            oxidized: "Бекапи конфігів (Oxidized)",
            nodeny_plus: "NoDeny plus",
            search_device: "API пошуку пристроїв",
            mikbill: "MikBill",
            links: "Links",
        },
        by_default: "За замовчуванням: '{default}'",
        parameter_name: {
            "SECURE_CHECK_PASSWORD_STRENGTH": "Перевірка складності пароля",
            "ANALYTICS_MIN_RX_SIGNAL": "Мін рівень вхідного сигналу ONU",
            "ANALYTICS_MAX_RX_SIGNAL": "Макс рівень вхідного сигналу ONU",
            "ANALYTICS_MIN_OLT_RX_SIGNAL": "Мін рівень вхідного сигналу OLT",
            "ANALYTICS_MAX_OLT_RX_SIGNAL": "Макс рівень вхідного сигналу OLT",
            "LINKS_UTILIZATION_CALCULATE_PERIOD": "Період розрахунку використання каналу",
            "LINKS_UTILIZATION_MAX_PRC_FOR_ALERT": "Процент використання каналу для створення сповіщення",
            AUTO_TOPOLOGY_SCHEDULE_ENABLED: "Запускать автоматично",
            AUTO_TOPOLOGY_THREADS: "Кількість потоків",
            SEARCH2_FILTER: "Додатковий пошук по",
            SEARCH2_ENABLED: "Додатковий статус пошуку",
            RATE_LIMITER_TIME: "Таймаут блокування IP",
            RATE_LIMITER_ATTEMPTS: "Кількість спроб входу",
            RATE_LIMITER_ENABLED: "Увімкнути захист від брутфорсу",
            MIKBILL_SET_ONT_COORDINATES: "Знаходити координати ОНУ",
            ALL_OK_BILLING_SERVICE_URL: "URL сервісу",
            MAP_TILE_LAYER: "Тип мапи",
            SWC_ENABLE_SPLITTING: "Вичитувати та кешувати по інтерфейсам",
            MIKBILL_API_ADDR: "MikBill веб-адреса",
            MIKBILL_AUTH_KEY: "Ключ API",
            MIKBILL_GLOBAL_SEARCH_FIELD: "Використовувати в пошуку",
            WEB_ZOOM_MAIN: "Масштаб веб-інтерфейсу",
            WEB_ZOOM_IFRAME: "Масштаб веб-інтерфейсу(в iframe)",
            SWC_CHECK_ICMP_PING: "Перевіряти стан по ICMP",
            SWC_CACHE_ACTUALIZE_TIMEOUT_SEC: "Актуалізувати кеш запиту до пристрою через",
            NODENY_URL: "NoDeny веб-адреса",
            NODENY_DATABASE_DSN: "DSN URL підключення до БД",
            NODENY_DATABASE_USERNAME: "Ім'я користувача БД",
            NODENY_DATABASE_PASSWORD: "Пароль користувача",
            NODENY_COMPARISON_DESCRIPTION_REQUIRED: "Опис інтерфейсу і ім'я повинні співпадати",
            NODENY_COMPARISON_CHECK_TOPOLOGY: "Перевіряти топологію",
            EXTERNAL_HTTP_ADDRESS: "Веб-адреса системи",
            OXIDIZED_URL: "Oxidized URL",
            OXIDIZED_THREADS: "Кількість потоків",
            OXIDIZED_INTERVAL: "Інтервал бекапу",
            OXIDIZED_TIMEOUT: "Таймаут",
            POLLER_DO_NOT_CLEAR_INTERFACES: "Не очищати інтерфейси",
            SWC_CONSOLE_WAIT_BYTE_SEC: "Таймаут потоку байтів",
            SWC_SNMP_VERSION: "SNMP-версія",
            NOTIFICATIONS_CHECK_PREVIOUS_MESSAGE: "Перевіряти попередні повідомлення",
            DEFAULT_LANGUAGE: 'Мова за замовчуванням',
            APP_NAME: 'Імя агенту',
            LOGS_RETURN_RESULTS_LIMIT: 'Розмір логів',
            MAP_COORDINATES: 'Координати мапи',
            PROMETHEUS_RETENTION_TIME: "Запам'ятовувати час життя логів",
            LOG_LEVEL: 'Рівень логів',
            RR_NUM_WORKERS: 'Кількість потоків веб-сервера',
            POLLER_IGNORE_DOWN: 'Ігнорувати лежачі пристрої',
            POLLER_COUNT_PROCS: 'Кількість потоків опитувальника',
            API_KEY_EXPIRATION: 'Час життя ключа',
            TRUSTED_HOST_NETWORK_LIST: 'Список довіренних мереж',
            PROXY_ENABLED: 'Проксі ввімкнутий',
            PROXY_REAL_IP_HEADER: 'Заголовок з IP користувача',
            SWC_CONSOLE_CONN_TYPE: 'Тип підключення консолі',
            SWC_CONSOLE_PORT: 'Порт консолі',
            SWC_CONSOLE_TIMEOUT_SEC: 'Таймаут консолі',
            SWC_SNMP_REPEATS: 'SNMP repeats',
            SWC_SNMP_TIMEOUT_SEC: 'SNMP timeout sec',
            SWC_SNMP_PORT: 'SNMP port',
            SWC_MIKROTIK_API_PORT: 'Mikrotik API-порт',
            SEARCH_DEVICE_SOURCES: 'Джерела пошуку',
            SEARCH_DEVICE_CHECK_ALL_SOURCES: 'Перевіряти всі джерела',
            SEARCH_DEVICE_HISTORY_ONLY_ACTIVE: 'Повертати тільки активні',
            SEARCH_DEVICE_IGNORE_TAG_PORTS: 'Ігнорувати TAGGED-порти',
            SEARCH_DEVICE_REQUEST_CONCURRENCY: 'Кількість потоків пошуку',
        },
        parameter_description: {
            "SECURE_CHECK_PASSWORD_STRENGTH": "Перевірка мінімальної складності пароля. Мінімум 8 символів, включаючи - великі, малі літери, цифри та спеціальні символи",
            "ANALYTICS_MIN_RX_SIGNAL": "Встановити мінімальний рівень RX у dBm",
            "ANALYTICS_MAX_RX_SIGNAL": "Встановити максимальний рівень RX у dBm",
            "ANALYTICS_MIN_OLT_RX_SIGNAL": "Встановити мінімальний рівень RX OLT у dBm",
            "ANALYTICS_MAX_OLT_RX_SIGNAL": "Встановити максимальний рівень RX OLT у dBm",
            "LINKS_UTILIZATION_CALCULATE_PERIOD": "Період у форматі тривалості (60s, 1m, 5m, 1h) для розрахунку використання каналу",
            "LINKS_UTILIZATION_MAX_PRC_FOR_ALERT": "Процент використання порту, перевищення якого спричинить створення події",

            SEARCH2_FILTER: "Додатковий пошук за вказаною сутністю",
            SEARCH2_ENABLED: "Буде показано додатковий пошук в заголовку",
            RATE_LIMITER_TIME: "Час перебування IP у чорному списку (в секундах)",
            RATE_LIMITER_ATTEMPTS: "Кількість невдалих спроб входу для надсилання IP до чорного списку (за 10 хвилин)",
            RATE_LIMITER_ENABLED: "Увімкнути захист від перебору за допомогою блокування IP",
            ALL_OK_BILLING_SERVICE_URL: "For example - https://service.billing.com. Used for build billing links",
            MAP_TILE_LAYER: "Вкажіть джерело мапи за-замовчуванням",
            SWC_ENABLE_SPLITTING: "При виклику модулів без вказання інтерфейсу (наприклад отримання списку ОНУ, їх статусів) отримана відповідь буде прочитана в розрізі інтерфесів і закешована",
            MIKBILL_API_ADDR: "MikBill веб-адрес, в формате https://admin.isp",
            MIKBILL_AUTH_KEY: "Ключ API. Вказуйте той же, що ввели в налаштуваннях інтеграції в MikBill",
            MIKBILL_GLOBAL_SEARCH_FIELD: "Поле абонента, яке потрібно використовувати для пошуку",
            WEB_ZOOM_MAIN: "Значення за-замовчуванням - 1.0. Наприклад, щоб встановити 95% - вкажіть 0.95. Це експериментальна опція",
            WEB_ZOOM_IFRAME: "Значення за-замовчуванням - 1.0. Наприклад, щоб встановити 95% - вкажіть 0.95. Це експериментальна опція",
            SWC_CHECK_ICMP_PING: "",
            OXIDIZED_TIMEOUT: "Таймаут опитування обладнання",
            OXIDIZED_URL: "URL інстанса oxidized. Не змінюйте, якщо використовуєете влаштованний інстанс",
            OXIDIZED_THREADS: "Максимальна кількість потоків при опитуванні обладнання. Рекомендовано - потоки = кількість CPU",
            OXIDIZED_INTERVAL: "Інтервал опитування обладнання, в секундах",
            POLLER_DO_NOT_CLEAR_INTERFACES: "Не очищати відсутні інтерфейси. Якщо опція ввімкнена - опитувальник не буде видаляти старі інтерфейси",
            SWC_CONSOLE_WAIT_BYTE_SEC: "Максимальна кількість секунд очікування наступного байта-данних при роботі з консолю",
            SWC_SNMP_VERSION: "",
            NOTIFICATIONS_CHECK_PREVIOUS_MESSAGE: "Коли подія закривається, система перевіряє, чи було відправлено сповіщення. Якщо сповіщення про проблему не було відправлено - сповіщення про вирішення проблеми буде проігноровано",
            DEFAULT_LANGUAGE: 'Мова інтерфейсу за замовчуванням (наприклад, для нових користувачів)',
            LOG_LEVEL: '',
            RR_NUM_WORKERS: 'Кількість потоків веб-серверу',
            PROXY_ENABLED: 'Якщо використовується проксі - цю опію необхідно ввімкнути. В випадку використання довірених IP і проксі - це можи викликати проблеми з безпекою',
            PROXY_REAL_IP_HEADER: 'Заголовок реального IP користувача',
            SWC_CONSOLE_CONN_TYPE: 'Тип консольного підключення',
            SWC_CONSOLE_PORT: '',
            SWC_CONSOLE_TIMEOUT_SEC: 'Максимальне очікування сессії в секундах',
            SWC_SNMP_PORT: "SNMP-порт",
            POLLER_COUNT_PROCS: "Максимальна кількість фонових процессів для опитування обладнання",
            POLLER_IGNORE_DOWN: "Виключити опитування недоступного обладнання",
            MAP_COORDINATES: "Координати карти",
            PROMETHEUS_URL: "Prometheus URL",
            ALERTMANAGER_URL: "Alertmanager URL",
            PROMETHEUS_RETENTION_TIME: "Період збереження данних Prometheus",
            REDIS_HOST: "Хост Redis",
            REDIS_PORT: " Порт Redis",
            REDIS_PASSWORD: " Пароль Redis",
            ALLOW_TRUSTED_HOSTS: "Перевірте надійні мережі",
            GROUP_DEVICES: "Увімкнути групу пристроїв",
            SEARCH_DEVICE_SOURCES: "Пошук джерел",
            SEARCH_DEVICE_CHECK_ALL_SOURCES: "Перевірте всі джерела",
            SEARCH_DEVICE_HISTORY_ONLY_ACTIVE: "З історії - активний лише пошук",
            SEARCH_DEVICE_IGNORE_TAG_PORTS: "Ігнорувати транспортні порти (тільки комутатор)",
            SEARCH_DEVICE_REQUEST_CONCURRENCY: "Запити на паралельність",
            LOGS_RETURN_RESULTS_LIMIT: "Максимальна кількість значень виборки",
            DATABASE_URL: "Строка підключення для PDO (https://www.php.net/manual/ru/ref.pdo-mysql.connection.php)",
            DATABASE_NAME: "Ім'я бази данних",
            DATABASE_USER: "Ім'я користувача",
            DATABASE_PASSWD: "Пароль користувача",
            APP_NAME: "Ім'я додатку",
            API_KEY_EXPIRATION: "Час житя API-ключа(в секундах)",
            TRUSTED_HOST_NETWORK_LIST: "Список довіренних мереж, вказуються через кому",
            TRUSTED_HOST_PROXY_ENABLED: "Використовуються для проксі (варіанти: yes|no)",
            TRUSTED_HOST_PROXY_REAL_IP_HEADER: "Ім'я заголовка, с проксі-сервера для отримання IP-адреси",
            AUTH_CHECK_PAIR_METHOD: "Им'я класу, для для перевірки логіна-паролю",
            RPC_CONCURRENCY: "Максимальна кількість оброблювачів при запитах типу BatchRPC",
            RPC_TIMEOUT: "Максимальний час очікування відповіді",
            RPC_RETRY: "Кількість повторів при помилці",
            RPC_URL: "Адреса RPC-сервера (не рекомендуется змінювати)",
            MEMCACHE_ENABLED: "Використовувати memcached (варианты: yes|no)",
            MEMCACHE_SERVER: "Адреса сервера",
            MEMCACHE_PORT: "Порт сервера",
            MEMCACHE_CACHING_QUERY_TIMEOUT_SEC: "Час життя кеша бази даних (в секундах)",
            SWC_TELNET_PORT: "Порт телнет",
            SWC_TELNET_TIMEOUT_SEC: "Максимальний час очікування відповіді від обладнання через telnet",
            SWC_SNMP_REPEATS: "Кількість повторів snmp",
            SWC_SNMP_TIMEOUT_SEC: "Максимальний час очікування відповіді snmp",
            SWC_CALL_CONCURRENCY: "Максимальна кількість одночасних запитів до обладнання",
            SWC_MIKROTIK_API_PORT: "Порт API мікротика",
            SWC_CACHE_SYSTEM: "Кеш-система для збереження відповідей від switcher-core",
            SWC_CACHE_ACTUALIZE_TIMEOUT_SEC: "Час актуальності даних (через скільки секунд знову запитувати дані з обладнання), в секундах",
            SWC_CACHE_TIMEOUT_SEC: "Час життя кеша, в секундах",
            ALL_OK_BILLING_DATABASE_URL: "Строка подключення до БД AllOkBilling, PDO",
            ALL_OK_BILLING_DATABASE_USER: "Ім'я користувача",
            ALL_OK_BILLING_DATABASE_PASSWD: "Пароль",
            ALL_OK_BILLING_API_URL: "URL API AllOkBilling",
        },
    },
}