<template>
  <div class="row" ref="networkBlock" style="background: #FAFAFA">
    <FullscreenPreloader :show="loading"></FullscreenPreloader>
    <Preloader style="position: absolute; z-index: 1; left: 10px;  width: 35px; height: 35px" :size="35" v-if="reloading"></Preloader>
    <div style="position: absolute; z-index: 1; width: 74px;  right: 120px; padding-top: 10px;">
      <select v-model="configuration.auto_reload" class="form-control" :title="$t('auto_reload')" style="height: 34px">
        <option v-for="param in ['off', '30s', '1m', '2m', '5m', '10m']" :key="param" :value="param"
                :checked="param == configuration.auto_reload">{{ param }}
        </option>
      </select>
    </div>
    <div style="position: absolute; z-index: 1; padding: 10px;  right: 0 ">
      <div style="max-width: 250px">
        <button class="btn btn-light btn-sm" @click="initializeNetwork()" style=" padding-bottom: 0px"
                :title="$t('reload')">
          <i class="mdi mdi-reload mdi-18px"></i>
        </button>
        <Fullscreen :object="$refs.networkBlock" ></Fullscreen>
        <button class="btn btn-light btn-sm" @click="toggleFilterModal()" style=" padding-bottom: 0px"
                :title="$t('open_parameters')">
          <i class="mdi mdi-menu mdi-18px"></i>
        </button>
        <div id="topologyFiltersModal" v-if="configuration.filterFormModalParameters.showModal">
          <div class="row">
            <div class="col-9">
              {{ $t('configure_your_topology') }}
            </div>
            <div class="col-3">
              <a href="javascript:void(0)" @click="toggleFilterModal()"
                 style="position: absolute; right: 8px; top: -7px">
                <i class="mdi mdi-close mdi-24px"></i>
              </a>
            </div>
          </div>
          <hr style="margin: 0; padding: 0">
          <div class="row">
            <div class="col-6">
              <div class="form-group ">
                <label class="mb-0">{{ $t('util_time_period') }}</label>
                <select v-model="configuration.requestParams.period" class="form-control">
                  <option v-for="param in ['10m', '15m', '30m', '45m', '1h']" :key="param" :value="param">{{ param }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <label class="mb-0">{{ $t('hide_devices_without_links') }}</label>
                <label class="switch-small" :title="$t('show_devices')">
                  <input type="checkbox" v-model="configuration.requestParams.hide_without_links"
                         :disabled="configuration.filterFormModalParameters.hide_without_links.disabled">
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <label class="mb-0">{{ $t('minimal_link_utilization') }}</label>
                <input class="form-control" type="number" step="5" min="0" max="100"
                       v-model="configuration.requestParams.utilization">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <label class="mb-0">{{ $t('hide_level_above') }}</label>
                <input class="form-control" type="number" step="1" min="0" max="100"
                       v-model="configuration.requestParams.hide_levels_above">
              </div>
            </div>
          </div>
          <hr style="margin: 0; padding: 0">
          <div class="row">
            <div class="col-6">
              <div class="form-group ">
                <label class="mb-0">{{ $t('enable_physics') }}</label><br>
                <label class="switch-small" :title="$t('show_devices')">
                  <input type="checkbox" :checked="configuration.enablePhysics" @change="togglePhysics">
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <label class="mb-0">{{ $t('save_object_positions') }}</label>
                <button class="btn btn-info btn-sm " @click="saveObjectPositions"><i class="mdi mdi-content-save"></i> {{ $t('save_position') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <hr style="margin: 0; padding: 0;">
      <vis-network
          ref="network"
          class="network-container"
          :options="options"
          :events="[
               'resize', 'zoom',   'blurEdge', 'click',
                 'dragging', 'dragEnd',
               'stabilized', 'stabilizationIterationsDone', 'startStabilizing', 'stabilizationProgress'
             ]"
          @resize="closeAll"
          @zoom="closeAll"
          @click="clickEvent"
          @stabilization-iterations-done="updateDeviceInfoBoxPosition"
          @start-stabilizing="updateDeviceInfoBoxPosition"
          @stabilization-progress="updateDeviceInfoBoxPosition"
          @dragging="closeAll"
          @drag-end="closeAll"
      />
    </div>
    <DeviceModal ref="deviceModal" :device="deviceModalInfo" v-if="deviceModalInfo"/>
    <DeviceInfoBox v-if="infoBoxDevice" :device="infoBoxDevice" :styles="infoboxStyle" @close="closeAll"
                   @show-device="showDeviceModal" />
    <LinkInfoBox v-if="infoBoxLink" ref="linkModal" :link="infoBoxLink" :key="infoBoxLink.id" :styles="linkInfoStyle" @close="closeAll"/>
  </div>
</template>

<script>
import DeviceInfoBox from "@/components/Modals/DeviceInfoBox.vue";
import DeviceModal from "@/components/Modals/DeviceModal.vue";
import LinkInfoBox from "@/components/Modals/LinkInfoBox.vue";
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";
import Preloader from "@/components/Preloader.vue";
import Fullscreen from "@/components/Fullscreen.vue";

export default {
  components: {Fullscreen, Preloader, FullscreenPreloader, DeviceInfoBox, DeviceModal, LinkInfoBox},

  deactivated() {
    console.log("destroy update interval")
    if(this.autoReloadInterval) {
      clearInterval(this.autoReloadInterval)
      this.autoReloadInterval = null
    }
  },
  beforeDestroy() {
    console.log("destroy update interval")
    if(this.autoReloadInterval) {
      clearInterval(this.autoReloadInterval)
      this.autoReloadInterval = null
    }
    this.saveObjectPositions(false)
  },
  data() {
    return {
      autoReloadInterval: null,
      configuration: {
        requestParams: {
          period: "10m",
          hide_without_links: true,
          utilization: 0,
          hide_levels_above: 50,
        },
        filterFormModalParameters: {
          showModal: false,
          hide_without_links: {
            disabled: false,
          }
        },
        objectPositions: null,
        enablePhysics: false,
        auto_reload: 'off',
      },
      fullScreenEnabled: false,
      loading: false,
      reloading: false,
      nodeIds: [],
      devices: [],
      links: [],
      selectedDevice: null,
      infoBoxDevice: null,
      deviceModalInfo: null,
      infoBoxLink: null,
      infoboxStyle: {},
      linkInfoStyle: {},
      options: {
        interaction: {hover: true},
        nodes: {
          shape: "dot",
          font: {
            color: "#343434", size: 14,
            strokeWidth: 6,
            strokeColor: '#FAFAFA',
          },
          scaling: {
            min: 10,
            max: 36,
            label: {
              enabled: true,
              min: 14,
              max: 20,
            },
          },
        },
        edges: {
          color: {color: "#848484", highlight: "#848484"},
          arrows: {
            to: {enabled: true, scaleFactor: 0.5},
          },
          smooth: {
            type: "continuous",
          },
          font: {
            color: '#FAFAFA',
            strokeWidth: 3
          },
          width: 1,
          shadow: false,
        },
        physics: {
          enabled: false,
          adaptiveTimestep: true,
          solver: "forceAtlas2Based",

          repulsion: {
            centralGravity: 0.2,
            springLength: 200,
            springConstant: 0.05,
            nodeDistance: 100,
            damping: 0.09
          },
          hierarchicalRepulsion: {
            centralGravity: 0.0,
            springLength: 100,
            springConstant: 0.01,
            nodeDistance: 120,
            damping: 0.09,
            avoidOverlap: 0
          },
          forceAtlas2Based: {
            gravitationalConstant: -150,
            centralGravity: 0.02,
            springLength: 70,
            springConstant: 0.2,
          },
          stabilization: {
            enabled: true,
            iterations: 100,
            updateInterval: 0.01,
            fit: true,
          },
        },
      },
    };
  },
  mounted() {
    this.loading = true
    if (!this.loadSavedConfiguration()) {
      this.initializeNetwork();
      try {
        this.loadSavedPositions()
      } catch (error) {
        console.log(error)
      }
    }
    this.loading = false
  },
  watch: {
    'configuration.auto_reload': {
      handler(value) {
        if(!value || value === 'off') {
          clearInterval(this.autoReloadInterval);
          this.autoReloadInterval = null;
        } else {
          clearInterval(this.autoReloadInterval);
          this.autoReloadInterval = null;

          const interval = this.$helpers.parseDuration(value);
          this.autoReloadInterval = setInterval(() => this.initializeNetwork(), interval)
        }
      },
      immediate: true
    },
    'configuration.requestParams': {
      handler() {
        if (this.configuration.requestParams.utilization > 0) {
          this.configuration.requestParams.hide_without_links = true
          this.configuration.filterFormModalParameters.hide_without_links.disabled = true
        } else {
          this.configuration.filterFormModalParameters.hide_without_links.disabled = false
        }
        this.initializeNetwork()
      },
      deep: true,
    },
    'configuration': {
      handler() {
        localStorage.setItem('topologyMapConfiguration', JSON.stringify(this.configuration))
      },
      deep: true
    },
  },
  methods: {
    showDeviceModal(deviceInfo) {
      this.deviceModalInfo = deviceInfo
      setTimeout(() => {
        this.$refs.deviceModal.open()
      }, 10)
    },
    togglePhysics() {
      if (this.configuration.enablePhysics) {
        this.disablePhysics()
      } else {
        this.enablePhysics()
      }
    },
    enablePhysics() {
      this.$refs.network.setOptions({physics: {enabled: true}});
      this.configuration.enablePhysics = true
    },
    async disablePhysics() {
      this.$refs.network.setOptions({physics: {enabled: false}});
      if(this.configuration.objectPositions) {
        this.loadSavedPositions()
        await this.$nextTick();
        this.setData();
      }
      this.configuration.enablePhysics = false
    },
    loadSavedPositions() {
      for (const i in this.devices) {
        if(typeof this.configuration.objectPositions[this.devices[i].id] !== 'undefined') {
          const position = this.configuration.objectPositions[this.devices[i].id]
          this.devices[i].x = position.x
          this.devices[i].y = position.y
        }
      }
    },
    saveObjectPositions(withToggle = true) {
      this.configuration.objectPositions = this.$refs.network.getPositions();
      if(withToggle) {
        this.disablePhysics();
        this.$noty.success(this.$t('positions_success_saved'))
      }
    },
    loadSavedConfiguration() {
      let config = localStorage.key('topologyMapConfiguration') ? JSON.parse(localStorage.getItem('topologyMapConfiguration')) : null
      if (config) {
        this.configuration = config
        if (this.configuration.enablePhysics) {
          this.enablePhysics()
        }
        return true
      } else {
        this.enablePhysics()
      }
      return false
    },
    toggleFilterModal() {
      this.configuration.filterFormModalParameters.showModal = !this.configuration.filterFormModalParameters.showModal
    },
    setData() {
      if(!this.$refs.network) {
        return
      }
      const position = this.$refs.network.getViewPosition(); // { x, y }
      const scale = this.$refs.network.getScale(); // Текущий масштаб
      this.$refs.network.setData(this.devices, this.links);
      this.$refs.network.moveTo({
        position: position, // Положение { x, y }
        scale: scale,       // Масштаб
        animation: {
          duration: 0       // Без анимации для мгновенного восстановления
        }
      });
    },
    async initializeNetwork() {
      // TODO: потрібно мб прикрутити дебаунс на інппути, бо може виникнути ситуація з декількома запитами одночасно
      // if(this.loading) return

      try {
        await this.loadData();

        if(this.configuration.objectPositions) {
          this.loadSavedPositions()
        }

        await this.$nextTick();
        this.setData();
      } catch (error) {
        console.error("Failed to initialize network: ", error);
      }
    },
    closeEdgeBox() {
      this.infoBoxLink = null
    },
    closeInfoBox() {
      this.infoBoxDevice = null;
      const network = this.$refs.network;
      if (network) {
        network.selectNodes([]); // Скидання вибору ноди
      }
    },
    closeAll(event) {
      this.closeEdgeBox()
      this.closeInfoBox()
    },
    openEdgeBox(event) {
      const edgeId = event.edges[0];
      const edge = this.links.find(i => i.id === edgeId)
      const network = this.$refs.network;

      const fromPosition = network.getPositions([edge.from])[edge.from];
      const toPosition = network.getPositions([edge.to])[edge.to];


      // Визначення середньої точки ребра для позиції LinkInfoBox
      let domMidpoint = network.canvasToDom({
        x: (fromPosition.x + toPosition.x) / 2,
        y: ((fromPosition.y + toPosition.y) / 2),
      });

      // Пробую брати позицію з події, позицію курсора.
      // Взяв так, бо коли лінк трохи зігнутий, то тултіп взагалі десь пливе
      domMidpoint = {
        y: event.pointer.DOM.y,
        x: event.pointer.DOM.x,
      };

      // Оновлення стилю LinkInfoBox
      if(this.$helpers.isMobile()) {
        this.linkInfoStyle = {
          top: `0px`,
          left: `0px`,
          'z-index': 10,
          width: '100%',
        };
      } else {
        this.linkInfoStyle = {
          top: `${domMidpoint.y}px`,
          left: `${domMidpoint.x}px`,
          transform: 'translate(-50%, -85%)',
          'z-index': 10,
          width: '400px',
        };
      }

      this.infoBoxLink = edge.linkData
    },
    async loadData() {
      this.reloading = true
      const {data} = await this.$api.get("/component/links/topology-tree", this.configuration.requestParams)

      this.devices = data.devices.map(i => {
        return {
          deviceData: {...i},
          id: i.id,
          name: i.name,
          label: i.ip,
          ip: i.ip,
          model: i.model,
          color: {
            background: i?.design?.color || "#848484",
            border: "gray"
          },
          size: i?.design?.size || 15,
          x: null,
          y: null,
          links: [],
        }
      })

      this.links = data.links.map(i => {
        return {
          linkData: {...i},
          id: i.id,
          from: i.src_device.id,
          to: i.dest_device.id,
          label: (i.utilization || 0) + '%',
          color: {
            color: i?.design?.color || "#848484",
          },
          width: i?.design?.size || 1,
          arrows: "to",
          font: {
            size: 10,
            strokeColor: i?.design?.color || "#848484",
          },
        };
      });
      this.reloading = false
    },
    openInfobox(event) {
      const nodeId = event.nodes[0];
      const node = this.devices.find((item) => item.id === nodeId);

      if (node) {
        const deviceData = node.deviceData
        let links = this.links.filter((item) => item.to === deviceData.id || item.from === deviceData.id).map(i => ({...i.linkData}))
        for (const i in links) {
           let link = links[i]
           if(!link.src_device) continue

           link.link_type = 'downlink'
           if (link.src_device && link.src_device.id !== node.id) {
             link.link_type = 'uplink'
           }
           links[i] = link
        }
        links.sort((a, b) => {
           return  b.utilization - a.utilization
        })
        console.log(links)
        deviceData.links = links

        this.infoBoxDevice = deviceData
        this.updateDeviceInfoBoxPosition(event);
      } else {
        this.closeInfoBox()
      }
    },

    updateDeviceInfoBoxPosition(event) {
      if (!event?.nodes?.length) {
        this.closeInfoBox()
        return
      }

      if (!this.infoBoxDevice) return;

      const nodeId = event.nodes[0];
      const network = this.$refs.network;

      const canvasPosition = network.getPositions([nodeId])[nodeId];

      const domPosition = network.canvasToDom(canvasPosition);

      // Оновлення стилю LinkInfoBox
      if(this.$helpers.isMobile()) {
        this.infoboxStyle = {
          top: `0px`,
          left: `0px`,
          'z-index': 10,
          width: '100%',
        };
      } else {
        this.infoboxStyle = {
          top: `${domPosition.y}px`,
          left: `${domPosition.x}px`,
          transform: 'translate(-50%, -80%)',
          'z-index': 10,
          'width': '350px',
        };
      }
    },
    clickEvent(event) {
      if (event.nodes.length !== 0  ) {
        this.openInfobox(event)
        this.closeEdgeBox()
      } else if (event.edges.length !== 0  ) {
        this.openEdgeBox(event)
        this.closeInfoBox()
      } else {
        this.closeAll()
      }
    },
  },

};
</script>

<style scoped>
.network-container {
  width: 100%;
  height: 100vh;
}

#topologyFiltersModal {
  background: white;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: black;
  padding-top: 3px;
  width: 400px;
  position: absolute;
  top: 10px;
  right: 8px;
  z-index: 810;
}

#topologyFiltersModal > .row {
  padding: 10px;
}
</style>
<style>
/* Сглаживание текста с закруглёнными краями */
.vis-network .vis-label {
  border-radius: 5px; /* Закругление углов */
  padding: 5px; /* Внутренние отступы */
  background-color: #ffffff; /* Фон текста */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Тень текста */
}
</style>