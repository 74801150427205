<template>
  <div class="container-fluid">
    <BoxInfoModal ref="boxInfoModal"></BoxInfoModal>
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <FullscreenPreloader v-show="preloading"></FullscreenPreloader>
        <Card :name="$t('filters')" :show="true" :disable-hide="true">
          <form class="row" @submit.prevent="loadData">
            <div class="col-lg-6 col-xl-5 col-sm-8 col-md-8 col-7">
              <div class="form-group">
                <label class="mb-0">{{ $t('device') }}</label>
                <v-select class="style-chooser"
                          v-model="filter.device"
                          label="displayName"
                          :options="deviceList.filter(e => {return e.model.type === 'OLT' && (!filter.device || filter.device.id !== e.id)})"
                          :close-on-select="true"
                />
              </div>
            </div>
            <div class="col-lg-2 col-xl-3 col-sm-4 col-md-4 col-5">
              <div class="form-group">
                <label class="mb-0">{{ $t('only_filled') }}</label><br>
                <label class="switch mb-0">
                  <input type="checkbox" v-model="filter.only_filled">
                  <span class="slider "></span>
                </label>
              </div>
            </div>
            <div class="col-lg-2 col-xl-2 col-sm-6 col-md-6 col-6">
              <div class="form-group">
                <label class="mb-0"> &nbsp;</label>
                <button class="btn btn-default btn-block btn-sm" >
                  <i
                      class="mdi mdi-table-search mdi-18px"></i></button>
              </div>
            </div>
            <div class="col-lg-2 col-xl-2 col-sm-6 col-md-6 col-6">
              <div class="form-group">
                <label class="mb-0"> &nbsp;</label>
                <div>
                  <WcaExportExcel style="width: 100%" name="PON boxes" v-if=" !preloading && !error" :fetch="exportXls.fetch"
                                    :fields="exportXls.fields" ></WcaExportExcel>
                </div>
              </div>
            </div>
          </form>
        </Card>
        <Preloader v-if="loading"/>
        <SomethingWrong v-if="error !== '' && !loading" :error-message="error"
                        :show-reload-button="false"></SomethingWrong>
        <v-client-table v-if="!preloading && error === '' && !loading" v-model="data" :columns="table.columns"
                        :options="table.options"
                        class="btn-add-margin-top-for-mobile">
          <template v-slot:device="{row}">
            <router-link :to="{name: 'device_dashboard', params: {id: row.device.id}}">
              {{ row.device.ip }} <span v-if="row.device.name !== ''"> - <small>{{ row.device.name }}</small></span>
            </router-link>
          </template>
          <template v-slot:name="{row}">
            <b>{{ row.name }}</b>
          </template>
          <template v-slot:has_issues="{row}">
            <ColoredBlockText style="width: 40px"
                              :background-color="row.has_issues ? 'rgba(192, 154, 0, 1);' : '#a3a3a3'"
                              :border-color="row.has_issues ? 'rgba(192, 154, 0, 1);' : '#a3a3a3'"
                              :text-color="'#FFFFFF'"
                              v-html="row.has_issues ? $t('yes') : $t('no')"
            ></ColoredBlockText>
          </template>
          <template v-slot:exclude_from_stat="{row}">
            <ColoredBlockText style="width: 40px"
                              :background-color="row.exclude_from_stat ? 'rgba(192, 154, 0, 1);' : '#a3a3a3'"
                              :border-color="row.exclude_from_stat ? 'rgba(192, 154, 0, 1);' : '#a3a3a3'"
                              :text-color="'#FFFFFF'"
                              v-html="row.exclude_from_stat ? $t('yes') : $t('no')"
            ></ColoredBlockText>
          </template>
          <template v-slot:is_full="{row}">
            <ColoredBlockText
                :background-color="row.is_full ? 'darkred' : 'darkgreen'"
                :border-color="row.is_full ? 'red' : 'green'"
                :text-color="'#FFFFFF'"
                v-html="row.is_full ? $t('yes') : $t('no')"
            ></ColoredBlockText>
          </template>
          <template v-slot:actions="{row}">
            <button
                class="btn btn-sm btn-default" @click="showBoxModal(row)"><i class="fa fa-eye"></i></button>
          </template>
        </v-client-table>
      </div>
    </div>

  </div>
</template>


<script>
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";
import SomethingWrong from "@/components/SomethingWrong.vue";
import WcaExportExcel from "@/components/WcaExportExcel.vue";
import ColoredBlockText from "@/components/ColoredBlockText.vue";
import BoxInfoModal from "@/views/UtelsPonBoxes/BoxInfoModal.vue";

var filter = {}

export default {
  components: {
    BoxInfoModal,
    ColoredBlockText,
    WcaExportExcel,
    SomethingWrong,
    FullscreenPreloader,
    Card,
    Preloader,
  },
  data() {
    return {
      modal: {
        title: '',
        data: null,
        loading: true,
      },
      exportXls: {
        fields: {
          'ID': 'id',
          'Utels ID': 'utels_id',
          'Created At': 'created_at',
          'Device IP': 'device.ip',
          'Device Name': 'device.name',
          'OLT Name': 'olt_name',
          'Name': 'name',
          'Count ports': 'count_ports',
          'Count registered': 'count_registered',
          'Is filled': 'is_full',
          'Seems incorrect': 'has_issues',
          'Excluded from stat': 'exclude_from_stat',
          'Comment': 'comment',
        },
        async fetch() {
          let data = []
          let params = {}
          if (filter.device) {
            params['device_id'] = this.filter.device.id
          }
          if (filter.only_filled) {
            params['only_filled'] = 'yes'
          }
          await this.$api.get('/component/utels_integration/boxes', params, true).catch((e) => {
            this.$noty.warning(this.$t('error_export_xls'))
            console.log(e)
          }).then(resp => {
            resp.data.forEach(e => {
              data.push(e)
            })
          });
          return data
        }
      },
      data: [],
      error: '',
      isMounted: false,
      filter: {
        device: null,
        only_filled: false,
      },
      preloading: true,
      loading: false,
      deviceList: [],
      table: {
        columns: ['created_at', 'device', 'olt_name', 'name', 'count_ports', 'count_registered', 'comment', 'loading_prc', 'exclude_from_stat', 'has_issues', 'is_full', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table table-sm',
          perPage: 100,
          perPageValues: [100, 300, 500],
          headings: {
            'created_at': this.$t('created_at'),
            'device': this.$t('device'),
            'olt_name': this.$t('olt_name'),
            'name': this.$t('name'),
            'count_ports': this.$t('count_ports'),
            'count_registered': this.$t('count_registered'),
            'exclude_from_stat': this.$t('exclude_from_stat'),
            'comment': this.$t('comment'),
            'has_issues': this.$t('has_issues'),
            'is_full': this.$t('is_full'),
            'actions': '',
            'loading_prc': this.$t('loaded_prc'),
          },
          sortable: ['created_at', 'name', 'olt_name', 'is_full', 'count_ports', 'exclude_from_stat', 'has_issues', 'count_registered', 'loading_prc'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  async mounted() {
    filter = this.filter
    this.$setRouteMeta(this.$t('utels_pon_boxes'))
    this.$api.enableSupportWaiting()
    this.loadDevicesList()
    if (typeof this.$route.query.device_id !== 'undefined') {
      console.log("DeviceID setted")
      this.filter.devices.push(await this.getDevice(this.$route.query.device_id))
    }
    if (typeof this.$route.query.only_filled !== 'undefined' && this.$route.query.only_filled === 'yes') {
      console.log("Bad signals setted")
      this.filter.only_filled = true
    }
    this.isMounted = true
    await this.$api.waitResponses()

    this.preloading = false
    this.loading = true

    this.loadData()
  },
  methods: {
    async showBoxModal(box) {
      this.$refs.boxInfoModal.showBoxModal(box)
    },
    async loadData() {
      this.loading = true
      let params = {}
      if (this.filter.device) {
        params['device_id'] = this.filter.device.id
      }
      if (this.filter.only_filled) {
        params['only_filled'] = 'yes'
      }
      await this.$api.get('/component/utels_integration/boxes', params, false).catch((e) => {
        console.log(e)
      }).then(resp => {
        this.data = resp.data
      }).finally(() => {
        this.loading = false
      });
    },
    async loadDevicesList() {
      await this.$api.get('/device/options?type=OLT').then(r => {
        this.deviceList = []
        r.data.forEach(elem => {
          if (elem.name.trim() === '') {
            elem.name = this.$t('no_name')
          }
          elem.displayName = `${elem.ip} - ${elem.name}`
          this.deviceList.push(elem)
        })
      }).catch(() => {
      })
    },
    async getDevice(deviceId) {
      let data = {}
      await this.$api.get('/device/' + deviceId).then(r => {
        r.data.displayName = `${r.data.ip} - ${r.data.name}`
        data = r.data
      }).catch(() => {
      })
      return data
    }
  }
}
</script>
<style>
.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}

.json-tree {
  padding-left: 10px !important;
}

.json-tree-key {
  font-size: 14px;
}
</style>