<template>
  <export-excel v-show="show" ref="button"
                :class   = "'btn btn-default ' + (loading ? 'with-opacity' : '')"
                :fetch   = "getData"
                :fields = "fields"
                :worksheet = "worksheet"
                :disabled="loading"
                :name   = "name">
    <i :class="'mdi ' + (loading ? ' mdi-spin mdi-loading ' : 'mdi-microsoft-excel')" :title="$t('export_excel')"></i> {{hideText ? '' :  $t('export_excel')}}
  </export-excel>
</template>

<script>

export default {
  props: {
    fields: {
      type: Object,
      default: null,
    },
    worksheet: {
      type: String,
      default: 'List 1',
    },
    name: {
      type: String,
      default: 'export.xls',
    },
    fetch: {
      type: Function,
      default: null,
    },
    show: {
      type: Boolean,
      default: true,
    }

  },
  data() {
    return {
      loading: false,
      hideText: false
    }
  },
  mounted() {
    this.checkButtonWidth();
    window.addEventListener('resize', this.checkButtonWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkButtonWidth);
  },
  methods: {
    checkButtonWidth() {
      this.$nextTick(() => {
          if(this.$refs.button) {
            let button = this.$refs.button.$el;
            let buttonWidth = button.offsetWidth;
            this.hideText = buttonWidth < 140;
          }
      });
    },
    async getData() {
      if(this.loading) {
        return  null
      }
      this.loading = true
      let data = await this.fetch()
      if (!data || data.length === 0) {
        this.$noty.warning(this.$t('no_data_to_show'))
      }
      this.loading = false
      return data
    },
  }
}
</script>
<style scoped>
.with-opacity {
  opacity: 0.8;
}
</style>