<template>
  <ModalForm ref="deviceModalForm" v-if="device" :modal-width="1000"  :title="$t('device') + ' - ' + device.ip"   :hide-footer="true">
    <template v-slot:content>
      <div v-if="device">
      <div class="row" style="padding: 5px; ">
        <div class="col-12 col-md-5">
          <h4><i class="mdi mdi-database"></i> {{$t('general_info')}}</h4>
          <div style="font-size: 110%">
            {{$t('ip')}}: <b>{{device.ip}}</b>
          </div>
          <div style="font-size: 110%">
            {{$t('name')}}: <b>{{device.name !== '' ? device.name : $t('n_a')}}</b>
          </div>
          <div style="font-size: 110%">
            {{$t('model')}}: <b>{{device.model.name !== '' ? device.model.name : $t('n_a')}}</b>
          </div>
          <div style="font-size: 110%">
            {{$t('group')}}: <b>{{device.group.name !== '' ? device.group.name : $t('n_a')}}</b>
          </div>
          <div style="font-size: 110%">
            {{$t('description')}}: <b>{{device.description !== '' ? device.description : $t('n_a')}}</b>
          </div>
          <hr style="margin-top: 5px; margin-bottom: 5px; padding: 0">
          <h4><i class="mdi mdi-lightning-bolt"></i>  {{$t('pinger')}}</h4>
          <div v-if="device.pinger?.status">
            <div style="font-size: 110%">{{$t('status')}}: <span :style="`font-weight: bold; color: ` + (device.pinger.status === 'Up' ? 'darkgreen': 'darkred')">{{device.pinger.status}}</span></div>
            {{$t('last_change')}}: <b>{{device.pinger.last_change}}</b>
          </div>
          <div v-else>
                <h4 style="color: gray">{{$t('n_a')}}</h4>
          </div>
        </div>
        <div class="col-12 col-md-7">
          <div v-if="device.links.filter(r => r.link_type == 'uplink').length !== 0">
            <h4><i class="mdi mdi-arrow-up-bold-outline"></i> {{$t('uplinks')}}</h4>
            <div style="max-height: 300px; overflow: auto">
              <table class="table table-sm table-bordered table-striped" >
                <thead>
                  <tr>
                    <th>{{$t('interface')}}</th>
                    <th>{{$t('src_iface')}}</th>
                    <th>{{$t('utilization')}} (in/out)</th>
                    <th>{{$t('speed')}} (in/out)</th>
                    <th>{{$t('link_speed')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lnk in device.links.filter(r => r.link_type == 'uplink')" :key="lnk.id">
                    <td><b>{{lnk.src_iface ? lnk.src_iface.name : $t('n_a')}}</b></td>
                    <td>
                      <div v-if="lnk.src_iface">
                        <router-link target="_blank"
                                     :to="{name: 'device_iface_dashboard', params: {id: lnk.src_device.id, 'interface': lnk.src_iface.bind_key}}">
                          {{lnk.src_device.ip}} - {{lnk.src_iface ? lnk.src_iface.name : $t('n_a')}} </router-link>
                      </div>
                      <div v-else>
                        {{lnk.src_device.ip}} - {{lnk.src_iface ? lnk.src_iface.name : $t('n_a')}}
                      </div>
                    </td>
                    <td>
                      <div v-if="lnk.dest_iface?.utilization?.in_util_prc && lnk.dest_iface?.utilization?.out_util_prc">
                        {{lnk.dest_iface.utilization.in_util_prc.toFixed(1)}}% / {{lnk.dest_iface.utilization.out_util_prc.toFixed(1)}}%
                      </div>
                      <div v-else-if="lnk.src_iface?.utilization?.in_util_prc && lnk.src_iface?.utilization?.out_util_prc">
                        {{lnk.src_iface.utilization.in_util_prc.toFixed(1)}}% / {{lnk.src_iface.utilization.out_util_prc.toFixed(1)}}%
                      </div>
                    </td>
                    <td>
                      <div v-if="lnk.dest_iface?.utilization?.in_util_prc && lnk.dest_iface?.utilization?.out_mbps">
                        {{lnk.dest_iface.utilization.in_mbps.toFixed(1)}}Mbps / {{lnk.dest_iface.utilization.out_mbps.toFixed(1)}}Mbps
                      </div>
                      <div v-else-if="lnk.src_iface?.utilization?.in_mbps && lnk.src_iface?.utilization?.out_mbps">
                        {{lnk.src_iface.utilization.in_mbps.toFixed(1)}}Mbps / {{lnk.src_iface.utilization.out_mbps.toFixed(1)}}Mbps
                      </div>
                    </td>
                    <td>
                      <div v-if="lnk.dest_iface?.utilization?.speed_mbits">
                        {{lnk.dest_iface.utilization.speed_mbits }}Mbits
                      </div>
                      <div v-else-if="lnk.src_iface?.utilization?.speed_mbits">
                        {{lnk.src_iface.utilization.speed_mbits }}Mbits
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="device.links.filter(r => r.link_type == 'downlink').length !== 0">
            <h4><i class="mdi mdi-arrow-down-bold-outline"></i> {{$t('downlinks')}}</h4>
            <div style="max-height: 300px; overflow: auto">
              <table class="table table-sm table-bordered table-striped" >
                <thead>
                  <tr>
                    <th>{{$t('interface')}}</th>
                    <th>{{$t('dest_iface')}}</th>
                    <th>{{$t('utilization')}} (in/out)</th>
                    <th>{{$t('speed')}} (in/out)</th>
                    <th>{{$t('link_speed')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lnk in device.links.filter(r => r.link_type == 'downlink')" :key="lnk.id">
                    <td><b>{{lnk.src_iface ? lnk.src_iface.name : $t('n_a')}}</b></td>
                    <td>
                      <div v-if="lnk.dest_iface">
                        <router-link target="_blank"
                                     :to="{name: 'device_iface_dashboard', params: {id: lnk.dest_device.id, 'interface': lnk.dest_iface.bind_key}}">
                          {{lnk.dest_device.ip}} - {{lnk.dest_iface ? lnk.dest_iface.name : $t('n_a')}} </router-link>
                      </div>
                      <div v-else>
                          {{lnk.dest_device.ip}} - {{lnk.dest_iface ? lnk.dest_iface.name : $t('n_a')}}
                      </div>
                    </td>
                    <td>
                      <div v-if="lnk.src_iface?.utilization?.in_util_prc && lnk.src_iface?.utilization?.out_util_prc">
                        {{lnk.src_iface.utilization.in_util_prc.toFixed(1)}}% / {{lnk.src_iface.utilization.out_util_prc.toFixed(1)}}%
                      </div>
                      <div v-else-if="lnk.dest_iface?.utilization?.in_util_prc && lnk.dest_iface?.utilization?.out_util_prc">
                        {{lnk.dest_iface.utilization.in_util_prc.toFixed(1)}}% / {{lnk.dest_iface.utilization.out_util_prc.toFixed(1)}}%
                      </div>
                    </td>
                    <td>
                      <div v-if="lnk.src_iface?.utilization?.in_mbps && lnk.src_iface?.utilization?.out_mbps">
                        {{lnk.src_iface.utilization.in_mbps.toFixed(1)}}Mbps / {{lnk.src_iface.utilization.out_mbps.toFixed(1)}}Mbps
                      </div>
                      <div v-else-if="lnk.dest_iface?.utilization?.in_util_prc && lnk.dest_iface?.utilization?.out_mbps">
                        {{lnk.dest_iface.utilization.in_mbps.toFixed(1)}}Mbps / {{lnk.dest_iface.utilization.out_mbps.toFixed(1)}}Mbps
                      </div>
                    </td>
                    <td>
                      <div v-if="lnk.src_iface?.utilization?.speed_mbits">
                        {{lnk.src_iface.utilization.speed_mbits }}Mbits
                      </div>
                      <div v-else-if="lnk.dest_iface?.utilization?.speed_mbits">
                        {{lnk.dest_iface.utilization.speed_mbits }}Mbits
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from "@/components/ModalForm.vue";

export default {
  components: {ModalForm},
  props: {
    device: {
      type: Object,
      default: null,
    },
  },
  mounted() {

  },
  watch: {
    device: {
      handler() {
        console.log(this.device)
      }
    }
  },
  methods: {
    close() {
      this.$refs.deviceModalForm.hide();
    },
    open() {
      this.$refs.deviceModalForm.show();
    },
  },
};
</script>

