<template>
  <div class="row" v-if="!loading && !error">
    <div class="col-4 col-lg-3 col-xl-2" style="padding-bottom: 15px">
      <button @click="save" class="btn btn-info btn-block">{{ $t('save') }}</button>
    </div>
    <div class="col-8 col-lg-9 col-xl-10" style="padding-bottom: 15px">

    </div>
    <div class="col-12 col-md-12 col-sm-12 col-mini col-lg-6 col-xl-4" v-for="(config, configName) in data"
         :key="configName" >
      <Card :show="true" :name="$t(`sys_config.groups.${configName}`)">
        <div class="from-group row" v-for="(item, index) in config" :key="index" style="margin-bottom: 1rem">
          <label class="col-sm-7 text-right control-label col-form-label" :title="item.param_name">{{
              $t(`sys_config.parameter_name.${item.param_name}`)
            }} <span v-if="item.rebuild_required" style="font-weight: bold; color: darkred">*</span> <br>
            <small>{{ $t(`sys_config.parameter_description.${item.param_name}`) }}</small>
          </label>
          <div class="col-sm-5">
            <v-select v-if="item.type === 'select'" class="style-chooser" :options="item.variants" v-model="item.value" :searchable="false" :clearable="false" :filterable="false" >
              <template v-slot:label>
                <span>{{ item.default }}</span>
              </template>
            </v-select>
            <input v-if="item.type === 'number'" type="number" :placeholder=item.default v-model="item.value"
                   class="form-control">
            <input v-if="item.type === 'input'" :placeholder=item.default v-model="item.value" class="form-control">
            <label class="switch-small" title="Enabled" v-if="item.type === 'checkbox'"
                   style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
              <input type="checkbox" :checked="item.value" @click="item.value = !item.value">
              <span class="slider"></span>
            </label>
          </div>
        </div>
      </Card>
    </div>
      <div class="col-12">
          <hr>
                  <div v-html="$t('rebuild_required_description')"></div>
      </div>
  </div>
</template>


<script>
import Card from "../../../components/Card.vue";

export default {
  components: {Card},
  data() {
    return {
      loading: false,
      data: [],
      error: null,
      filter: '',
    }
  },
  watch: {
    filter: {
      handler() {

      },
      deep: true,
    },
  },
  methods: {
    save() {
      let form = {}
      let hasError = false
      for (const [key, value] of Object.entries(this.data)) {
        value.forEach(vl => {
          form[vl.param_name] = vl.value
          if(vl.value && (typeof vl.value === 'string' || vl.value instanceof String) && vl.regex && !vl.value.match(`${vl.regex}`)) {
            console.log(vl)
            this.$noty.error( this.$t('incorrect_value_for', {value: this.$t(`sys_config.parameter_name.${vl.param_name}`)}))
            hasError = true
          }
        })
      }
      if(hasError) {
        return
      }
      this.$api.put('/system/configuration', form).then(r => {
        this.$noty.success(this.$t("parameters_success_saved"))
      })
    },
    async load() {
      this.loading = true
      await this.$api.get('/system/configuration')
          .then(response => {
            this.data = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      this.loading = false
    },
  },
  async mounted() {
    await this.load()
  },

}
</script>