import Dashboard from './views/GridDashboard/Dashboard.vue'
import DeviceDashboard from "./views/DeviceInfo/DeviceDashboard/DeviceDashboard.vue";
import PageNotFound from "./views/PageNotFound";
import Login from "./views/Login/Index";
import Main from "./views/Main";
import DeviceInfo from "./views/DeviceInfo/DeviceDashboard/DeviceInfo.vue";
import VueRouter from "vue-router";
import InterfaceInfo from "./views/DeviceInfo/DeviceDashboard/InterfaceInfo.vue";
import Management from "./views/Managment/Managment";
import Users from "./views/Managment/Users/Users";
import List from "./views/Managment/Users/List";
import Edit from "./views/Managment/Users/Edit";
import UserRoles from "./views/Managment/UserRoles/UserRoles";
import RoleList from "./views/Managment/UserRoles/List";
import RoleEdit from "./views/Managment/UserRoles/Edit";
import DeviceList from "./views/DeviceInfo/DeviceList";
import DeviceAccessManagement from "./views/Managment/DeviceAccess/DeviceAccess";
import DeviceManagementList from "./views/Managment/Device/DeviceList";
import DeviceManagementEdit from "./views/Managment/Device/DeviceEdit";
import Logs from "./views/Logs/Logs";
import ActionLog from "./views/Logs/ActionLog";
import DeviceActionLog from "./views/Logs/DeviceActionLog";
import Account from "./views/Account";
import PollerLogs from "./views/Logs/PollerLogs";
import SystemConfiguration from "./views/Configuration/SystemConfiguration"
import ScheduleLogs from "./views/Logs/ScheduleLogs";
import DeviceGroups from "./views/Managment/DeviceGroups/DeviceGroups";
import PageForbidden from "./views/PageForbidden";
import ModelList from "./views/Managment/DeviceModels/ModelList";
import ModelEdit from "./views/Managment/DeviceModels/ModelEdit";
import EventLogs from "./views/Events/EventLogs";
import NotificationConfiguration from "./views/Notifications/NotificationsConfiguration";
import ConfigurationPage from "./views/Autodiscovery/ConfigurationPage";
import EventConfiguration from "./views/Events/Configuration";
import BoxesMapEditing from "./views/PonBoxes/BoxesMapEditing";
import BoxTypes from "./views/PonBoxes/BoxTypes/BoxTypes";
import BoxesMapView from "./views/PonBoxes/BoxesMapView";
import EditBox from "./views/PonBoxes/EditBox";
import Boxes from "./views/PonBoxes/Boxes";
import BoxesPage from "./views/PonBoxes/BoxesPage";
import DeviceInfoKeepAlive from "./views/DeviceInfo/DeviceInfoKeepAlive.vue";
import OntList from "@/views/Interfaces/OntList.vue";
import OntStatuses from "@/views/Analitycs/OntStatuses.vue";
import DeviceStatuses from "@/views/Analitycs/DeviceStatuses.vue";
import StarredInterfaces from "@/views/Interfaces/FavoriteInterfaces.vue";
import TaggedInterfaces from "@/views/Interfaces/TaggedInterfaces.vue";
import DuplicatedOnts from "@/views/Analitycs/DuplicatedOnts.vue";
import DuplicatedMacAddresses from "@/views/Analitycs/DuplicatedMacAddresses.vue";
import OntSignalLevelStrength from "@/views/Analitycs/OntSignalLevelStrength.vue";
import Macros from "@/views/Macros/index.vue";
import MacrosEdit from "@/views/Macros/edit.vue";
import UnregisterOntsMacros from "@/views/OntsRegistration/index.vue";
import UnregisterOntsMacrosEdit from "@/views/OntsRegistration/edit.vue";
import ErrorsIncreasing from "@/views/Analitycs/ErrorsIncreasing.vue";
import TrapLogs from "@/views/Logs/TrapLogs.vue";
import Tree from "@/views/Links/TopologyTree.vue";
import BoxesList from "@/views/UtelsPonBoxes/BoxesList.vue";

const routes = [
    {
        path: "/login",
        name: "login",
        meta: {
            title: '',
            allowAnonymous: true,
        },
        component: Login,
    },{
        path: "/forbidden",
        name: "forbidden",
        meta: {
            title: 'Forbidden',
            allowAnonymous: true,
        },
        component: PageForbidden,
    },
    {
        path: "/",
        component: Main,
        meta: {
            title: ""
        },
        children: [
            {
               path: "/account/settings",
               meta: {
                   title: '',
               },
               name: "account_settings",
               component: Account,
            },
            {
                path: "",
                meta: {
                    title: "dashboard",
                    no_breadcrumb: true,
                },
                name: "dashboard",
                component: Dashboard,
            },
            {
              path: "/pon-boxes",
              component: Boxes,
              children: [
                  {
                      path: "boxes",
                      meta: {
                          title: '',
                      },
                      name: "pon_boxes_boxes",
                      component: BoxesPage,
                  },
                  {
                      path: "box/:id",
                      meta: {
                          title: '',
                          breadcrumbs: [  'pon_boxes_boxes'],
                      },
                      name: "pon_boxes_edit_box",
                      component: EditBox,
                  },
                  {
                      path: "map",
                      meta: {
                          title: '',
                      },
                      name: "pon_boxes_map",
                      component: BoxesMapView,
                  },
                  {
                      path: "map-builder",
                      meta: {
                          title: '',
                      },
                      name: "pon_boxes_map_builder",
                      component: BoxesMapEditing,
                  },
                  {
                      path: "types",
                      meta: {
                          title: '',
                      },
                      name: "pon_boxes_types",
                      component: BoxTypes,
                  },
              ]
            },
            {
                path: "/map",
                meta: {
                    title: '',
                },
                name: "map",
                component: () => import('@/views/Maps/Map.vue'),
            },
            {
                path: "/links/topology-tree",
                meta: {
                    title: '',
                },
                name: "topology-tree",
                component: () => import('@/views/Links/TopologyTree.vue'),
            },
            {
                path: "/links/list",
                meta: {
                    title: '',
                },
                name: "links_view",
                component: () => import('@/views/Links/List.vue'),
            },
            {
                path: "/utels-pon-boxes",
                meta: {
                    title: "",
                },
                name: "utels_pon_boxes",
                component: BoxesList,
            },
            {
                path: "/devices",
                meta: {
                    title: '',
                },
                component: DeviceInfoKeepAlive,
                children: [
                    {
                        path: "list",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "dev_dashboard",
                        component: DeviceList,
                    },
                    {
                        path: ":id",
                        component: DeviceDashboard,
                        children: [
                            {
                                path: "",
                                meta: {
                                    title: "",
                                    breadcrumbs: ['dashboard', 'dev_dashboard'],
                                },
                                name: "device_dashboard",
                                component: DeviceInfo,
                            },
                            {
                                path: "interface/:interface",
                                meta: {
                                    title: "",
                                    breadcrumbs: ['dashboard', 'dev_dashboard', 'device_dashboard'],
                                },
                                name: "device_iface_dashboard",
                                component: InterfaceInfo
                            },
                        ]
                    },
                ]
            },
            {
                path: "logs",
                meta: {
                    title: '',
                },
                component: Logs,
                children: [
                    {
                        path: "actions",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "log_actions_full",
                        component: ActionLog,
                    },
                    {
                        path: "events",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "events",
                        component: EventLogs,
                    },
                    {
                        path: "traps",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "trap_logs",
                        component: TrapLogs,
                    },
                    {
                        path: "device-calling",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "log_device_calling_full",
                        component: DeviceActionLog,
                    },
                    {
                        path: "schedule-reports",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "log_schedule_reports",
                        component: ScheduleLogs,
                    },
                    {
                        path: "poller",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "log_poller",
                        component: PollerLogs,
                    }
                ]
            },
            {
              path: "/interfaces/ont-list",
              meta: {
                  title: "ONT list",
              },
              name: "ifaces_ont_list",
              component: OntList,
            },
            {
              path: "/interfaces/favorite",
              meta: {
                  title: "Favorite",
              },
              name: "ifaces_favorite_list",
              component: StarredInterfaces,
            },
            {
              path: "/interfaces/tags",
              meta: {
                  title: "Tagged",
              },
              name: "ifaces_tags_list",
              component: TaggedInterfaces,
            },
            {
              path: "/analytics/ont-statuses",
              meta: {
                  title: "ONT statuses",
              },
              name: "analytic_ont_statuses",
              component: OntStatuses,
            },
            {
              path: "/analytics/increasing-errors",
              meta: {
                  title: "Errors increasing",
              },
              name: "analytic_increasing_errors",
              component: ErrorsIncreasing,
            },
            {
              path: "/analytics/ont-level-strength",
              meta: {
                  title: "ONT level signal",
              },
              name: "analytic_ont_level_strength",
              component: OntSignalLevelStrength,
            },
            {
              path: "/analytics/duplicated-onts",
              meta: {
                  title: "Duplicated ONTs",
              },
              name: "analytic_duplicated_onts",
              component: DuplicatedOnts,
            },
            {
              path: "/analytics/duplicated-mac-addresses",
              meta: {
                  title: "Duplicated MAC addresses",
              },
              name: "analytic_duplicated_mac_addresses",
              component: DuplicatedMacAddresses,
            },
            {
              path: "/analytics/device-statuses",
              meta: {
                  title: "ONT statuses",
              },
              name: "analytic_device_statuses",
              component: DeviceStatuses,
            },
            {
                path: "/management",
                meta: {
                    title: 'management',
                },
                name: "management",
                component: Management,
                children: [
                    {
                        path: "user",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard']
                        },
                        component: Users,
                        children: [
                            {
                                path: "",
                                meta: {
                                    title: '',
                                    breadcrumbs: ['dashboard'],
                                },
                                name: "management_users_list",
                                component: List,
                            },
                            {
                                path: ":id",
                                meta: {
                                    title: '',
                                    breadcrumbs: ['dashboard', 'management_users_list'],
                                },
                                name: "management_user_edit",
                                component: Edit,
                            }
                        ]
                    },
                    {
                        path: "user-role",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        component: UserRoles,
                        children: [
                            {
                                path: "",
                                meta: {
                                    title: '',
                                    breadcrumbs: ['dashboard'],
                                },
                                name: "management_roles_list",
                                component: RoleList,
                            },
                            {
                                path: ":id",
                                meta: {
                                    title: '',
                                    breadcrumbs: ['dashboard', 'management_roles_list'],
                                },
                                name: "management_role_edit",
                                component: RoleEdit,
                            }
                        ]
                    },
                    {
                        path: "device",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "management_device_list",
                        component: DeviceManagementList,
                    },
                    {
                        path: "device/:id",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard', 'management_device_list'],
                        },
                        name: "management_device_edit",
                        component: DeviceManagementEdit,
                    },
                    {
                        path: "device-access",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "management_device_accesses",
                        component: DeviceAccessManagement,
                    },
                    {
                        path: "device-model",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "management_device_models",
                        component: ModelList,
                    },
                    {
                        path: "device-model/:id",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard', 'management_device_models'],
                        },
                        name: "management_device_model_edit",
                        component: ModelEdit,
                    },
                    {
                        path: "device-group",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "management_device_groups",
                        component: DeviceGroups,
                    },
                ]
            },
            {
                path: "/config/system/configuration",
                meta: {
                    title: '',
                },
                name: "system_configuration",
                component: SystemConfiguration,
            },
            {
                path: "/config/autodiscovery",
                meta: {
                    title: '',
                },
                name: "config_autodiscovery",
                component: ConfigurationPage,
            },
            {
                path: "/config/notifications",
                meta: {
                    title: '',
                },
                name: "notification_configuration",
                component: NotificationConfiguration,
            },
            {
                path: "/config/events/configuration",
                meta: {
                    title: '',
                },
                name: "config_events",
                component: EventConfiguration,
            },
            {
                path: "/config/macros",
                meta: {
                    title: 'Macros',
                },
                name: "config_macros",
                component: Macros,
            },
            {
                path: "/config/macros/:id/edit",
                name: "macros_edit",
                meta: {
                    title: 'Macros edit',
                    breadcrumbs: ['dashboard','config_macros']
                },
                component: MacrosEdit,
            },
            {
                path: "/config/macros/create",
                name: "macros_create",
                meta: {
                    title: 'Macros create',
                    breadcrumbs: ['dashboard','config_macros']
                },
                component: MacrosEdit,
            },
            {
                path: "/config/onts-registration",
                meta: {
                    title: 'ONTs registration',
                },
                name: "config_onts_registration",
                component: UnregisterOntsMacros,
            },
            {
                path: "/config/onts-registration/:id/edit",
                name: "onts_registration_edit",
                meta: {
                    title: 'ONTs registration edit',
                    breadcrumbs: ['dashboard','config_onts_registration']
                },
                component: UnregisterOntsMacrosEdit,
            },
            {
                path: "/config/onts-registration/create",
                name: "onts_registration_create",
                meta: {
                    title: 'Create registration',
                    breadcrumbs: ['dashboard','config_onts_registration']
                },
                component: UnregisterOntsMacrosEdit,
            }
        ]
    },
    {
        path: '/:catchAll(.*)',
        meta: {
            title: 'Page not found',
            false: true,
        },
        component: PageNotFound,
    },
];

const router = new VueRouter({
    routes: routes,
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router;
