<template>
  <div :style="styles" class="leaflet-popup leaflet-zoom-animated">
    <div class="leaflet-popup-content-wrapper">
      <div class="leaflet-popup-content"  >
        <i class="mdi mdi-network"></i> {{ device.ip }}<br />
        <i class="mdi mdi-server-network"></i> {{ device.name }} - {{ device?.model?.name }}<br />
        <div v-if="device?.pinger">
          {{ device.pinger.status }} from: {{ device.pinger.last_change }}
        </div>
        <div class="d-flex align-items-center justify-content-center mt-3">
          <a :href="dashboardUrl">
            <i class="fa fa-external-link-alt" style="font-size: 20px;"></i>
          </a>
          <a href="#" class="ml-3" @click.prevent="$emit('show-device', device);">
            <i class="fa fa-eye" style="font-size: 20px;"></i>
          </a>
          <a v-if="isPermitted" :href="editUrl">
            <i class="fa fa-edit ml-3" style="font-size: 20px;"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="leaflet-popup-tip-container" v-if="!$helpers.isMobile()"><div class="leaflet-popup-tip"></div></div>
    <a class="leaflet-popup-close-button" role="button" aria-label="Close popup" href="#close" @click.prevent="close"><span aria-hidden="true">×</span></a>
  </div>
</template>

<script>

export default {
  props: {
    device: Object,
    styles: Object,
  },
  computed: {
    dashboardUrl() {
      return this.$router.resolve({ name: 'device_dashboard', params: { id: this.device.id } }).href;
    },
    editUrl() {
      return this.$router.resolve({ name: 'management_device_edit', params: { id: this.device.id } }).href;
    },
    ifaceUrl() {
      return this.$router.resolve({  name: 'device_iface_dashboard', params: {id: this.device.id, 'interface': this.device.bind_key} }).href
    },
    isPermitted() {
      return this.$auth.isPermitted('device_management');
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent;
}
</style>