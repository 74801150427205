<template>
  <div class="container-fluid" style="padding: 0px; padding-top: 15px">
    <div class="row">
      <div class="col-mini col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
        <Preloader style="margin-top: 10px" v-if="loading"></Preloader>
        <div v-if="!loading" class="row" style="margin-left: 1px; margin-right: 1px;">
          <div class="order-first col-2 col-md-1 col-sm-2 col-lg-1 col-xl-1" style=" margin-bottom: 5px" v-if="$auth.isPermitted('dashboard_edit')">
            <button class="btn btn-outline-info btn-sm btn-block" style="border-radius: 3px" v-if="!editable"
                    @click="editable = true"><i
                class="mdi mdi-file-edit"></i></button>
            <button class="btn btn-outline-info btn-sm btn-block" v-if="editable" style="border-radius: 3px"
                    @click="saveDashboard()"><i
                class="mdi mdi-check"></i></button>
          </div>
          <div class="col-md-7 col-lg-7 col-xl-8 col-sm-4" v-if="!editable">

          </div>
          <div class="col-12 col-md-5 col-lg-3 col-xl-3 col-sm-6" v-if="editable" style="margin-bottom: 5px; ">
            <v-select v-model="choosedNewWidget"
                      label="name"
                      :clearable="false"
                      :searchable="false"
                      :filterable="false"
                      :close-on-select="true"
                      style="min-width: 200px"
                      :placeholder="$t('choose_widget')"
                      :options="widgetsChooseList"></v-select>
          </div>
          <div class="col-12 col-md-2 col-lg-4 col-xl-5 col-sm-6" v-if="editable && $auth.isPermitted('dashboard_global_edit')" style="margin-bottom: 5px; padding: 5px; padding-left: 13px">
            {{$t('set_for_all_users')}} &nbsp;
            <label class="switch-small" title="Set for all users" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
              <input type="checkbox" v-model="isGlobal">
              <span class="slider"></span>
            </label>
          </div>
          <div class="col-12 col-md-2 col-lg-4 col-xl-3 col-sm-6" v-if="editable && !$auth.isPermitted('dashboard_global_edit')" style="margin-bottom: 5px; padding: 5px; padding-left: 13px">

          </div>
          <div
              class="order-first order-md-last order-sm-last order-lg-last order-xl-last col-6 col-md-2 col-sm-3 col-xl-2 col-lg-2"
              style="text-align: right; vertical-align: center; padding-top: 5px; margin-bottom: 5px">
            {{ $t('update_interval') }}
          </div>
          <div
              class="order-first order-md-last order-sm-last order-lg-last order-xl-last col-4 col-md-2 col-sm-3 col-xl-1 col-lg-2"
              style="margin-top: -1px; margin-bottom: 5px">
            <v-select v-model="updateInterval"
                      label="name"
                      :clearable="false"
                      :searchable="false"
                      :filterable="false"
                      :reduce="e => e.key"
                      :options="updateIntervals"></v-select>
          </div>
        </div>
        <div v-show="!loading">
          <grid-layout
              :layout="layout"
              :col-num="24"
              :cols = '{ lg: 24, md: 12, sm: 6, xs: 6, xxs: 1 }'
              :row-height="10"
              :is-draggable="editable"
              :is-resizable="editable"
              :is-mirrored="false"
              :vertical-compact="true"
              :responsive="true"
              :margin="[10, 10]"
              :use-css-transforms="true"
              @breakpoint-changed="breakpointChangedEvent"
              @layout-updated="layoutUpdatedEvent"
          >
            <grid-item v-for="item in layout"
                       :x="item.x"
                       :y="item.y"
                       :w="item.w"
                       :h="item.h"
                       :i="item.i"
                       :min-width="widgets[item.widget].minWidth"
                       :min-height="widgets[item.widget].minHeight"
                       :key="item.i">
              <div style="height: 100%; width: 100%">
                <div style="height: 100%; width: 100%; background: #FEFEFE; border: 1px solid gray" v-if="!item.widget">
                  No widget
                </div>
                <component v-else :is="widgets[item.widget].component" ref="activatedWidgets"></component>
                <div style="position: absolute; left: 7px; top: 7px; z-index: 10;" v-if="editable">
                  <a style=" color: darkred" href="javascript:void(0)" @click="deleteWidget(item)"><i
                      class="mdi mdi-trash-can"></i> </a>
                </div>
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import VueGridLayout from 'vue-grid-layout';
import Preloader from "@/components/Preloader";

export default {
  components: {
    Preloader,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  watch: {
    updateInterval: {
      handler(n) {
          console.log("Change reload interval to ", n)
          this.setReloadInterval()
      },
      deep: true,
    },
    choosedNewWidget: {
      handler(n) {
        if (n !== null) {
          let layout = this.layouts[this.breakpoint]

          let maxId = 0
          layout.forEach(l => {
            if (Number(l.i) > maxId) {
              maxId = l.i
            }
          })
          maxId++
          layout.unshift({"x": 0, "y": 0, "w": n.minWidth, "h": n.minHeight, "i": maxId, widget: n.key})
          this.choosedNewWidget = null
          this.layout = layout

        }
      },

    }
  },
  computed: {
    widgetsChooseList() {
      let widgets = []
      for (const [key, lay] of Object.entries(this.widgets)) {
        if(lay && lay.is_allowed()) {
          widgets.push({key: key, name: this.$t(`widgets.${key}`), minWidth: lay.minWidth, minHeight: lay.minHeight})
        }
      }
      return widgets
    },
  },
  data() {
    return {
      loading: true,
      breakpoint: '',
      choosedNewWidget: null,
      reloadWidgetsInterval: null,
      updateInterval: 60,
      isGlobal: false,
      updateIntervals: [
        {key: 5, name: '5s'},
        {key: 10, name: '10s'},
        {key: 30, name: '30s'},
        {key: 60, name: '1m'},
        {key: 120, name: '2m'},
        {key: 300, name: '5m'},
      ],
      widgets: {},
      editable: false,
      layout: [],
      layouts: null,
    }
  },
  async mounted() {
    this.$setRouteMeta(this.$t('dashboard'))
    this.widgets = require('./widgets.js').default
    await this.loadDashboard()
    this.layout = this.layouts.xxs
    this.loading = false
    this.setReloadInterval()
  },
  methods: {
    async loadDashboard() {
      let layouts = {}
      await this.$api.get('/dashboard/template').then(r => {
        for (const [breakpint, layout] of Object.entries(r.data)) {
           layouts[breakpint] = layout.filter(lay => {
             return this.widgets[lay.widget] && this.widgets[lay.widget].is_allowed()
           })
        }
      })
      this.layouts = layouts
    },
    breakpointChangedEvent(newBreakpoint, newLayout) {
      console.log(`New breakpoint is ${newBreakpoint}`)
      this.breakpoint = newBreakpoint
      if(this.layouts && this.layouts[newBreakpoint]) {
          this.layout = this.layouts[newBreakpoint]
      }
    },
    layoutUpdatedEvent(layout) {
       console.log(`Layout updated`)
        if(this.layouts) {
            this.layouts[this.breakpoint] = layout
        }
    },
    async saveDashboard() {
      await this.$api.put('/dashboard/template', {
        layout: this.layouts,
        reset_users_dashboard: this.isGlobal,
        is_global: this.isGlobal,
      }).then(r => {
        this.$noty.info(this.$t("dashboard_saved"))
      })
      this.editable = false
    },
    deleteWidget(widget) {
      this.layout.splice(this.layouts[this.breakpoint].indexOf(widget), 1);
    },
    clearReloadIntervals() {
      if(this.reloadWidgetsInterval) {
        clearInterval(this.reloadWidgetsInterval)
        this.reloadWidgetsInterval = null
      }
    },
    setReloadInterval() {
      this.clearReloadIntervals()
      this.reloadWidgetsInterval = setInterval(async () => {
        this.loadWidgetData()
      }, this.updateInterval * 1000)
    },
    loadWidgetData() {
      this.$refs.activatedWidgets.forEach(component => {
        console.log("Calling update for component", component)
        component.loadData()
      })
    }
  },
  beforeDestroy() {
    this.clearReloadIntervals()
  },
}
</script>
