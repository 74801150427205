<template>
  <div>
    <Preloader v-if="loading"></Preloader>
    <router-view v-else/>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader.vue";

export default {
  components: {Preloader},
  data() {
    return {
        error: '',
        loading: true,
    }
  },

  async mounted() {
    console.log(this.$route)
     if (this.$route.query.WCA_AUTH_KEY) {
        this.$api.setToken(this.$route.query.WCA_AUTH_KEY)
        await this.$api.get('/user/self', null, true).then(r => {
           let conf = this.$auth.getEmpty()
           conf.key = this.$route.query.WCA_AUTH_KEY
           conf.user = r.data
           this.$auth._setAuth(conf)
          const query = Object.assign({}, this.$route.query);
          delete query.WCA_AUTH_KEY;
          this.$router.replace({ query });
       }).catch(e => {
          this.$auth.logout()
          this.$router.push('/login?redirect=' + this.$route.fullPath)
        })
     }

    this.loading = false
  },
  watch: {

  },
}
</script>

<style>
:root {
  --vs-border-color: #e9ecef !important;
}
.vs__search::placeholder {
  color: #6c757d !important;
  font-size: 90%;
}
.ace-jsoneditor {
  min-height: 350px !important;
  max-height: 500px !important;
}
.jsoneditor-tree-inner {
  max-height: 500px;
}

.jsoneditor-contextmenu .jsoneditor-menu button {
  color: #FAFAFA !important;
}

.jsoneditor-contextmenu .jsoneditor-menu button:hover {
  color: #FAFAFA !important;
  background-color: #20323b !important;
}

.leaflet-bottom {
  z-index: 600 !important;
}
.leaflet-top {
  z-index: 600 !important;
}
.error-msg {
  color: darkred;
  font-size: small;
}

.bg-tab-white {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
}
.bg-white-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(335deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 56%);
}
#graph{
  position: relative;
  height: 100%;
  width: 100% !important;
}

canvas {
  width: 100% !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.slide-enter-to, .slide-leave {
  max-height: 700px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}


.card {
  border-top: 1px solid #E0E0E0 !important;
  border-radius: 0px;
  border-left: 1px solid #E0E0E0 !important;
  border-right: 1px solid #E0E0E0 !important;
  border-bottom: 1px solid #E0E0E0 !important;
}
.a-btn {
  font-weight: bold !important;
  color: white !important;
  margin-left: 5px; font-size: 20px; padding: 2px 8px 0px  8px;
}
.a-btn:hover {
font-weight: bold !important;
color: white !important;
}
.a-btn:visited {
  font-weight: bold !important;
  color: white !important;
}


.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #FFF;
  border-radius: 2px;
  padding-top: 2px;
  padding-left: 3px;
  padding-bottom: 5px;
  color: #394066;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #555;
}
.VueTables__search {
  width: 100%;
}
.VueTables__search-field {
  position: relative;
  right: 0;
}

.VueTables__search-field label {
  display: inline !important;
}
.VueTables__limit-field {
  display: inline;
}
.VueTables__limit-field label {
  float: left;
}
.VueTables__limit-field select {
  width: 80px !important;
}
.VueTables__search__input {
  display: inline !important;

  min-width: 300px;
}
.styled-table {
  background-color: #FFFFFF;
}
.styled-table thead tr th {
  background-color: #343a40;
  color: #DADADA;
  border: 1px solid #1f2226;
}

.styled-table tbody tr td {
  border: 1px solid #dadada;
  border-left:  1px solid #dadada;
  border-right: 1px solid #dadada;
}

.table-full-width {
  width: 100%;

}
.table-without-outline {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
}
.table-without-outline thead tr {
  border-bottom: 1px solid #DADADA;
}
.table-without-outline thead tr > th:nth-of-type(1)  {
  padding-left: 25px;
}
.table-without-outline thead tr > th:nth-last-of-type(1)  {
  padding-right: 25px;
}
.table-without-outline tbody tr > td:nth-of-type(1)  {
  padding-left: 25px;
}
.table-without-outline tbody tr > td:nth-last-of-type(1)  {
  padding-right: 25px;
}
.table-without-outline tbody tr {
  border-bottom: 1px solid #DADADA;
}
.table-without-outline thead tr th {
  padding: 5px;
}

@media only screen and (max-width: 768px) {
  .page-breadcrumb {
    padding: 5px 10px 0 10px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 580px) {

  .col-mini {
    padding: 5px !important;
  }
  .table-responsive table {
    min-width: 480px !important;
    scrollbar-width: thin;
  }
  .btn-add-margin-top-for-mobile {
    margin-top: 55px;
  }
  .VueTables__search {
    display: inline !important;
    width: 100%;
  }
}
.col-mini {}

.table-responsive {
  overflow-x: auto;
  scrollbar-width: thin;
}
.table-responsive table {
  min-width: 500px;
}
.btn-add-margin-top-for-mobile {

}
.custom-filter {
  min-width: 170px;
}
.srv-tbl-custom {
}


.VueTables__child-row-toggler {
  width: 35px !important;
  padding: 5px !important;
}
.VueTables__child-row-toggler--closed::before {
  font-family: 'Font Awesome 5 Free';
  content: "\f067";
  font-weight: bold;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: #444444;
  border-radius: 5px;
  color: #FAFAFA;
}

.VueTables__child-row-toggler--open::before {
  font-family: 'Font Awesome 5 Free';
  content: "\f068";
  font-weight: bold;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: #444444;
  border-radius: 5px;
  color: #FAFAFA;
}


#app {
  width: 95%;
  margin: 0 auto;
}

.VuePagination {
  text-align: center;
}

.vue-title {
  text-align: center;
  margin-bottom: 10px;
}

.vue-pagination-ad {
  text-align: center;
}

.glyphicon.glyphicon-eye-open {
  width: 16px;
  display: block;
  margin: 0 auto;
}

th:nth-child(3) {
  text-align: center;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

/* Добавлено, потому что при меньшем зуме, тень всплывающего окна занимает не весь экран */
.vm--overlay {
  height: 150vh !important;
}

.leaflet-control-button {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border-radius: 4px;
  width: 34px;
  height: 34px;
  background: #FFFFFF;
  margin-left: -10px;
  display: block;
  text-align: center;
}



.mx-input {
  border-radius: 2px !important;
  border: 1px solid #e9ecef;
}

.mx-input:hover {
  border-radius: 2px !important;
  border: 1px solid #e9ecef;
}

.mx-input:active {
  border-radius: 2px !important;
  border: 1px solid #e9ecef;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.a-to-badge:active {
  color: #FAFAFA !important;
}

.a-to-badge:visited {
  color: #FAFAFA !important;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.fixed-header-table th,
.fixed-header-table td {
  border: 1px solid #ccc !important;
  text-align: left;
}

.fixed-header-table thead th {
  position: sticky;
  top: -2px; /* Заголовок остаётся в верхней части */
  background-color: #f9f9f9; /* Цвет фона для заголовка */
  z-index: 2; /* Чтобы заголовок находился выше содержимого */
}


</style>
