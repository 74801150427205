export default {
    permissions: {
        group_descriptions: {
            analytics: "Отображение информации обо всех устройствах без групповых прав пользователя. Рекомендуется разрешить только администратору",
            web_portal: "Информация о системе, правила глобального поиска, необходимые для корректной работы веб-портала",
            prom_wrapper: "Правила отображения диаграмм, оптической истории, трафика на ONU или интерфейсе",
        },
        groups: {
            utels_integration: "UTELS integration",
            macros: "Компонент: Макросы",
            sensor_devices: "Датчики",
            billing_integration: "Интеграция с биллингами",
            oxidized: "Бекапы конфигов (oxidized)",
            analytics: "Analytics",
            web_portal: "Веб-портал",
            links: "Компонент: Линки",
            pon_boxes: "Компонент: PON-боксы",
            olts: "ОЛТ'ы",
            switches: "Свитчи",
            notifications: "Компонент: Notifications",
            events: "Компонент: Events",
            external_apps: "Внешние приложения",
            router_os: "Компонент: RouterOS",
            prom_wrapper: "Компонент: Prometheus",
            diag: "Компонент: Диагностика",
            sd: "Компонент: Поиск устройства",
            fdb_history: "Компонент: FDB история",
            system: "Система",
            sys_info: "Веб-панель",
            user_management: "Пользователи",
            device_management: "Оборудование",
            switcher_core_dev_control: "Работа с оборудованием",
            switcher_core: "Информация с оборудования",
            onu_registration: "Компонент: Регистрация ОНУ на ZTE",
        },
        keys: {
            "utels_update_boxes": "Разрешить исключать PON-боксы из статистики",
            unregistered_onts_result_output: "Регистрация ОНУ: Разрешить просмотр вывода консоли при ошибке ",
            macros_execute: "Выполнение макросов",
            macros_edit: "Настройка макросов",
            analytics: "Аналитика",
            olts_ctrl_uni_ports: "Управление UNI-портами на ONU",
            sensor_devices_view: "Отображение данных датчиков",
            sensor_devices_allow_switch_modes: "Разрешить переключать состояние",
            sensor_devices_configure: "Разрешить конфигурацию датчиков",
            olts_ctrl_port: "Управление физическими портами",
            device_iface_manage_marks: "Управление избранными(тегами) интерфейсами",
            user_management_config_strict_ip: "Настройка разрешенных IP для входа",
            external_apps_oxidized: "Разрешить чтение конфигов оборудования",
            billing_integration_show_info: "Разрешить диагностику для биллингов",
            system_cross_auth_user: "!!! Разрешить авторизовать других пользователей (генерировать ключи)",
            billing_integration: "Разрешить биллингу проводить диагностику",
            poller_info_by_device: "Инфо с опросчика в дашборде устройства",
            device_iface_management: "Управление интерфейсами",
            run_poller_by_device: "Разрешить ручной запуск опросчика",
            analytics_ont_list: "Список ОНУ",
            analytics_ont_status_history: "История статусов ОНУ",
            analytics_iface_status_history: "История статусов интерфейсов",
            analytics_device_online_history: "История статусов устройств",
            switches_save_config: "Разрешить сохранять конфиг",
            switches_vlan_port_control: "Управление вланами",
            olts_ctrl_dereg: 'Разрешить удалять ОНУ',
            olts_ctrl_reboot: 'Разрешить перезагружать ОНУ',
            olts_ctrl_clear_counters: 'Разрешать очистку счетчиков',
            olts_ctrl_reset: 'Разрешить сброс ОНУ',
            olts_ctrl_disable: 'Разрешить вкл/выкл ОНУ',
            olts_ctrl_description: 'Разрешить изменять описание ОНУ',
            events_configuration: "Разрешить настройку событий",
            system_status: "Статус системы",
            live_traffic_info: "Просмотр реалтайм трафика",
            dashboard_global_edit: "Разрешить глобальное редактирование дашборда",
            dashboard_edit: "Разрешить редактировать свой дашборд",
            links_view: "Просмотр линков",
            links_edit: "Изменение линков",
            external_apps_phpmyadmin: "phpMyAdmin",
            pon_boxes_view: "Просмотр боксов/карт",
            pon_boxes_map_editing: "Редактирование боксов/карт",
            events_see_all: "Отображать все события",
            notifications_send_global_notify: "Отправлять глобальные уведомления (без устройства)",
            unregistered_onts: "Регистрация ОНУ",
            unregistered_onts_config: "Конфигурация регистрации ОНУ",
            notifications_full_access: "Полный доступ",
            notifications_configure_contacts: "Разрешить настраивать контакты",
            notifications_configure_self_contacts: "Разрешить настройку только своих контактов",
            log_poller: "Логи опросчика",
            olts_info: "Инфо с ОЛТов",
            olts_onu_reboot: "Разрешить перезагружать ОНУ",
            olts_onu_dereg: "Разрешить удаление ОНУ",
            olts_onu_reset: "Разрешить сброс ОНУ",
            olts_onu_clear_counters: "Разрешить очистку счетчиков",
            switches_info: "Инфо с свитчей",
            switches_reboot_device: "Разрешить перезагрузку",
            switches_clear_counters: "Разрешить очистку счетчиков",
            switches_set_port_description: "Изменять описание порта",
            switches_set_port_admin_state: "Изменять админ-состояние порта",
            switches_set_port_admin_speed: "Изменять скорость порта",
            events_show: "Разрешить просмотр событий",
            events_resolve: "Разрешить закрывать события",
            external_apps_grafana: "Grafana",
            external_apps_prometheus: "Prometheus",
            external_apps_alertmanager: "Alertmanager",
            router_os_info: "Инфо с роутеров",
            prom_chart_info: "Графики",
            diag_arp_ping: "ARP пинг",
            diag_icmp_ping: "ICMP пинг",
            diag_traceroute: "Traceroute",
            diag_interface: "Диагностика интерфейса (для биллинга)",
            sd_search_ip_with_port: "Поиск порта (ARP + FDB)",
            device_groups_management: "Управление группами устройств",
            sd_search_mac: "Разрешить поиск устройства по MAC-адресу",
            sd_search_ip: "Разрешить поиск устройства по IP-адресу",
            fdb_history_by_interface: "История по интерфейсу",
            fdb_history_search: "Разрешить поиск истории",
            schedule_reports: "Просмотр отчетов планировщика",
            schedule_configuration: "Конфигурирование планировщика",
            system_configuration: "Чтение/изменение системных настроек",
            global_search: "Глобальный поиск",
            system_info: "Информация о системе",
            user_management: "Полный доступ",
            user_self_control: "Обновление информации о себе",
            user_display: "Просмотр информации о других пользователях",
            device_access_management: "Управление доступами",
            device_show: "Просмотр устройства",
            device_management: "Управление устройствами",
            full_sw_core_access: "Полный доступ",
            swc_reboot_onu: "Перезагрузка ОНУ",
            system_logs_actions: "Логи действий",
            users_list: "Список пользователей",
            log_switcher_core_actions: "Логи оборудования",
            zte_unregistered_onts: "Незарегистрированные ОНУ",
            zte_unregistered_onts_config: "Конфигурирование регистрации ОНУ",
        }
    },
}