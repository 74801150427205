export default [
    {
        name: 'dashboard',
        url: '/',
        icon: 'mdi mdi-view-dashboard',
        children: null,
        need_permissions: [],
    },
    {
        name: 'devices',
        url: '/devices/list',
        icon: 'mdi mdi-file-tree',
        children: null,
        need_permissions: [],
    },
    {
        name: 'interfaces',
        url: null,
        icon: 'mdi mdi-ethernet',
        need_permissions: ['device_show'],
        need_components: ['prometheus_wrapper', 'analytics'],
        children: [
            {
                name: 'ont_list',
                url: '/interfaces/ont-list',
                icon: 'mdi mdi-table-of-contents',
                need_permissions: ['device_show'],
            },
            {
                name: 'favorite_list',
                url: '/interfaces/favorite',
                icon: 'mdi mdi-star',
                need_permissions: ['device_show'],
            },
            {
                name: 'tags',
                url: '/interfaces/tags',
                icon: 'mdi mdi-tag',
                need_permissions: ['device_show'],
            },
        ],
    },
    {
        name: 'links',
        url: null,
        icon: 'mdi mdi-transit-connection-variant',
        children: [
            {
                name: 'link_list',
                url: '/links/list',
                icon: 'mdi mdi-link-variant',
            },
            {
                name: 'topology',
                url: '/links/topology-tree',
                icon: 'mdi mdi-family-tree',
            }
        ],
        need_permissions: ['device_show', 'links_view'],
    },
    {
        name: 'map',
        url: '/map',
        icon: 'mdi mdi-map',
        children: null,
        need_permissions: ['device_show'],
    },
    {
        name: 'events.name',
        url: '/logs/events?only_not_resolved=true',
        icon: 'mdi mdi-alert',
        need_permissions: ['events_show'],
        need_components: ['events'],
    },
    {
        name: 'utels_pon_boxes',
        url: '/utels-pon-boxes',
        icon: 'mdi mdi-file-tree-outline',
        need_components: ['utels_integration'],
    },
    {
        name: 'analytics',
        url: null,
        icon: 'mdi mdi-text-search',
        need_permissions: [],
        need_components: ['prometheus_wrapper', 'analytics'],
        children: [
            {
                name: 'increasing_errors',
                url: '/analytics/increasing-errors',
                icon: 'mdi mdi-alert-octagon-outline',
                need_permissions: ['analytics'],
            },
            {
                name: 'ont_statuses',
                url: '/analytics/ont-statuses',
                icon: 'mdi mdi-list-status',
                need_permissions: ['analytics'],
            },
            {
                name: 'duplicated_macs',
                url: '/analytics/duplicated-mac-addresses',
                icon: 'mdi mdi-list-status',
                need_permissions: ['analytics'],
            },
            {
                name: 'ont_level_strength',
                url: '/analytics/ont-level-strength',
                icon: 'mdi mdi-list-status',
                need_permissions: ['analytics'],
            },
            {
                name: 'duplicated_onts',
                url: '/analytics/duplicated-onts',
                icon: 'mdi mdi-list-status',
                need_permissions: ['analytics'],
            },
            {
                name: 'device_statuses',
                url: '/analytics/device-statuses',
                icon: 'mdi mdi-list-status',
                need_permissions: ['analytics'],
            },
        ],
    },

    {
        name: 'pon_boxes.name',
        url: null,
        icon: 'mdi mdi-file-tree-outline',
        children: [
            {
                name: 'pon_boxes.list',
                url: '/pon-boxes/boxes',
                icon: 'mdi mdi-file-tree',
                need_permissions: ['pon_boxes_view'],
                need_components: ['pon_boxes'],
            },
            {
                name: 'pon_boxes.map',
                url: '/pon-boxes/map',
                icon: 'mdi mdi-map-legend',
                need_permissions: ['pon_boxes_view'],
                need_components: ['pon_boxes'],
            },
            {
                name: 'pon_boxes.map_builder',
                url: '/pon-boxes/map-builder',
                icon: 'mdi mdi-map-plus',
                need_permissions: ['pon_boxes_map_editing', 'pon_boxes_view'],
                need_components: ['pon_boxes'],
            },
            {
                name: 'pon_boxes.types',
                url: '/pon-boxes/types',
                icon: 'mdi mdi-mapbox',
                need_permissions: ['pon_boxes_map_editing', 'pon_boxes_view'],
                need_components: ['pon_boxes'],
            },
        ],
    },
    {
        name: 'logs',
        url: null,
        icon: 'mdi mdi-file-document',
        children: [
            {
                name: 'log_actions',
                url: '/logs/actions',
                icon: 'mdi mdi-code-tags',
                need_permissions: ['system_logs_actions'],
            },
            {
                name: 'log_device_calling',
                url: '/logs/device-calling',
                icon: 'mdi mdi-code-tags',
                need_permissions: ['log_switcher_core_actions'],
            },
            {
                name: 'log_traps',
                url: '/logs/traps',
                icon: 'mdi mdi-code-tags',
                need_permissions: ['log_switcher_core_actions'],
                need_components: ['trapservice']
            },
            {
                name: 'log_poller',
                url: '/logs/poller',
                icon: 'mdi mdi-code-tags',
                need_permissions: ['log_poller'],
            },
            {
                name: 'schedule_reports',
                url: '/logs/schedule-reports',
                icon: 'mdi mdi-code-tags',
                need_permissions: ['schedule_reports'],
            },
        ],
    },
    {
        name: 'device_management',
        url: null,
        icon: 'mdi mdi-server-network',
        children: [
            {
                name: 'devices',
                url: '/management/device',
                icon: 'mdi mdi-lan-connect',
                need_permissions: ['device_management'],
            },
            {
                name: 'accesses',
                url: '/management/device-access',
                icon: 'mdi mdi-key-variant',
                need_permissions: ['device_access_management'],
            },
            {
                name: 'groups',
                url: '/management/device-group',
                icon: 'mdi mdi-select-group',
                need_permissions: ['device_groups_management'],
            },
            {
                name: 'models',
                url: '/management/device-model',
                icon: 'mdi mdi-dots-horizontal-circle-outline',
                need_permissions: ['device_management'],
            },
            {
                name: 'config_autodiscovery',
                url: '/config/autodiscovery',
                icon: 'mdi mdi-cloud-search-outline',
                need_permissions: ['system_configuration'],
                need_components: ['autodiscovery']
            },
        ],
    },
    {
        name: 'users_management',
        url: null,
        icon: 'mdi mdi-account-multiple',
        children: [
            {
                name: 'users',
                url: '/management/user',
                icon: 'mdi mdi-account',
                need_permissions: ['user_management'],
            },
            {
                name: 'roles',
                url: '/management/user-role',
                icon: 'mdi mdi-account-multiple',
                need_permissions: ['user_management'],
            },
        ],
    },
    {
        name: 'config',
        url: null,
        icon: 'mdi mdi-cogs',
        children: [
            {
                name: 'macros.config',
                url: '/config/macros',
                icon: 'mdi mdi-script-text',
                need_permissions: ['macros_edit'],
                need_components: ['macros']
            },
            {
                name: 'onts_registration.config',
                url: '/config/onts-registration',
                icon: 'mdi mdi-script-text',
                need_permissions: ['unregistered_onts_config'],
                need_components: ['onts_registration']
            },
            {
                name: 'notifications_configuration',
                url: '/config/notifications',
                icon: 'mdi mdi-message-cog-outline',
                need_components: ['notifications'],
                need_permissions: ['notifications_full_access']
            },
            {
                name: 'event_configuration',
                url: '/config/events/configuration',
                icon: 'mdi mdi-alert-plus-outline',
                need_components: ['events'],
                need_permissions: ['events_configuration']
            },
            {
                name: 'system_configuration',
                url: '/config/system/configuration',
                icon: 'mdi mdi-cog',
                need_permissions: ['system_configuration']
            },
        ],
    },
    {
        name: 'external_apps',
        url: null,
        icon: 'mdi mdi-apps',
        children: [
            {
                name: 'oxidized',
                url: '/oxidized/',
                external: true,
                icon: 'mdi mdi-file-code-outline',
                need_permissions: ['external_apps_oxidized']
            },
            {
                name: 'grafana',
                url: '/grafana/',
                external: true,
                icon: 'mdi mdi-chart-bar-stacked',
                need_permissions: ['external_apps_grafana']
            },
            {
                name: 'prometheus',
                url: '/prometheus/',
                external: true,
                icon: 'mdi mdi-database',
                need_permissions: ['external_apps_prometheus']
            },
            {
                name: 'alertmanager',
                url: '/alertmanager/',
                external: true,
                icon: 'mdi mdi-alert',
                need_permissions: ['external_apps_alertmanager']
            },
            {
                name: 'phpmyadmin',
                url: '/phpmyadmin/',
                external: true,
                icon: 'mdi mdi-database',
                need_permissions: ['external_apps_phpmyadmin']
            },
        ],
    },
]
