<template>
  <div>
    <div style="width: 100%; position: relative; text-align: center; bottom: 0;">
      <i style="color: gray" class="mdi mdi-arrow-right-bold-outline mdi-36px "></i>
    </div>
    <div class="row" style="margin-top: -30px">
      <div class="col-6" style="text-align: center">
        <i class="mdi mdi-ip"></i> {{ link.src_device.ip }}<br>
        <b> {{ link.src_device.name ? link.src_device.name : $t('no_name') }}</b>
      </div>
      <div class="col-6" style="text-align: center">
        <i class="mdi mdi-ip"></i> {{ link.dest_device.ip }}<br>
        <b> {{ link.dest_device.name ? link.dest_device.name : $t('no_name') }}</b>
      </div>
      <div class="col-6" style="text-align: center">
        <div style="width: 100%; font-size: 100%; padding-top: 10px; font-weight: bold" v-if="link.src_iface">
          <i class="mdi mdi-ethernet"></i>
          <router-link
              :to="{name: 'device_iface_dashboard', params: {id: link.src_device.id, 'interface':link.src_iface.bind_key}}">
            {{ link.src_iface.name }} <span
              v-if="link.src_iface.description">- {{ link.src_iface.description }} </span>
          </router-link>
          <router-link target='_blank'
                       :to="{name: 'device_iface_dashboard', params: {id: link.src_device.id, 'interface': link.src_iface.bind_key}}">

            <i class="mdi mdi-open-in-new"></i>
          </router-link>
        </div>
        <div v-else style="text-align: center; padding-top: 20px;">
          <h4 style="color: gray">{{ $t('unknown_interface') }}</h4>
        </div>
      </div>
      <div class="col-6" style="text-align: center">
        <div style="width: 100%; font-size: 100%; padding-top: 10px; font-weight: bold" v-if="link.dest_iface">
          <i class="mdi mdi-ethernet"></i>
          <router-link
              :to="{name: 'device_iface_dashboard', params: {id: link.dest_device.id, 'interface':link.dest_iface.bind_key}}">
            {{ link.dest_iface.name }} <span
              v-if="link.dest_iface.description">- {{ link.dest_iface.description }} </span>
          </router-link>
          <router-link target='_blank'
                       :to="{name: 'device_iface_dashboard', params: {id: link.dest_device.id, 'interface': link.dest_iface.bind_key}}">
            <i class="mdi mdi-open-in-new"></i>
          </router-link>
        </div>
        <div v-else style="text-align: center; padding-top: 20px;">
          <h4 style="color: gray">{{ $t('unknown_interface') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6" style="text-align: center; color: #aaaaaa">
        <i class="mdi mdi-arrow-down-bold-outline mdi-24px" v-if="util_based_by === 'src'"></i>
      </div>
      <div class="col-6"  style="text-align: center; color: #aaaaaa">
        <i class="mdi mdi-arrow-down-bold-outline mdi-24px"  v-if="util_based_by === 'dest'"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="util">
        <table class="table table-sm table-bordered table-striped table-light">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('in') }}</th>
            <th>{{ $t('out') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>{{ $t('utilization') }}</th>
            <td><b>{{util.in_util_prc}}</b> %</td>
            <td><b>{{util.out_util_prc}}</b> %</td>
          </tr>
          <tr>
            <th>{{ $t('speed') }}</th>
            <td><b>{{util.in_mbps}}</b> Mbps</td>
            <td><b>{{util.out_mbps}}</b> Mbps</td>
          </tr>
          <tr>
            <th>{{ $t('link_speed') }}</th>
            <td colspan="2" style="text-align: center">{{util.speed_mbits}} Mbps</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    link: Object,
  },
  data() {
    return {
      util: null,
      util_based_by: ''
    }
  },
  mounted() {
    if (this.link.src_iface?.utilization) {
      this.util = this.link.src_iface?.utilization
      this.util_based_by = 'src'
    } else if (this.link.dest_iface?.utilization) {
      this.util = this.link.dest_iface?.utilization
      this.util_based_by = 'dest'
    }
  },
};
</script>

<style scoped>
</style>