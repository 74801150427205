<template>
  <div :style="boxStyle" v-html="text">

  </div>
</template>


<script>
export default {
  props: {
    text: {
      default: 'n/a',
      type: String,
    },
    backgroundColor: {
      default: '#FAFAFA',
      type: String,
    },
    textColor: {
      default: '#0F0F0F',
      type: String,
    },
    borderColor: {
      default: '#F0F0F0',
      type: String,
    }
  },
  computed: {
      boxStyle() {
        return `
            border: 1px solid ${this.borderColor};
            color: ${this.textColor};
            background: ${this.backgroundColor};
            border-radius: 3px;
            padding: 3px;
            text-align: center;
            vertical-align: middle;
        `
      }
  },

}
</script>
